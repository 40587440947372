import { useState } from 'react';
import { selectIsFullRegistration } from '@mwl/core-lib';

import type { FormRegistrationTab } from '@/components/FormRegistration/FormRegistration.types';
import { useTypedSelector } from '@/hooks';

export const useRegisterTabs = () => {
  const isFullRegistration = useTypedSelector(selectIsFullRegistration);

  const initialRegistrationTab: FormRegistrationTab = isFullRegistration ? 'extended' : 'phone';

  const [registrationTab, setRegistrationTab] = useState<FormRegistrationTab>(initialRegistrationTab);

  return {
    registrationTab,
    setRegistrationTab,
    initialRegistrationTab,
  };
};
