import type { FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  selectUserInitialAuthCompleted,
  selectUserIsAuthenticated,
  selectUserProfileIsFrozen,
  useQueryPopup,
} from '@mwl/core-lib';

import type { PopupClasses } from '@/components/Popup/Popup';
import { Popup } from '@/components/Popup/Popup';
import { Tabs } from '@/components/Tabs/Tabs';
import { paymentMobileSize } from '@/constants';
import { routes } from '@/constants/routes';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { popupsObject } from '@/utils';

import type { PopupPaymentTab } from '../../types';
import { Deposit } from '../../views/Deposit/Deposit';
import { PaymentsHistory } from '../../views/PaymentsHistory/PaymentsHistory';
import { Withdrawal } from '../../views/Withdrawal/Withdrawal';
import { PaymentTab } from '../PaymentTab/PaymentTab';
import { UserIsFrozenPopup } from '../UserIsFrozenPopup/UserIsFrozenPopup';

import { tabsContent } from './PopupPayment.data';
import type { TabsContent } from './PopupPayment.types';

import styles from './PopupPayment.module.scss';

const BasePopupPayment: FC = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [isFrozenPopupOpen, setIsFrozenPopupOpen] = useState(false);

  const { show, activeParam, onClose } = useQueryPopup<Array<PopupPaymentTab>>('deposit', 'withdrawal', 'history');

  const isSmallScreen = useMediaWidthDown(paymentMobileSize);
  const isWebView = router.query.inWebView === '1';

  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const isAuthCompleted = useTypedSelector(selectUserInitialAuthCompleted);
  const isFrozen = useTypedSelector(selectUserProfileIsFrozen);

  const handleCloseFrozenPopup = () => {
    setIsFrozenPopupOpen(false);
    router.push(routes.home);
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isFrozen && show) {
      setIsFrozenPopupOpen(true);
    }
  }, [isFrozen, show]);

  useEffect(() => {
    if (!isAuth && show && isAuthCompleted) {
      onClose();
    }
  }, [show, isAuth, onClose, isAuthCompleted]);

  const popupClasses: PopupClasses = {
    close: styles.close,
    wrapper: styles.wrapper,
    container: cn(styles.container, isWebView && styles.containerInWebView),
  };

  return (
    <Popup
      {...popupsObject.deposit.nodeProps}
      show={isAuth && show}
      onClose={handleClose}
      classes={popupClasses}
      disableClickOutside
      disableScrollOnFocus={false}
      className={styles.popup}
      closeButtonColor={isSmallScreen ? 'default' : 'white'}
      withCloseButton={!isWebView}
    >
      <div className={styles.root}>
        {activeParam && (
          <Tabs className={styles.tabs}>
            {(Object.keys(tabsContent) as Keys<TabsContent>).map((key) => (
              <PaymentTab
                {...popupsObject.deposit.tabs(key).nodeProps}
                className={styles.tab}
                key={key}
                label={t(tabsContent[key])}
                value={key}
                active={activeParam === key}
              />
            ))}
          </Tabs>
        )}
        <div className={styles.content}>
          {activeParam === 'history' && <PaymentsHistory />}
          {activeParam === 'deposit' && <Deposit />}
          {activeParam === 'withdrawal' && <Withdrawal />}
          <UserIsFrozenPopup show={isFrozenPopupOpen} onClose={handleCloseFrozenPopup} />
        </div>
      </div>
    </Popup>
  );
};

export const PopupPayment = memo(BasePopupPayment);
