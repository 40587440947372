var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.0.25"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import ignoreList from './sentry.ignoreList';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const isProdDeploy = process.env.DEPLOY_ENV === 'production';
const release = process.env.SENTRY_RELEASE;

if (isProdDeploy) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.5,
    ignoreErrors: ignoreList.ignoreErrors,
    ignoreUrls: ignoreList.ignoreUrls,
    integrations: [new BrowserTracing()],
    release,
    beforeSend(event, hint) {
      // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
      if (hint.originalException === 'Timeout') return null;
      if (event?.extra?.arguments[0]?.currentTarget === 'head > link.jv-css') return null;
      return event;
    },
  });
}
