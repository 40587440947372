import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import type {
  FormState,
  PaymentHistoryItem,
  PayoutCreateResponse,
  UseSubmitFormOnFailure,
  WithdrawalFormValues,
} from '@mwl/core-lib';
import { generalError, loadPayoutRequest, payoutCreateKeys, useSubmitWithdrawal } from '@mwl/core-lib';

import { useTypedDispatch } from '@/hooks';

import { useWithdrawal } from '../../Withdrawal.context';

interface UseSubmitPayoutFormParams {
  formState: FormState<WithdrawalFormValues>;
}

/**
 * Хук занимается отправкой запроса на вывод средств (обработкой статуса ответа и ошибок)
 *
 * @param param.formState - состояние формы (значения полей)
 *
 * @returns result.state - состояние запроса (из хука useSubmitForm)
 * @returns result.onSubmitConfirm - метод для отправки запроса (обертка над onSubmitConfirm из хука useSubmitForm)
 */
export const useSubmitWithdrawalForm = ({ formState }: UseSubmitPayoutFormParams) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation(['payment', 'common']);

  const { payment, openPopup } = useWithdrawal();

  const onSuccess = useCallback(
    (data: PayoutCreateResponse | undefined) => {
      // TODO добавить обработку остальных статусов
      if (data && data?.status === 'VERIFICATION') {
        openPopup({
          type: 'confirmation',
          confirmation: {
            id: data.id || '',
            amount: data.money?.amount,
            currencyCode: data.money?.currency,
            status: 'WAITING_FOR_CONFIRMATION',
            maskedCard: data.requisites?.cardNumber || null,
            createdAt: data.createdAt || '',
            paymentMethod: data.type,
            paymentType: 'WITHDRAW',
          } as PaymentHistoryItem,
        });
      } else if (data?.status === 'ERROR' || data?.status === 'DECLINED') {
        openPopup({
          type: 'error',
          title: t(payoutCreateKeys.error.title),
          message: t(payoutCreateKeys.error.message),
          buttonText: t(payoutCreateKeys.error.button),
        });
      } else {
        openPopup({
          type: 'success',
          title: t(payoutCreateKeys.success.title),
          message: t(payoutCreateKeys.success.message),
          buttonText: t(payoutCreateKeys.success.button),
        });
      }

      dispatch(loadPayoutRequest());
    },
    [dispatch, openPopup, t],
  );

  const onError: UseSubmitFormOnFailure = useCallback(
    (error) => {
      if (error.code === 'USER_ACCESS') {
        openPopup({ type: 'userIsFrozen' });
      } else {
        openPopup({
          type: 'error',
          title: t(payoutCreateKeys.error.title),
          message: t(error.message || generalError, { ns: 'common' }),
          buttonText: t(payoutCreateKeys.error.button),
        });
      }
    },
    [openPopup, t],
  );

  const { state, onSubmitConfirm } = useSubmitWithdrawal({
    formState,
    payment: payment?.method,
    onSuccess,
    onError,
  });

  return { state, onSubmitConfirm };
};
