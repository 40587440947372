import type { Redirect } from 'next';

import { routes } from '@/constants';

/**
 * Проверяет доступ к разделу спорта. Временная мера, после релиза спорта можно удалить
 *
 * @returns Если доступ запрещен, возвращает редирект на главную страницу, иначе undefined.
 */
export const checkSportAccessOnServer = (): { redirect: Redirect } | null => {
  if (!process.env.NEXT_PUBLIC_SPORT_ACCESS) {
    return {
      redirect: { destination: routes.home, permanent: false },
    };
  }

  return null;
};
