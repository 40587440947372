import type { DropdownTransition } from '@mwl/ui';

export const menuTransition: DropdownTransition = {
  initial: ({ side }) => ({
    opacity: 0,
    transform: side === 'bottom' ? 'translateY(5px)' : 'translateY(-5px)',
  }),
  close: ({ side }) => ({
    opacity: 0,
    transform: side === 'bottom' ? 'translateY(5px)' : 'translateY(-5px)',
  }),
  duration: 250,
};
