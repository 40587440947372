import type { FormSettings } from '@mwl/core-lib';
import {
  isEmail,
  isNotEmpty,
  minLengthValidator,
  minPasswordLength,
  onlyNumbers,
  removeAllSpaces,
  requiredStringValidator,
  requiredValidator,
} from '@mwl/core-lib';

import type { SecondStepRegistrationState, SecondStepRegistrationValues } from './SecondStepRegistration.types';

export const initialState: SecondStepRegistrationState = {
  error: null,
  isLoading: false,
  isSuccess: false,
};

const regexpOnlyString = /^[\p{Letter}\p{Mark}]+$/u;

export const formSettings: FormSettings<SecondStepRegistrationValues> = {
  code: {
    rules: {
      required: { validate: isNotEmpty, message: 'error' },
    },
    value: { format: '', label: '', value: '', prefix: '' },
  },
  firstName: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      onlyString: {
        validate: (value) => regexpOnlyString.test(value),
        message: 'error',
      },
    },
    value: '',
  },
  lastName: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      onlyString: { validate: (value) => regexpOnlyString.test(value), message: 'error' },
    },
    value: '',
  },
  dateOfBirth: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
    },
    value: '',
  },
  phone: {
    rules: {
      required: { validate: requiredStringValidator, message: 'registration.full.error_phone' },
      minLength: {
        validate: (value, state) =>
          minLengthValidator(removeAllSpaces(state?.code.format || '').length)(onlyNumbers(value)),
        message: 'registration.full.error_phone',
      },
    },
    value: '',
  },
  email: {
    rules: {
      required: { validate: requiredStringValidator, message: 'registration.full.error_email' },
      email: { validate: isEmail, message: 'registration.full.error_email' },
    },
    value: '',
  },
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'registration.full.error_password' },
      minLength: { validate: minLengthValidator(minPasswordLength), message: 'registration.full.error_password' },
    },
    value: '',
  },
  currency: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '' },
  },
  country: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '' },
  },
  zipCode: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
    },
    value: '',
  },
  address: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
    },
    value: '',
  },
};
