import type { FC } from 'react';
import { QRCodeSVG } from 'qrcode.react';

import type { PaymentQrCodeFieldProps } from './PaymentQrCodeField.types';

import styles from './PaymentQrCodeField.module.scss';

export const PaymentQrCodeField: FC<PaymentQrCodeFieldProps> = ({ value }) => {
  return (
    <div className={styles.codeWrapper}>
      {value && (
        <QRCodeSVG
          className={styles.code}
          value={value}
          bgColor="transparent"
          fgColor="#000"
          level="L"
          includeMargin={false}
        />
      )}
    </div>
  );
};
