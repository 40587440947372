import type { FC } from 'react';
import { useCallback } from 'react';
import { useDeposit } from '@mwl/core-lib';

import CloseIcon from '@public/assets/common/icons/close.svg';

import { Popup } from '@/components/Popup/Popup';
import { PaymentTextElement } from '@/modules/payment/components/PaymentTextElement/PaymentTextElement';

import styles from './PopupDeclineStatus.module.scss';

export const PopupDeclineStatus: FC = () => {
  const { content, changeContent } = useDeposit();

  const resetContent = useCallback(() => {
    changeContent({ form: 'create' });
  }, [changeContent]);

  return (
    <Popup show={content.form === 'decline'} onClose={resetContent} disableScrollOnFocus={false}>
      <div className={styles.root}>
        <div className={styles.iconWrapper}>
          <CloseIcon width={16} height={16} className={styles.icon} />;
        </div>
        <div className={styles.content}>
          {content.textFormElements?.map((item, idx) => (
            <PaymentTextElement {...item} key={idx} className={styles.field} />
          ))}
        </div>
      </div>
    </Popup>
  );
};
