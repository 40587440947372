import { byKey, complex, simple } from '@mwl/core-lib';

const filterSectionSchema = complex({
  filterContent: simple,
  itemCheckbox: simple,
});

export const casinoFiltersSchema = complex({
  searchField: simple,
  filtersToggle: simple,
  filterSections: byKey(filterSectionSchema),
});
