import { complex, simple } from '@mwl/core-lib';

import { authPopupSchema } from '../authPopup';
import { depositPopupSchema } from '../depositPopup';

export const globalPopupsSchema = complex({
  auth: authPopupSchema,
  deposit: depositPopupSchema,
  bonus: simple,
});
