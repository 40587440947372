import { complex, simple } from '@mwl/core-lib';

export const liveCasinoPageSchema = complex({
  topGames: simple,
  favoriteGames: simple,
  vipGames: simple,
  newGames: simple,
  gameShows: simple,
  allGames: simple,
  recommended: simple,
  promotions: simple,
});
