import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import type { BonusPacketRegistrationType } from '@mwl/core-lib';
import {
  formatBonusPacketsToRegisterBonuses,
  getBonusPackets,
  useQueryPopupOpen,
  useRegistrationBonuses,
} from '@mwl/core-lib';

import { registrationBonuses, routes } from '@/constants';

import type { RegistrationBonusItem } from '@/types';

export interface RegisterContextProps {
  bonuses: Array<RegistrationBonusItem>;
  selectedBonus: RegistrationBonusItem;
  currency: string;
  changeBonus(bonusType: BonusPacketRegistrationType): void;
  onSuccessRegister(): void;
  updateCurrency(currency: string): void;
}

export const RegisterContext = createContext<RegisterContextProps>({} as RegisterContextProps);

export const useRegister = (): RegisterContextProps => useContext(RegisterContext);

export const useProvideRegister = (): RegisterContextProps => {
  const defaultBonuses = useRegistrationBonuses(registrationBonuses);

  const { onOpenPopup } = useQueryPopupOpen();
  const router = useRouter();
  const isDemoMode = router.query.mode === 'demo';
  const isGamePage = router.pathname === routes.casino.game;

  const selectedCurrency = useRef('');
  const timerId = useRef<NodeJS.Timeout>();

  const [selectedBonusType, setSelectedBonusType] = useState<BonusPacketRegistrationType>();
  const [bonuses, setBonuses] = useState(defaultBonuses);

  useEffect(() => {
    setBonuses(defaultBonuses);
  }, [defaultBonuses]);

  const changeBonus: RegisterContextProps['changeBonus'] = useCallback(
    (bonusType) => setSelectedBonusType(bonusType),
    [],
  );

  const onSuccessRegister = useCallback(async () => {
    if (isGamePage && isDemoMode) {
      setTimeout(() => {
        router.push({
          pathname: routes.casino.game,
          query: { gameId: router.query.gameId, mode: 'real', deposit: 1 },
        });
      }, 200);

      return;
    }

    if (!isGamePage) {
      setTimeout(() => onOpenPopup({ queryName: 'deposit' }), 200);
    }
  }, [isDemoMode, isGamePage, onOpenPopup, router]);

  const loadBonusesByCurrency = useCallback(async (currency: string) => {
    const { data: bonusPackets, status } = await getBonusPackets({ currency });

    if (status === 200 && bonusPackets) {
      const availableBonusPackets = bonusPackets.filter((bonus) => bonus.available);

      setBonuses(formatBonusPacketsToRegisterBonuses(availableBonusPackets, registrationBonuses));
    }
  }, []);

  const updateCurrency = useCallback(
    (currency: string) => {
      clearTimeout(timerId.current as NodeJS.Timeout);

      timerId.current = setTimeout(() => {
        if (currency !== selectedCurrency.current) {
          if (selectedCurrency.current) {
            loadBonusesByCurrency(currency);
          }

          selectedCurrency.current = currency;
        }
      }, 100);
    },
    [loadBonusesByCurrency],
  );

  const selectedBonus = useMemo(() => {
    const targetBonus = bonuses.find((bonus) => bonus.type === selectedBonusType);
    return targetBonus ?? bonuses[0];
  }, [bonuses, selectedBonusType]);

  return { bonuses, selectedBonus, changeBonus, onSuccessRegister, updateCurrency, currency: selectedCurrency.current };
};

export const RegisterProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useProvideRegister();

  return <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>;
};
