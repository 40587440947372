import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import type { Analytics } from '@mwl/core-lib';
import {
  fetchLogin,
  loginSuccess,
  removeAllSpaces,
  sendAnalyticsData,
  useForm,
  useSubmitFormAuth,
} from '@mwl/core-lib';

import MailIcon from '@public/assets/common/icons/mail.svg';

import { FormField } from '@/components/FormField/FormField';
import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { useTypedDispatch } from '@/hooks';
import { formLoginObject } from '@/utils/markerTree';

import { ButtonSignIn } from '../ButtonSignIn/ButtonSignIn';
import { ForgotButton } from '../ForgotButton/ForgotButton';

import type { FormLoginEmailProps, FormLoginEmailValues } from './FormLoginEmail.types';
import { formSettings } from './FormLoginEmail.utils';

import styles from './FormLoginEmail.module.scss';

const BaseFormLoginEmail: FC<FormLoginEmailProps> = ({ className, onSuccess, analytics }) => {
  const { t } = useTranslation('common');

  const dispatch = useTypedDispatch();

  const { changeField, formState, handleSubmit } = useForm<FormLoginEmailValues>(formSettings);

  const {
    state: { error, isLoading },
    onSubmitConfirm,
  } = useSubmitFormAuth({
    requestHandler: fetchLogin,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.token) {
        sendAnalyticsData(analytics?.submit?.success?.eventName, analytics?.submit?.success?.data);
        dispatch(loginSuccess({ token: data.token }));
        onSuccess();
      }
    },
  });

  const checkFieldError = useCallback(
    (fieldName: keyof FormLoginEmailValues) => {
      return Boolean(
        (formState.isTouched && formState.errors && formState.errors[fieldName]) || error?.type === '/bad-credentials',
      );
    },
    [formState, error],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;

      changeField(name as keyof FormLoginEmailValues, removeAllSpaces(value));
    },
    [changeField],
  );

  const submitConfirm = useCallback(
    (value: FormLoginEmailValues) => {
      onSubmitConfirm({
        credentialSubject: value.email,
        password: value.password,
        rememberMe: true,
      });
    },
    [onSubmitConfirm],
  );

  const handleBlur = (fieldAnalytics?: Analytics) => () => {
    sendAnalyticsData(fieldAnalytics?.change?.eventName, fieldAnalytics?.change?.data);
  };

  useEffect(() => {
    const errors = [];

    if (checkFieldError('email')) {
      errors.push('email');
    }

    if (checkFieldError('password')) {
      errors.push('password');
    }

    if (errors.length) {
      sendAnalyticsData(analytics?.submit?.error?.eventName, {
        ...(analytics?.submit?.error?.data || {}),
        form_errors: errors,
      });
    }
  }, [analytics, checkFieldError]);

  return (
    <form
      {...formLoginObject.emailTab.nodeProps}
      className={cn(styles.root, className)}
      onSubmit={handleSubmit(submitConfirm)}
    >
      {error && (
        <FormMessage className={styles.message}>
          {Array.isArray(error.message) ? t(error.message) : error.message}
        </FormMessage>
      )}
      {isLoading && <Loader />}
      <FormField
        {...formLoginObject.emailTab.emailInput.nodeProps}
        inputMode="email"
        autoComplete="email"
        autoCapitalize="off"
        as="input"
        placeholder={t('form.field.email', 'E-mail')}
        value={formState.values.email}
        name="email"
        prefix={<MailIcon className={styles.mailIcon} />}
        onChange={handleChange}
        onBlur={handleBlur(analytics?.email)}
        className={styles.field}
        classes={{ component: styles.input }}
        isError={checkFieldError('email')}
      />
      <FormFieldHidden
        {...formLoginObject.emailTab.passwordInput.nodeProps}
        placeholder={t('form.field.password', 'Password')}
        value={formState.values.password}
        onChange={handleChange}
        onBlur={handleBlur(analytics?.password)}
        name="password"
        className={styles.field}
        isError={checkFieldError('password')}
        classes={{ component: styles.input }}
        analytics={analytics?.password}
      />
      <ForgotButton analytics={analytics?.forgotLink} {...formLoginObject.emailTab.resetLink.nodeProps} />
      <ButtonSignIn analytics={analytics?.signIn} {...formLoginObject.emailTab.submitButton.nodeProps} />
    </form>
  );
};

const FormLoginEmail = memo(BaseFormLoginEmail);

export * from './FormLoginEmail.types';
export { FormLoginEmail };
