import type { ValidatorGCBParamsByHost, WidgetLicenceValidator } from '@/types';

export const ALLOWED_VALIDATOR_HOSTS = ['banzai.bet'];

export const widgetLicenceValidator: WidgetLicenceValidator = {
  id: 'apg-3f024ff0-fcd6-4654-8a31-f39167f48ae0',
  sealId: '3f024ff0-fcd6-4654-8a31-f39167f48ae0',
  imageSize: '256',
  imageType: 'basic-light-large',
};

export const VALIDATOR_GCB_PARAMS_BY_HOST: ValidatorGCBParamsByHost = {
  'banzai.bet': {
    imageSrc: 'https://seal.cgcb.info/8b089e55-4795-425a-6bf6-fdb75eba6900',
    imageAlt: 'GCB Certificate for banzai.bet',
    href: 'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJazByWlVFMFJsbFNSRWQwVnpnNVNISkVMMVkwVFZFOVBTSXNJblpoYkhWbElqb2lPR0pNTTNOTkt6ZzVUQ3R3V1hKc04wZ3ZLMVJOVVQwOUlpd2liV0ZqSWpvaU1UbGpaR000WWpjM09EVXpOVEZtT0RoaE4yVTFORGcwTmpjd05XTTROV1ExTldZMlpHUXlZekF5T1RBNU0yVTBPV1l3TjJJd05EVmpZbVkwTWpSbU9DSXNJblJoWnlJNklpSjk=',
  },
};
