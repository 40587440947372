import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { loginSuccess, removeAllSpaces, resetPasswordByCodeId, useForm, useSubmitFormAuth } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';
import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { useTypedDispatch } from '@/hooks';
import { formRecoveryPasswordObject } from '@/utils';

import { useRecoveryState } from '../../FormRecovery.context';

import type { FormRecoveryPasswordProps, FormRecoveryPasswordValues } from './FormRecoveryPassword.types';
import { formSettings } from './FormRecoveryPassword.utils';

import styles from './FormRecoveryPassword.module.scss';

const BaseFormRecoveryPassword: FC<FormRecoveryPasswordProps> = ({ className }) => {
  const { t } = useTranslation('common');

  const dispatch = useTypedDispatch();

  const { codeId } = useRecoveryState();

  const { changeField, formState, handleSubmit } = useForm<FormRecoveryPasswordValues>(formSettings);

  const {
    state: { error, isLoading },
    onSubmitConfirm,
  } = useSubmitFormAuth({
    requestHandler: resetPasswordByCodeId,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.token) {
        dispatch(loginSuccess({ token: data.token }));
      }
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;

      changeField(name as keyof FormRecoveryPasswordValues, removeAllSpaces(value));
    },
    [changeField],
  );

  const submitConfirm = useCallback(
    async (value: FormRecoveryPasswordValues) => {
      onSubmitConfirm({ codeId, password: value.password });
    },
    [codeId, onSubmitConfirm],
  );

  const errorText = useMemo(() => {
    let errorMessage;

    if (error) {
      errorMessage = Array.isArray(error?.message) ? t(error.message) : error.message;
    }

    if (formState.isTouched) {
      if (formState.errors?.password) {
        errorMessage = t(formState.errors.password);
      } else if (formState.errors?.confirm) {
        errorMessage = t(formState.errors.confirm);
      }
    }

    if (errorMessage) {
      return <FormMessage>{errorMessage}</FormMessage>;
    }

    return null;
  }, [error, formState.errors.confirm, formState.errors.password, formState.isTouched, t]);

  return (
    <form
      {...formRecoveryPasswordObject.nodeProps}
      className={cn(styles.root, className)}
      onSubmit={handleSubmit(submitConfirm)}
    >
      {errorText}
      {isLoading && <Loader />}
      <FormFieldHidden
        {...formRecoveryPasswordObject.newPasswordField.nodeProps}
        placeholder={t('form.field.new_password', 'New password')}
        value={formState.values.password}
        onChange={handleChange}
        name="password"
        className={styles.field}
        isError={Boolean(formState.isTouched && formState.errors?.password)}
      />
      <FormFieldHidden
        {...formRecoveryPasswordObject.confirmPasswordField.nodeProps}
        placeholder={t('form.field.confirm_password', 'Confirm password')}
        value={formState.values.confirm}
        onChange={handleChange}
        name="confirm"
        className={styles.field}
        isError={Boolean(formState.isTouched && formState.errors?.confirm)}
      />
      <Button
        {...formRecoveryPasswordObject.submitButton.nodeProps}
        as="button"
        variant="fulfilled"
        color="red"
        fontSize="xl"
        fontWeight="bold"
        size="md"
        fullWidth
        className={styles.button}
        type="submit"
        rounded
      >
        {t('recovery.button.save', 'Save')}
      </Button>
    </form>
  );
};

const FormRecoveryPassword = memo(BaseFormRecoveryPassword);

export * from './FormRecoveryPassword.types';
export { FormRecoveryPassword };
