import type { ChangeEventHandler, Ref } from 'react';
import { forwardRef, memo, useCallback, useMemo } from 'react';

import { FormField } from '@/components/FormField/FormField';
import type { SelectProps } from '@/components/Select/Select';
import { Select } from '@/components/Select/Select';

import type { FormFieldPhoneOption, FormFieldPhoneProps } from './FormFieldPhone.types';
import { createFieldMask, filterItem, getFieldFormat } from './FormFieldPhone.utils';

import styles from './FormFieldPhone.module.scss';

const BaseFormFieldPhone = <T extends FormFieldPhoneOption>(
  {
    className,
    options,
    value,
    selected,
    onChange,
    onSelect,
    parentRef,
    sizeVariant,
    adaptive = true,
    classes = {},
    variant = 'default',
    analytics,
    ...props
  }: FormFieldPhoneProps<T>,
  ref: Ref<HTMLInputElement | HTMLTextAreaElement>,
): JSX.Element => {
  const selectNumberFormat: SelectProps<T>['numberFormat'] = useMemo(
    () => ({ prefix: '+ ', allowEmptyFormatting: true }),
    [],
  );

  const handleSelect: SelectProps<T>['onSelect'] = useCallback((option) => onSelect(option), [onSelect]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => onChange(event.target.value),
    [onChange],
  );

  const formFieldClasses = { ...classes, component: cn(classes?.component, styles.input) };

  return (
    <div className={cn(styles.root, className)}>
      <Select
        selected={selected}
        options={options}
        onSelect={handleSelect}
        className={styles.select}
        numberFormat={selectNumberFormat}
        filter={filterItem}
        parentRef={parentRef}
        sizeVariant={sizeVariant}
        headerClasses={{ input: classes?.component }}
        adaptive={adaptive}
        variant={variant}
        analytics={analytics}
      />
      <FormField
        {...props}
        ref={ref}
        as="numberFormat"
        autoComplete="off"
        value={value}
        onChange={handleChange}
        format={getFieldFormat(selected.format)}
        mask={createFieldMask(selected.format, 'X')}
        allowEmptyFormatting
        className={cn(styles.field, {
          [styles.empty]: !value.replace(/\D/g, '').length,
        })}
        sizeVariant={sizeVariant}
        variant={variant}
        classes={formFieldClasses}
      />
    </div>
  );
};

const FormFieldPhone = memo(forwardRef(BaseFormFieldPhone));

export * from './FormFieldPhone.types';
export { FormFieldPhone };
