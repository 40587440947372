import { useCallback, useState } from 'react';
import type { PaymentHistoryItem } from '@mwl/core-lib';
import { loadHistoryRequest } from '@mwl/core-lib';

import { useTypedDispatch } from '@/hooks';

import type { PaymentsHistoryState } from './PaymentsHistory.types';

const initialState: PaymentsHistoryState = {
  form: null,
};

export const usePaymentsHistoryState = () => {
  const dispatch = useTypedDispatch();

  const [state, setState] = useState<PaymentsHistoryState>(initialState);

  const handleOpenVerification = useCallback(
    (item: PaymentHistoryItem) => setState((prev) => ({ ...prev, selectedItem: item, form: 'VERIFICATION' })),
    [],
  );

  const handleOpenConfirmCancelling = useCallback(
    (item: PaymentHistoryItem) => setState((prev) => ({ ...prev, selectedItem: item, form: 'CANCEL_CONFIRM' })),
    [],
  );

  const handleOpenBalanceInfo = useCallback(
    (item: PaymentHistoryItem) => setState((prev) => ({ ...prev, selectedItem: item, form: 'BALANCE_INFO' })),
    [],
  );

  const handleClose = useCallback(() => {
    setState((prev) => ({ ...prev, form: null, error: null }));
  }, []);

  const handleCloseVerification = useCallback(
    (success: boolean) => {
      if (success) {
        dispatch(loadHistoryRequest());
      }

      setState((prev) => ({ ...prev, form: null, error: null }));
    },
    [dispatch],
  );

  return {
    ...state,
    handleOpenVerification,
    handleClose,
    handleCloseVerification,
    handleOpenConfirmCancelling,
    handleOpenBalanceInfo,
  };
};
