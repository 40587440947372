import type { TippyProps } from '@tippyjs/react';

/**
 * Настройки попапа для popper.
 */
export const getPopperOptions = ({ isMobile }: { isMobile: boolean }): TippyProps['popperOptions'] => ({
  modifiers: [
    {
      name: 'flip',
      options: { fallbackPlacements: ['bottom-start'] },
    },
    {
      name: 'preventOverflow',
      options: {
        padding: isMobile ? 0 : 10,
      },
    },
    // Set width popper to be same as container
    {
      enabled: true,
      fn: ({ instance, state }) => {
        const triggerReferenceWidth = `${state.rects.reference.width}px`;

        if (state.styles.popper.width !== triggerReferenceWidth) {
          state.styles.popper.width = triggerReferenceWidth;
          instance.update();
        }
      },
      name: 'setWidth',
      phase: 'beforeWrite',
      requires: ['computeStyles'],
    },
  ],
});
