import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { Promotion } from '@mwl/core-lib';
import {
  getPromotions,
  getTranslationSubjectList,
  promotionsMapper,
  selectUserCurrency,
  selectUserLocale,
  useCancelRequest,
} from '@mwl/core-lib';

import { useTypedSelector } from '../useTypedSelector';

import type { UsePromoState } from './usePromo.types';

/**
 * Получает переводы для акции.
 * @param promoId - Идентификатор акции.
 * @param locale - Локаль для получения переводов.
 * @returns Массив переводов акции.
 */
export const getPromotionTranslations = async (promoId: string, locale: string) => {
  const { status, data } = await getTranslationSubjectList({ locale, subject_id: promoId, project_name: 'banzai' });

  if (status === 200 && data?.values) {
    return data.values;
  }

  return [];
};

/**
 * Хук для получения и управления промо-акциями.
 * @returns Объект состояния промо-акций.
 */
export const usePromo = () => {
  const { t } = useTranslation('promo');

  const cancelRequest = useCancelRequest();

  const locale = useTypedSelector(selectUserLocale);
  const currency = useTypedSelector(selectUserCurrency);

  const [promoPage, setPromoPage] = useState<UsePromoState>({ isLoading: false, isLoaded: false, items: [] });

  useEffect(() => {
    const fetchPromotion = async () => {
      setPromoPage((prev) => ({ ...prev, isLoading: true, isLoaded: false }));

      const { data, status } = await getPromotions({ locale });

      let promotions: Array<Promotion> = [];

      if (status === 200 && data) {
        promotions = promotionsMapper(data);
      }

      if (!cancelRequest.current) {
        setPromoPage({ items: promotions, isLoading: false, isLoaded: true });
      }
    };

    if (!promoPage.isLoading && !promoPage.isLoaded && locale && currency) {
      fetchPromotion();
    }
  }, [promoPage.isLoaded, promoPage.isLoading, locale, currency, t, cancelRequest]);

  return promoPage;
};

export * from './usePromo.types';
