import type { FC } from 'react';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { UrlQuery } from '@mwl/core-lib';
import {
  extendAnalyticsData,
  getDomain,
  selectUserProfile,
  updateBonusPacketsRequest,
  useQueryPopupOpen,
} from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';
import { SocialList } from '@/components/SocialList/SocialList';
import { routes } from '@/constants';
import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { formLoginObject } from '@/utils/markerTree';

import { AuthContainerHeader } from '../AuthContainerHeader/AuthContainerHeader';
import { Tab } from '../Tab/Tab';
import { Tabs } from '../Tabs/Tabs';

import { FormLoginEmail } from './components/FormLoginEmail/FormLoginEmail';
import { FormLoginPhone } from './components/FormLoginPhone/FormLoginPhone';
import { tabsContent } from './FormLogin.data';
import type { FormLoginProps, FormLoginTabsContent, OnTabChange } from './FormLogin.types';

import styles from './FormLogin.module.scss';

const BaseFormLogin: FC<FormLoginProps> = ({ tab, setTab, analytics }) => {
  const { t } = useTranslation('common');

  const { country } = useTypedSelector(selectUserProfile);

  const dispatch = useTypedDispatch();

  const formRef = useRef(null);

  const router = useRouter();
  const domain = getDomain();
  const { createLinkProps } = useQueryPopupOpen();

  const registerLinkProps = useMemo(() => createLinkProps({ queryName: 'register' }), [createLinkProps]);

  const socialQuery: UrlQuery = useMemo<UrlQuery>(
    () => ({
      domain,
      bonus_type: 'CASINO',
      currency: 'AZN',
      ...(country ? { country } : {}),
    }),
    [domain, country],
  );

  const onTabChange: OnTabChange = useCallback((newValue) => setTab(newValue), [setTab]);

  const onSuccess = useCallback(async () => {
    dispatch(updateBonusPacketsRequest());

    if (router.pathname === routes.casino.game && router.query.mode === 'demo') {
      setTimeout(() => {
        router.push({ pathname: router.pathname, query: { gameId: router.query.gameId, mode: 'real' } });
      }, 100);
    }
  }, [dispatch, router]);

  return (
    <div {...formLoginObject.nodeProps} className={styles.root} ref={formRef}>
      <AuthContainerHeader variant="login" showText={false} className={styles.header} />

      <Tabs {...formLoginObject.tabs.nodeProps} className={styles.tabs}>
        {(Object.keys(tabsContent) as Keys<FormLoginTabsContent>).map((key) => (
          <Tab
            {...formLoginObject.tabs(key).nodeProps}
            as="button"
            className={styles.tab}
            label={t(tabsContent[key])}
            key={key}
            active={key === tab}
            onClick={() => onTabChange(key)}
            analytics={extendAnalyticsData(analytics?.tabs, { auth_type: key, text: t(tabsContent[key]) })}
          />
        ))}
      </Tabs>

      <FormLoginPhone
        className={cn(styles.form, { [styles.active]: tab === 'phone' })}
        parentRef={formRef}
        onSuccess={onSuccess}
        analytics={analytics?.form}
      />
      <FormLoginEmail
        className={cn(styles.form, { [styles.active]: tab === 'email' })}
        onSuccess={onSuccess}
        analytics={analytics?.form}
      />

      <SocialList
        title={t('auth.social_title', 'You can login with social networks')}
        query={socialQuery}
        analytics={analytics?.form?.social}
      />
      <Button
        as="link"
        size="md"
        fontSize="lg"
        fontWeight="bold"
        fullWidth
        className={styles.buttonWithoutAccount}
        rounded
        {...registerLinkProps}
        {...formLoginObject.signUpButton.nodeProps}
      >
        {t('auth.not_have_account', `I don't have an account`)}
      </Button>
    </div>
  );
};

const FormLogin = memo(BaseFormLogin);

export * from './FormLogin.types';
export { FormLogin };
