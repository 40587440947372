import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import type { BonusPacketRegistrationType } from '@mwl/core-lib';
import { formatPhoneNumber, selectUserLocale, useForm } from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks';

import type { FormRegistrationFullData, FormRegistrationFullOnChangeStep } from '../../FormRegistrationFull.types';

import type { SecondStepRegistrationValues } from './SecondStepRegistration.types';
import { formSettings } from './SecondStepRegistration.utils';

interface UseSecondStepRegistrationFormParams {
  setFormData: Dispatch<SetStateAction<FormRegistrationFullData | undefined>>;
  bonusType?: BonusPacketRegistrationType;
  onChangeStep: FormRegistrationFullOnChangeStep;
}

export function useSecondStepRegistrationForm({
  setFormData,
  bonusType,
  onChangeStep,
}: UseSecondStepRegistrationFormParams) {
  const locale = useTypedSelector(selectUserLocale);

  const { changeField, formState, handleSubmit } = useForm<SecondStepRegistrationValues>(formSettings);

  const submitConfirm = useCallback(
    async (values: SecondStepRegistrationValues) => {
      const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';
      const { firstName, lastName, dateOfBirth, currency, code, country, password, email, address, zipCode } = values;

      setFormData({
        firstName,
        lastName,
        dateOfBirth,
        currencyIsoCode: currency.label,
        bonusType,
        locale,
        countryAlpha2IsoCode: country.value,
        phonePrefix: code.label,
        phone: formatPhoneNumber(values.code.value, values.phone),
        password,
        email,
        timezone,
        address,
        zipCode,
      });

      onChangeStep('third');
    },
    [bonusType, locale, setFormData, onChangeStep],
  );

  return {
    changeField,
    formState,
    handleSubmit: handleSubmit(submitConfirm),
  };
}
