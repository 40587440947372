import type { ChangeEventHandler } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import {
  extendAnalyticsData,
  formatResponseErrors,
  loginSuccess,
  registerSuccess,
  registrationByEmail,
  removeAllSpaces,
  selectUserLocale,
  sendAnalyticsData,
  setRecoveryLogin,
  useForm,
  useQueryPopupOpen,
  useSubmitFormAuth,
} from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';

import { useRegister } from '@/context/register/register';
import { useTypedDispatch, useTypedSelector } from '@/hooks';

import type { RegistrationCountryOption } from '@/types';

import type {
  FormRegistrationEmailValues,
  UseRegistrationEmailFormParams,
  UseRegistrationEmailHandlersParams,
} from './FormRegistrationEmail.types';
import { formSettings } from './FormRegistrationEmail.utils';

export function useRegistrationEmailForm({ analytics }: UseRegistrationEmailFormParams) {
  const { formState, changeField, handleSubmit, setErrors } = useForm<FormRegistrationEmailValues>(formSettings);

  const dispatch = useTypedDispatch();

  const locale = useTypedSelector(selectUserLocale);

  const { selectedBonus, onSuccessRegister } = useRegister();

  const { onOpenPopup } = useQueryPopupOpen();

  const fullAnalytics = useMemo(
    () =>
      extendAnalyticsData(analytics, {
        with_promocode: !!formState.values.promoCode,
        promocode: formState.values.promoCode || undefined,
      }),
    [analytics, formState.values.promoCode],
  );

  const {
    state: { error, isLoading },
    onSubmitConfirm,
  } = useSubmitFormAuth({
    requestHandler: registrationByEmail,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.token) {
        sendAnalyticsData(fullAnalytics?.success?.eventName, fullAnalytics?.success?.data);
        dispatch(registerSuccess());
        dispatch(loginSuccess({ token: data.token, skipAuthReload: true }));
        onSuccessRegister();
      }
    },
    onError: (formError) => {
      const isEmailAlreadyExist = !!formError.details?.find((detail) => detail.key === 'error.emailAlreadyExists');

      setErrors(formatResponseErrors<typeof formState.errors>(formError));

      if (isEmailAlreadyExist) {
        dispatch(setRecoveryLogin(formState.values.email));
        onOpenPopup({ queryName: 'reset_password' });
      }
    },
  });

  const submitConfirm = async (value: FormRegistrationEmailValues) => {
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';

    onSubmitConfirm({
      countryAlpha2IsoCode: value.country.value,
      currencyIsoCode: value.currency.label,
      email: value.email,
      password: value.password,
      bonusType: selectedBonus.type !== 'REFUSAL' ? selectedBonus.type : undefined,
      promoCode: value.promoCode || undefined,
      timezone,
      locale,
    });
  };

  useEffect(() => {
    const { type = '', errors } = formState;
    const eventName = fullAnalytics?.error?.eventName;

    if (!['VALIDATE_ALL', 'SET_ERRORS'].includes(type) || (!errors?.email && !errors?.password)) {
      return;
    }

    const errorFields: Array<string> = [];

    if (errors?.email) {
      errorFields.push('email');
    }

    if (errors?.password) {
      errorFields.push('password');
    }

    const eventSuffix = formState.type === 'VALIDATE_ALL' ? '_front_error' : '_back_error';

    sendAnalyticsData(`${eventName}${eventSuffix}`, {
      ...(fullAnalytics?.error?.data || {}),
      form_errors: errorFields,
    });
  }, [formState, fullAnalytics?.error?.data, fullAnalytics?.error?.eventName]);

  return {
    changeField,
    handleSubmit: handleSubmit(submitConfirm),
    isLoading,
    error,
    onSubmitConfirm,
    formState,
  };
}

export function useRegistrationEmailHandlers({ changeField }: UseRegistrationEmailHandlersParams) {
  const { updateCurrency } = useRegister();

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value, checked, type } = event.target;

      const typedName = name as keyof FormRegistrationEmailValues;

      const typedValue = (
        type === 'checkbox' ? checked : removeAllSpaces(value)
      ) as ValueOf<FormRegistrationEmailValues>;

      changeField(typedName, typedValue);
    },
    [changeField],
  );

  const handleSelectCurrency = useCallback(
    (option: SelectOption) => {
      updateCurrency(option.value);
      changeField('currency', option);
    },
    [changeField, updateCurrency],
  );

  const handleSelectCountry = useCallback(
    (option: RegistrationCountryOption) => {
      changeField('country', option);
    },
    [changeField],
  );

  return {
    handleInputChange,
    handleSelectCurrency,
    handleSelectCountry,
  };
}
