import type { FormSettings } from '@mwl/core-lib';
import {
  minLengthValidator,
  onlyNumbers,
  removeAllSpaces,
  requiredStringValidator,
  requiredValidator,
} from '@mwl/core-lib';

import type { FormLoginPhoneValues } from './FormLoginPhone';

export const formSettings: FormSettings<FormLoginPhoneValues> = {
  code: {
    rules: {
      required: {
        validate: requiredValidator,
        message: 'error',
      },
    },
    value: { format: '', label: '', value: '', prefix: '' },
  },
  phone: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      minLength: {
        validate: (value, state) =>
          minLengthValidator(removeAllSpaces(state?.code.format || '').length)(onlyNumbers(value)),
        message: 'error',
      },
    },
    value: '',
  },
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
    },
    value: '',
  },
};
