import type { FormSettings } from '@mwl/core-lib';
import {
  isNotEmpty,
  minLengthValidator,
  minPasswordLength,
  onlyNumbers,
  removeAllSpaces,
  requiredStringValidator,
  requiredValidator,
} from '@mwl/core-lib';

import type { FormRegistrationPhoneState, FormRegistrationPhoneValues } from './FormRegistrationPhone.types';

export const initialState: FormRegistrationPhoneState = {
  error: null,
  isLoading: false,
  isSuccess: false,
};

export const formSettings: FormSettings<FormRegistrationPhoneValues> = {
  code: {
    rules: {
      required: { validate: isNotEmpty, message: 'error' },
    },
    value: { format: '', label: '', value: '', prefix: '' },
  },
  country: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '' },
  },
  phone: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error.requiredField_common' },
      minLength: {
        validate: (value, state) =>
          minLengthValidator(removeAllSpaces(state?.code.format || '').length)(onlyNumbers(value)),
        message: 'error.phone.wrongFormat',
      },
    },
    value: '',
  },
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error.requiredField_common' },
      minLength: { validate: minLengthValidator(minPasswordLength), message: 'error.password.wrongFormat' },
    },
    value: '',
  },
  currency: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '' },
  },
  promoCode: {
    value: '',
  },
  agree: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: true,
  },
};
