import type { FC } from 'react';
import { isMobile } from 'react-device-detect';

import { DatepickerDynamic } from '@/components/DatepickerDynamic/DatepickerDynamic';
import { DatepickerNative } from '@/components/DatepickerNative/DatepickerNative';

import type { DatepickerAdaptiveProps } from './DatepickerAdaptive.types';

const DatepickerAdaptive: FC<DatepickerAdaptiveProps> = ({ adaptive, ...props }) => {
  if (adaptive && isMobile) {
    return <DatepickerNative {...props} />;
  }

  return <DatepickerDynamic {...props} />;
};

export { DatepickerAdaptive };
