import type { FormSettings } from '@mwl/core-lib';
import { minLengthValidator, requiredStringValidator } from '@mwl/core-lib';

import { CODE_LENGTH } from '@/constants';

import type { FormRecoveryCodeValues } from './FormRecoveryCode.types';

export const formSettings: FormSettings<FormRecoveryCodeValues> = {
  code: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      minLength: { validate: minLengthValidator(CODE_LENGTH), message: 'error' },
    },
    value: '',
  },
};
