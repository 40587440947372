import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { Analytics, TestProps } from '@mwl/core-lib';
import { extendAnalyticsData, useQueryPopupOpen } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';

import styles from './ForgotButton.module.scss';

export interface BaseForgotButtonProps extends TestProps {
  analytics?: Analytics<'click'>;
}

const BaseForgotButton = (props: BaseForgotButtonProps) => {
  const { t } = useTranslation('common');
  const { createLinkProps } = useQueryPopupOpen();
  const resetLinkProps = useMemo(() => createLinkProps({ queryName: 'reset_password' }), [createLinkProps]);

  const forgotButtonText = t('auth.forgot_password', 'Forgot password?');

  return (
    <Button
      as="link"
      variant="text"
      color="white"
      fontSize="sm"
      className={styles.root}
      uppercase={false}
      {...resetLinkProps}
      {...props}
      analytics={extendAnalyticsData(props?.analytics, { text: forgotButtonText })}
    >
      {forgotButtonText}
    </Button>
  );
};

const ForgotButton = memo(BaseForgotButton);

export { ForgotButton };
