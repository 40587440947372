import { complex, simple } from '@mwl/core-lib';

export const mobileIncidentsSchema = complex({
  id: simple,
  created: simple,
  status: simple,
  support: simple,
  subject: simple,
});

export const desktopIncidentsSchema = complex({
  table: simple,
  id: simple,
  created: simple,
  updated: simple,
  status: simple,
  support: simple,
  subject: simple,
});

export const incidentsPageSchema = complex({
  emptyContent: simple,
  container: simple,
  header: simple,
  nextPageButton: simple,
  mobileIncidents: mobileIncidentsSchema,
  desktopIncidents: desktopIncidentsSchema,
});
