import type { FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import type { Modal } from '@mwl/core-lib';
import { hideModal, selectActiveModal } from '@mwl/core-lib';

import { useTypedDispatch, useTypedSelector } from '@/hooks';

import { ModalMessage } from '../ModalMessage/ModalMessage';

const BaseModalMessageApp: FC = () => {
  const modal = useTypedSelector(selectActiveModal);

  const [state, setState] = useState<Modal>();

  const dispatch = useTypedDispatch();

  const handleClose = useCallback(() => {
    if (modal) {
      dispatch(hideModal({ id: modal.id }));
      modal.onClose?.();
    }
  }, [dispatch, modal]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (!modal) {
      timerId = setTimeout(() => {
        setState(undefined);
      }, 200);
    } else {
      setState(modal);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [modal]);

  return (
    <ModalMessage
      show={Boolean(modal)}
      status={state?.status}
      onClose={handleClose}
      buttonText={state?.buttonText}
      icon={state?.icon}
      title={state?.title}
      content={state?.message || ''}
      popupClasses={state?.popupClasses}
    >
      {state?.content}
    </ModalMessage>
  );
};

const ModalMessageApp = memo(BaseModalMessageApp);
export { ModalMessageApp };
