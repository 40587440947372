import type { ReactNode } from 'react';
import type { TFunction } from 'next-i18next';
import type { PaymentHistoryItem } from '@mwl/core-lib';
import { formatDateWithTranslationMonth, formatPriceByCurrencyCode, isNotNullOrUndefined } from '@mwl/core-lib';

import { PaymentStatus } from '@/modules/payment/components/PaymentStatus/PaymentStatus';

import type { ConfirmationFieldItem, ConfirmationFields, ConfirmationFieldsValues } from './ConfirmationPopup.types';

const fieldKeys: Record<keyof ConfirmationFields, ConfirmationFieldsValues> = {
  id: {
    key: ['need_confirmation.fields.transaction_number', 'Transaction number'],
  },
  createdAt: {
    key: ['need_confirmation.fields.createdAt', 'Date and time'],
    format: (item, t) => formatDateWithTranslationMonth(item.createdAt, t),
  },
  paymentMethod: {
    key: ['need_confirmation.fields.payment_system', 'Payment system'],
  },
  maskedCard: {
    key: ['need_confirmation.fields.requisites', 'Requisites'],
  },
  status: {
    key: ['need_confirmation.fields.status', 'Status'],
    format: (item) => <PaymentStatus item={item} />,
  },
  amount: {
    key: ['need_confirmation.fields.sum', 'Sum'],
    format: (item) => formatPriceByCurrencyCode(item.amount, item.currencyCode),
  },
  requisites: {
    key: ['need_confirmation.fields.requisites', 'Requisites'],
  },
};

export const createFields = (historyItem: PaymentHistoryItem, t: TFunction) => {
  return (Object.entries(fieldKeys) as Entries<typeof fieldKeys>).reduce((res, [fieldName, { key, format }]) => {
    if (isNotNullOrUndefined(historyItem[fieldName])) {
      res.push({
        key: t(key),
        value: format ? format(historyItem, t) : (historyItem[fieldName] as ReactNode),
      });
    }

    return res;
  }, [] as Array<ConfirmationFieldItem>);
};
