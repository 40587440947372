import type { PaymentMethodField } from '@mwl/core-lib';
import { getTransformCase } from '@mwl/core-lib';

import type { FormFieldAsNumberFormat } from '@/components/FormField/FormField.types';

import styles from './PaymentTextField.module.scss';

type GetNumberFieldPropsByNameData = Record<string, Omit<FormFieldAsNumberFormat, 'as' | 'onValueChange'>>;

interface GetNumberFieldPropsByNameProps {
  name: string;
  currencySymbol?: string;
}

interface GetNumberFieldPropsByNameResult extends Omit<FormFieldAsNumberFormat, 'as' | 'onValueChange'> {}

export const getNumberFieldPropsByName = ({
  name,
  currencySymbol = '',
}: GetNumberFieldPropsByNameProps): GetNumberFieldPropsByNameResult => {
  const result: GetNumberFieldPropsByNameData = {
    amount: {
      postfix: <span className={styles.amountPostfix}>{currencySymbol}</span>,
      allowLeadingZeros: false,
      allowEmptyFormatting: true,
      allowNegative: false,
      isAllowed: ({ value }) => value === value.replace(/^0\d+/, '0').replace(/^\./, ''),
    },
  };

  return result[name] || {};
};

export const prepareValue = (value: string, attr: PaymentMethodField['attr']) => {
  if (!value) {
    return value;
  }

  const transform = getTransformCase(attr);

  switch (transform) {
    case 'uppercase':
      return value.toUpperCase();
    default:
      return value;
  }
};
