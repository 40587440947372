import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { hideModal, useQueryPopupOpen } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';
import { useTypedDispatch } from '@/hooks';

import { bonusPacketModalId } from '../../BonusPacketCard.constants';

import type { BonusPacketCardPopupProps } from './BonusPacketCardPopup.types';

import styles from './BonusPacketCardPopup.module.scss';

const BonusPacketCardPopup: FC<BonusPacketCardPopupProps> = () => {
  const { t } = useTranslation('bonuses');

  const dispatch = useTypedDispatch();

  const { onOpenPopup } = useQueryPopupOpen();

  const handleClickLogin = useCallback(() => {
    dispatch(hideModal({ id: bonusPacketModalId }));
    onOpenPopup({ queryName: 'login' });
  }, [onOpenPopup, dispatch]);

  const handleClickRegister = useCallback(() => {
    dispatch(hideModal({ id: bonusPacketModalId }));
    onOpenPopup({ queryName: 'register' });
  }, [dispatch, onOpenPopup]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('auth_bonus_popup.title', "We didn't recognize you")}</div>
      <div className={styles.text}>
        {t('auth_bonus_popup.description', 'To play for real money, you need to sign in')}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={handleClickRegister}
          as="button"
          className={styles.button}
          size="md"
          fontWeight="bold"
          fontSize="lg"
          fullWidth
          variant="fulfilled"
          color="red"
          type="button"
          rounded
        >
          {t('auth_bonus_popup.sign_up', 'Sign Up')}
        </Button>
        <Button
          as="button"
          onClick={handleClickLogin}
          className={styles.button}
          size="md"
          fontWeight="bold"
          fontSize="lg"
          fullWidth
          variant="fulfilled"
          color="white"
          type="button"
          rounded
        >
          {t('auth_bonus_popup.sign_in', 'Sign In')}
        </Button>
      </div>
    </div>
  );
};

export { BonusPacketCardPopup };
