import type { FC } from 'react';
import { memo } from 'react';
import { useDeposit } from '@mwl/core-lib';

import { PaymentFormHeader } from '../../../../components/PaymentFormHeader/PaymentFormHeader';
import { PaymentTextElement } from '../../../../components/PaymentTextElement/PaymentTextElement';

import type { DepositFormTextProps } from './DepositFormText.types';

import styles from './DepositFormText.module.scss';

const BaseDepositFormText: FC<DepositFormTextProps> = ({ className }) => {
  const { changeContent, content } = useDeposit();

  const handleClose = () => changeContent({ form: 'create', template: null });

  return (
    <div className={cn(styles.root, className)}>
      <PaymentFormHeader className={styles.header} onClose={handleClose} />

      <div className={styles.content}>
        {content.textFormElements?.map((item, idx) => (
          <PaymentTextElement {...item} key={idx} className={styles.field} />
        ))}
      </div>
    </div>
  );
};

export * from './DepositFormText.types';
export const DepositFormText = memo(BaseDepositFormText);
