import type { FC } from 'react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { Analytics } from '@mwl/core-lib';
import { extendAnalyticsData, minLengthValidator, minPasswordLength, sendAnalyticsData } from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';

import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormFieldPhone } from '@/components/FormFieldPhone/FormFieldPhone';
import { FormFieldTooltip } from '@/components/FormFieldTooltip';
import { useShowFormFieldTooltip } from '@/components/FormFieldTooltip/FormFieldTooltip.hooks';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { Select } from '@/components/Select/Select';
import { useFormRegisterOptions, useRegistrationPhoneHandlers, useTogglePopupRegistrationBonus } from '@/hooks';

import type { RegistrationPhoneOption } from '@/types';

import { ButtonSignIn } from '../ButtonSignIn/ButtonSignIn';
import { ButtonSignUp } from '../ButtonSignUp/ButtonSignUp';
import { CheckboxSignUp } from '../CheckboxSignUp/CheckboxSignUp';
import { PromoCodeField } from '../PromoCodeField/PromoCodeField';

import { useRegistrationPhoneForm } from './FormRegistrationPhone.hooks';
import type { FormRegistrationPhoneProps, FormRegistrationPhoneValues } from './FormRegistrationPhone.types';

import styles from './FormRegistrationPhone.module.scss';

const BaseFormRegistrationPhone: FC<FormRegistrationPhoneProps> = ({
  className,
  variant = 'default',
  parentRef,
  testSchema,
  analytics,
}) => {
  const { t } = useTranslation('common');
  const phoneRef = useRef(null);

  const { codes, currencies, countries, userCountry, defaultCountry } = useFormRegisterOptions();

  const { formState, changeField, handleSubmit, isLoading, error } = useRegistrationPhoneForm({
    analytics: analytics?.submit,
  });

  const { handleSelectCode, handleSelectCurrency, handlePhoneChange, handleChange, handleSelectCountry } =
    useRegistrationPhoneHandlers<FormRegistrationPhoneValues>({
      changeField,
      countries,
      codes,
    });

  useTogglePopupRegistrationBonus({
    fieldsValues: [formState.values.phone.replace(/\D+/g, ''), formState.values.password, formState.values.promoCode],
  });

  useEffect(() => {
    if (codes.length) {
      handleSelectCode(codes[0]);
    }
  }, [codes, handleSelectCode]);

  useEffect(() => {
    if (countries.length) {
      const countryValue = countries.find((country) => country.value === userCountry) ?? defaultCountry;
      handleSelectCountry(countryValue);
    }
  }, [countries, defaultCountry, handleSelectCountry, userCountry]);

  useEffect(() => {
    if (formState.values.country && currencies?.length) {
      const currencyOption = currencies.find((value) => value.value === formState.values.country.currency);
      handleSelectCurrency(currencyOption ?? currencies[0], { onlySelf: true });
    }
  }, [formState.values.country, currencies, handleSelectCurrency]);

  const hasErrorPassword = Boolean(formState.isTouched && formState.errors?.password);
  const hasErrorPhone = Boolean(formState.isTouched && formState.errors?.phone);

  const { showedErrors, closeError, showError, hideError } = useShowFormFieldTooltip({
    isTouched: formState.isTouched,
    errors: formState.errors,
    errorFieldAfterTouched: 'phone',
  });

  const signUpAnalytics = extendAnalyticsData(analytics?.signUp, {
    with_promocode: !!formState.values.promoCode,
    promocode: formState.values.promoCode || undefined,
  });

  const handleSelectCurrencyWithAnalytics = useCallback(
    (option: SelectOption) => {
      const analyticsWithText = extendAnalyticsData(analytics?.currency, { text: option.label });
      sendAnalyticsData(analyticsWithText?.change?.eventName, analyticsWithText?.change?.data);

      handleSelectCurrency(option);
    },
    [analytics?.currency, handleSelectCurrency],
  );

  const handleSelectCountryCodeWithAnalytics = useCallback(
    (option: RegistrationPhoneOption) => {
      const analyticsWithText = extendAnalyticsData(analytics?.countryCode, { text: option.label });
      sendAnalyticsData(analyticsWithText?.change?.eventName, analyticsWithText?.change?.data);

      handleSelectCode(option);
    },
    [analytics?.countryCode, handleSelectCode],
  );

  const handleBlur = (fieldAnalytics?: Analytics) => () => {
    sendAnalyticsData(fieldAnalytics?.change?.eventName, fieldAnalytics?.change?.data);
  };

  return (
    <form
      className={cn(styles.root, className, styles[`${variant}Variant`])}
      onSubmit={handleSubmit}
      {...testSchema?.nodeProps}
    >
      {error && error.type !== '/phone-already-exists' && (
        <FormMessage className={styles.message}>
          {Array.isArray(error.message) ? t(error.message) : error.message}
        </FormMessage>
      )}
      {isLoading && <Loader />}
      <div className={cn(styles.formContainer)}>
        <FormFieldTooltip
          tooltipRef={phoneRef}
          errorText={t(formState.errors?.phone)}
          isError={hasErrorPhone}
          isShow={showedErrors?.phone === 'show'}
          onClose={closeError('phone')}
          className={styles.phoneField}
          onClickOutside={() => hideError('phone')}
        >
          <FormFieldPhone
            autoComplete="off"
            ref={phoneRef}
            label={t('auth.telephone', 'Telephone')}
            value={formState.values.phone}
            sizeVariant="32"
            options={codes}
            selected={formState.values.code}
            parentRef={parentRef}
            onSelect={handleSelectCountryCodeWithAnalytics}
            onChange={handlePhoneChange}
            onBlur={handleBlur(analytics?.phone)}
            errorText={t('auth.telephone', 'Telephone')}
            isError={hasErrorPhone}
            className={styles.phoneFieldInner}
            onFocus={() => showError('phone')}
            adaptive
            {...testSchema?.phoneInput.nodeProps}
          />
        </FormFieldTooltip>

        <FormFieldTooltip
          errorText={t(formState.errors?.password)}
          isError={hasErrorPassword}
          defaultText={
            !minLengthValidator(minPasswordLength)(formState.values.password)
              ? t('error.password.wrongFormat', 'The password must contain at least 8 characters')
              : undefined
          }
          className={styles.passwordField}
          isShow={showedErrors?.password === 'show'}
          onClose={closeError('password')}
          onClickOutside={() => hideError('password')}
        >
          <FormFieldHidden
            autoComplete="off"
            label={t('form.field.password', 'Password')}
            placeholder={t('form.field.password', 'Password')}
            value={formState.values.password}
            onChange={handleChange}
            onBlur={handleBlur(analytics?.password)}
            name="password"
            errorText={t('form.field.password', 'Password')}
            isError={hasErrorPassword}
            sizeVariant="32"
            onFocus={() => showError('password')}
            analytics={analytics?.password}
            {...testSchema?.passwordInput.nodeProps}
          />
        </FormFieldTooltip>
        <Select
          selected={formState.values.currency}
          options={currencies}
          onSelect={handleSelectCurrencyWithAnalytics}
          className={styles.currencyField}
          parentRef={parentRef}
          sizeVariant="32"
          {...testSchema?.countrySelect.nodeProps}
        />
        <PromoCodeField
          name="promoCode"
          value={formState.values.promoCode}
          onChange={handleChange}
          sizeVariant="32"
          className={styles.promoCodeField}
          analytics={analytics?.promoCode}
        />
        <CheckboxSignUp
          name="agree"
          checked={formState.values.agree}
          onChange={handleChange}
          className={styles.agreeField}
          variant={formState.isTouched && formState.errors?.agree ? 'red' : 'default'}
          analytics={analytics?.rules}
          {...testSchema?.agreementCheckbox.nodeProps}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonSignUp {...testSchema?.signUpButton.nodeProps} analytics={signUpAnalytics} />
        <ButtonSignIn {...testSchema?.signInButton.nodeProps} analytics={analytics?.signIn} />
      </div>
    </form>
  );
};

const FormRegistrationPhone = memo(BaseFormRegistrationPhone);

export * from './FormRegistrationPhone.types';
export { FormRegistrationPhone };
