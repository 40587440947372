import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useDeposit } from '@mwl/core-lib';

import { paymentMobileSize } from '@/constants';
import { useMediaWidthDown } from '@/hooks';

import { PaymentLayout } from '../../../../components/PaymentLayout/PaymentLayout';
import { usePayloadDeposit } from '../../hooks/usePayloadDeposit/usePayloadDeposit';
import { DepositForm } from '../DepositForm/DepositForm';
import { DepositFormQrCode } from '../DepositFormQrCode/DepositFormQrCode';
import { DepositFormText } from '../DepositFormText/DepositFormText';

export const DepositContent: FC = () => {
  const isMobileScreen = useMediaWidthDown(paymentMobileSize);

  const canBeSelectFirstPayment = useRef(true);

  const { methods, isLoading } = usePayloadDeposit();
  const { payment, changePayment, content, changeContent } = useDeposit();

  const resetContent = useCallback(() => {
    changeContent({ form: 'create' });
  }, [changeContent]);

  useEffect(() => {
    if (methods?.length && !payment && !isMobileScreen && canBeSelectFirstPayment.current) {
      canBeSelectFirstPayment.current = false;
      changePayment(methods[0]);
    }
  }, [methods, changePayment, payment, isMobileScreen]);

  return (
    <PaymentLayout payment={payment} isLoading={isLoading} methods={methods} onChangePayment={changePayment}>
      {payment && content.form === 'create' && <DepositForm />}
      {content.form === 'qrCode' && <DepositFormQrCode onClose={resetContent} />}
      {content.form === 'text' && payment?.method && <DepositFormText {...payment} />}
    </PaymentLayout>
  );
};
