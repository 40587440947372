import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import type { WithdrawalFormValues } from '@mwl/core-lib';
import { getWithdrawalFormSettings, useForm } from '@mwl/core-lib';

import ArrowIcon from '@public/assets/common/icons/arrowRightOutlined.svg';

import { Button } from '@/components/Button/Button';
import type { PaymentFieldValue } from '@/modules/payment/types';
import { popupsObject } from '@/utils';

import { PaymentFieldList } from '../../../../components/PaymentFieldList/PaymentFieldList';
import { PaymentFormFields } from '../../../../components/PaymentFormFields/PaymentFormFields';
import { PaymentFormTextFields } from '../../../../components/PaymentFormTextFields/PaymentFormTextFields';
import { usePaymentEditableFields } from '../../../../hooks/usePaymentEditableFields';
import { usePayloadWithdrawal } from '../../hooks/usePayloadWithdrawal/usePayloadWithdrawal';
import { useSubmitWithdrawalForm } from '../../hooks/useSubmitWithdrawalForm/useSubmitWithdrawalForm';
import { useUpdateWithdrawalForm } from '../../hooks/useUpdateWithdrawalForm';
import { useWithdrawalFormAmount } from '../../hooks/useWithdrawalFormAmount';
import { useWithdrawal } from '../../Withdrawal.context';
import { EmptyProfileForm } from '../EmptyProfileForm/EmptyProfileForm';

import styles from './WithdrawalForm.module.scss';

export const WithdrawalForm: FC = () => {
  const { t } = useTranslation('payment');

  const { payment, changePayment } = useWithdrawal();

  const { prohibitionReasons } = usePayloadWithdrawal();

  const { changeField, formState, handleSubmit, resetForm } = useForm<WithdrawalFormValues>(
    getWithdrawalFormSettings(payment?.method.fieldList ?? [], t),
  );

  const { onSubmitConfirm } = useSubmitWithdrawalForm({ formState });

  const { editableFields, staticFields } = usePaymentEditableFields(payment?.method);

  const availableAmount = useWithdrawalFormAmount(payment?.method.fieldList ?? []);

  useUpdateWithdrawalForm(resetForm);

  const handleClickBack = useCallback(() => {
    changePayment();
  }, [changePayment]);

  const handleChange = useCallback(
    (name: keyof WithdrawalFormValues) => (value: PaymentFieldValue) => changeField(name, value),
    [changeField],
  );

  if (!payment) {
    return null;
  }

  if (prohibitionReasons.includes('INCOMPLETE_PROFILE')) {
    return <EmptyProfileForm />;
  }

  const footer = (
    <div className={styles.staticData}>
      {payment.method.commission?.percent && (
        <div>
          {t('commission', 'Commission')}: <span className={styles.value}>{payment.method.commission?.percent}%</span>
        </div>
      )}
    </div>
  );

  return (
    <div {...popupsObject.deposit.payment.formWrapper(payment.method.identity).nodeProps} className={styles.root}>
      <Button
        className={styles.backButton}
        as="button"
        variant="text"
        onClick={handleClickBack}
        align="left"
        fontWeight="bold"
        color="white"
      >
        <ArrowIcon className={styles.backIcon} />
        {t('back.button_refill', 'Back')}
      </Button>

      <div className={styles.headForm}>
        {t('back.button_withdrawal', 'Withdrawal')}&nbsp;{t(payment?.method.title ?? '')}
      </div>

      <form onSubmit={handleSubmit(onSubmitConfirm)}>
        <PaymentFormTextFields formState={formState} fields={staticFields} />

        <PaymentFormFields method={payment.method} className={styles.withHead} footer={footer} variant="payout">
          <PaymentFieldList
            className={styles.field}
            quickTipsHide
            groups={payment.method.groupList}
            fields={editableFields}
            formState={formState}
            onChange={handleChange}
          />
          {payment.method.type !== 'pop-up' && (
            <div className={cn(styles.staticData, styles.above)}>
              {t('available_for_withdrawal', 'Available for withdrawal')}:{' '}
              <span className={styles.value}>{availableAmount}</span>
            </div>
          )}
        </PaymentFormFields>
      </form>
    </div>
  );
};
