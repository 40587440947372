import type { FC } from 'react';
import { useQueryPopupOpen } from '@mwl/core-lib';

import { Tab } from '@/components/Tab/Tab';

import type { PaymentTabProps } from './PaymentTab.types';

export const PaymentTab: FC<PaymentTabProps> = ({ className, label, value, ...props }) => {
  const { createLinkProps } = useQueryPopupOpen();

  const linkProps = createLinkProps({ queryName: value });

  return <Tab as="link" label={label} className={className} {...props} {...linkProps} />;
};
