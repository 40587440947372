import type { FC } from 'react';
import { Trans, useTranslation } from 'next-i18next';

import UserIcon from '@public/assets/common/icons/user.svg';

import { Button } from '@/components/Button/Button';
import { ModalMessage } from '@/components/ModalMessage/ModalMessage';
import type { PopupClasses } from '@/components/Popup/Popup.types';

import type { UserIsFrozenPopupProps } from './UserIsFrozenPopup.types';

import styles from './UserIsFrozenPopup.module.scss';

const popupClasses: PopupClasses = {
  wrapper: styles.wrapper,
};

export const UserIsFrozenPopup: FC<UserIsFrozenPopupProps> = ({ show, onClose }) => {
  const { t } = useTranslation('payment');

  return (
    <ModalMessage
      popupClasses={popupClasses}
      title={t('user_frozen.title', 'Account frozen')}
      icon={<UserIcon className={styles.icon} />}
      status="error"
      show={show}
      onClose={onClose}
      customAction={
        <>
          <p className={cn(styles.text, styles.subtitle)}>
            {t('user_frozen.subtitle', 'For security reasons, we have temporarily suspended your account.')}
          </p>
          <p className={cn(styles.text, styles.description)}>
            {t(
              'user_frozen.description',
              'Please contact the support service in a convenient way for you - we will definitely help you!',
            )}
          </p>
          <p className={cn(styles.text, styles.emailWrapper)}>
            <Trans i18nKey="user_frozen.email" components={[<span className={styles.email} key="user_frozen.email" />]}>
              {`Email: <0>support@banzai.bet</0>`}
            </Trans>
          </p>
          <Button
            as="button"
            onClick={onClose}
            fontSize="lg"
            fontWeight="bold"
            size="md"
            fullWidth
            align="center"
            color="red"
            rounded
          >
            {t('user_frozen.button', 'Ok')}
          </Button>
        </>
      }
    />
  );
};
