import type { GameBadge, LoyaltyTournament } from '@mwl/core-lib';

import type { TournamentCardProps } from '@/components/TournamentCard/TournamentCard.types';

/**
 * Функция преобразует тип из LoyaltyTournament в TournamentCardProps
 */
export const formatTournamentToTournamentCard = (tournament: LoyaltyTournament): TournamentCardProps => {
  return {
    ...tournament,
    badge: tournament.badge as GameBadge,
    prizeFundCount: null,
    title: tournament.tournamentname,
    mediaBannerImage: tournament.fullMediaBannerImage,
    startAt: tournament.startedAt,
    endAt: tournament.finishedAt,
    name: tournament.tournamentname,
    prizeFund: tournament.tournamentprizefund ?? tournament.prizeFundSummary,
    place: tournament.userPlace,
    score: tournament.userScore,
  };
};
