import { nanoid } from '@reduxjs/toolkit';

import GoogleIcon from '@public/assets/social/icons/google.svg';
import TelegramIcon from '@public/assets/social/icons/telegram.svg';

import type { SocialItem } from './SocialList.types';

export const socialList: Array<SocialItem> = [
  { id: nanoid(), name: 'telegram', href: '/api/v1/auth/telegram/login', Icon: TelegramIcon },
  { id: nanoid(), name: 'google', href: '/api/v1/auth/google/login', Icon: GoogleIcon },
];
