import { forwardRef } from 'react';
import { getTestProps, handleEventWithAnalytics, omitTestProps } from '@mwl/core-lib';
import { CheckboxBase } from '@mwl/ui';

import CheckIcon from '@public/assets/common/icons/check.svg';

import type { CheckboxProps } from './Checkbox.types';

import styles from './Checkbox.module.scss';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, children, classes, variant, checked, onChange, analytics, ...props }, ref) => {
    const analyticsEvent = checked ? analytics?.uncheck : analytics?.check;
    const analyticsClassName: string | undefined = analyticsEvent?.data?.cls;

    return (
      <CheckboxBase
        ref={ref}
        wrapperProps={getTestProps(props)}
        className={cn(styles.root, className, styles[variant])}
        checked={checked}
        onChange={handleEventWithAnalytics(onChange, analyticsEvent?.eventName, analyticsEvent?.data)}
        classes={{
          input: cn(analyticsClassName, styles.input),
          checked: styles.checked,
          box: cn(styles.box, classes?.box),
        }}
        icon={<CheckIcon className={styles.icon} />}
        {...omitTestProps(props)}
      >
        {!!children && <div className={cn(styles.text, classes?.text)}>{children}</div>}
      </CheckboxBase>
    );
  },
);

export * from './Checkbox.types';
export { Checkbox };
