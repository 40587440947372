import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';

import type { ButtonSignUpProps } from './ButtonSignUp.types';

import styles from './ButtonSignUp.module.scss';

const BaseButtonSignUp: FC<ButtonSignUpProps> = ({ className, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <Button
      as="button"
      variant="fulfilled"
      color="red"
      fontSize="lg"
      fontWeight="bold"
      size="lg"
      fullWidth
      className={cn(className, styles.root)}
      type="submit"
      rounded
      {...props}
    >
      {t('button.signup', 'Sign Up')}
    </Button>
  );
};

const ButtonSignUp = memo(BaseButtonSignUp);

export * from './ButtonSignUp.types';
export { ButtonSignUp };
