import type { FC } from 'react';
import { memo } from 'react';
import { extendAnalyticsData } from '@mwl/core-lib';
import { NoSSR } from '@mwl/ui';

import { ButtonIcon } from '../ButtonIcon/ButtonIcon';

import { socialList } from './SocialList.data';
import type { SocialListProps } from './SocialList.types';

import styles from './SocialList.module.scss';

const BaseSocialList: FC<SocialListProps> = ({ className, title, classes, query, disabled, analytics, ...props }) => {
  return (
    <div className={cn(styles.root, className)} {...props}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.list}>
        <NoSSR>
          {socialList.map(({ id, Icon, href, name }) => (
            <ButtonIcon
              as="link"
              href={{ pathname: href, query }}
              key={id}
              variant="text"
              color="red"
              className={cn(styles.item, classes?.item)}
              disabled={disabled}
              locale={false}
              aria-label={`social link ${name}`}
              analytics={extendAnalyticsData(analytics, { href: { pathname: href, query }, social_name: name })}
            >
              <Icon className={styles.icon} />
            </ButtonIcon>
          ))}
        </NoSSR>
      </div>
    </div>
  );
};

const SocialList = memo(BaseSocialList);

export * from './SocialList.types';
export { SocialList };
