import { createWrapper } from 'next-redux-wrapper';
import { createMakeStore, isDev } from '@mwl/core-lib';

import { base } from '@/constants';

import { rootReducer } from './features/rootReducer';
import { rootSaga } from './features/rootSaga';

const makeStore = createMakeStore({
  isDev,
  projectName: base.NAME_PROJECT,
  reducer: rootReducer,
  saga: rootSaga,
});

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = ReturnType<typeof makeStore>['dispatch'];

export const wrapperStore = createWrapper<AppStore>(makeStore);

export type { RootState } from './features/rootReducer';
