import type { FC } from 'react';

import LoaderIcon from '@public/assets/common/icons/loader.svg';

import type { LoaderProps } from './Loader.types';

import styles from './Loader.module.scss';

const Loader: FC<LoaderProps> = ({ className }) => {
  return (
    <div className={cn(styles.root, className)}>
      <LoaderIcon className={styles.svg} />
    </div>
  );
};

export * from './Loader.types';
export { Loader };
