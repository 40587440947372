import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { selectUserIsAuthenticated, useQueryPopupCheckAll } from '@mwl/core-lib';

import { useIsGamePage, useMediaWidthDown, useTypedSelector } from '@/hooks';
import { isWebViewUserAgent } from '@/utils';

import styles from './AppsFlyerBanner.module.scss';

const bannerId = 'AFbanner';

export const AppsFlyerBanner = () => {
  const router = useRouter();

  const bannerContainer = useRef<HTMLDivElement>(null);
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const isAnyPopupOpen = useQueryPopupCheckAll();
  const isGamePage = useIsGamePage();
  const isWebViewAgent = isWebViewUserAgent();
  const isXsScreen = useMediaWidthDown('screenXs');

  const isQueryWebView = router.query.inWebView === '1';

  const shouldInjectScript =
    isAuth && isXsScreen && !isWebViewAgent && !isQueryWebView && !isAnyPopupOpen && !isGamePage;

  useEffect(() => {
    if (!window.AF) {
      return;
    }

    const sidebarZIndex = getComputedStyle(document.documentElement).getPropertyValue('--sidebar-z-index');

    if (shouldInjectScript) {
      window.AF('banners', 'showBanner', {
        bannerContainerQuery: `#${bannerId}`,
        // баннер должен быть под сайдбаром
        bannerZIndex: sidebarZIndex ? Number(sidebarZIndex) : undefined,
      });
    } else {
      window.AF('banners', 'hideBanner');
    }
    // router.pathname - при переходах между страницами пытаемся показать баннер, так как он мог быть скрыт ранее и теперь доступен
  }, [shouldInjectScript, router.pathname]);

  useEffect(() => {
    const containerElement = bannerContainer.current;

    const resizeObserver = new ResizeObserver(([{ target }]) => {
      const element = target as HTMLElement;

      if (containerElement) {
        containerElement.style.height = `${element.offsetHeight}px`;
      }
    });

    const observeBannerResize = (element: HTMLElement) => {
      resizeObserver.disconnect();
      resizeObserver.observe(element);
    };

    const containerObserver = new MutationObserver(([{ target }]) => {
      const element = target as HTMLElement;

      if (element.firstChild) {
        observeBannerResize(element.firstChild as HTMLElement);
      } else if (containerElement) {
        containerElement.style.height = '';
      }
    });

    if (containerElement) {
      containerObserver.observe(containerElement, { childList: true });
    }

    return () => {
      containerObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, []);

  return <div id={bannerId} className={styles.root} ref={bannerContainer} />;
};
