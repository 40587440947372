import type { RefObject } from 'react';
import { useMemo } from 'react';
import { calcMaxHeight } from '@mwl/core-lib';

import { useMediaWidthDown } from './useMediaWidthDown';

const initialMaxDesktopHeight = 500;
const initialMaxMobileHeight = 350;

/**
 * Возвращает максимальную высоту элемента в зависимости от размера экрана.
 * @param parentRef - Ссылка на родительский элемент.
 * @param maxDesktopHeight - Максимальная высота для настольных устройств.
 * @param maxMobileHeight - Максимальная высота для мобильных устройств.
 * @returns Максимальная высота элемента.
 */
export const useMaxHeight = (
  parentRef?: RefObject<HTMLElement>,
  maxDesktopHeight = initialMaxDesktopHeight,
  maxMobileHeight = initialMaxMobileHeight,
) => {
  const isSmallScreen = useMediaWidthDown('screenXs');

  const maxHeight = useMemo(
    () => calcMaxHeight(isSmallScreen, maxDesktopHeight, maxMobileHeight, parentRef),
    [isSmallScreen, maxDesktopHeight, maxMobileHeight, parentRef],
  );

  return maxHeight;
};
