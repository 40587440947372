import type { FC } from 'react';
import { TabBase } from '@mwl/ui';

import { Button } from '../Button/Button';

import type { TabProps } from './Tab.types';

import styles from './Tab.module.scss';

const Tab: FC<TabProps> = ({ label, className, active, ...otherProps }) => {
  return (
    <TabBase
      component={Button}
      className={cn(styles.tab, className)}
      classes={{ active: styles.active }}
      variant={active ? 'lined' : 'text'}
      color={active ? 'red' : 'grey-light'}
      active={active}
      {...otherProps}
    >
      {label}
    </TabBase>
  );
};

export { Tab };
