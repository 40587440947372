import type { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';

import { Timer } from '../Timer/Timer';

import type { SendCodeAgainButtonProps } from './SendCodeAgainButton.types';

import styles from './SendCodeAgainButton.module.scss';

export const SendCodeAgainButton: FC<SendCodeAgainButtonProps> = ({ className, onClick, timer, onComplete }) => {
  const { t } = useTranslation('payment');

  const hasTimer = !!timer;

  if (hasTimer) {
    return (
      <span className={styles.timer}>
        {`${t('need_confirmation.send_code_again', 'Send code again')} `}
        <Timer date={timer} onComplete={onComplete} />
      </span>
    );
  }

  return (
    <Button
      as="button"
      variant="fulfilled"
      color="red"
      size="md"
      fontSize="xl"
      fullWidth
      className={cn(styles.root, className)}
      type="button"
      onClick={onClick}
      disabled={hasTimer}
      rounded
      uppercase={!hasTimer}
    >
      {t('need_confirmation.send_code_again', 'Send code again')}
    </Button>
  );
};
