import type { FC } from 'react';

import { WithdrawalContent } from './components/WithdrawalContent/WithdrawalContent';
import { WithdrawalProvider } from './Withdrawal.context';

export const Withdrawal: FC = () => {
  return (
    <WithdrawalProvider>
      <WithdrawalContent />
    </WithdrawalProvider>
  );
};
