import { byKey, complex, simple } from '@mwl/core-lib';

const downloadSchema = complex({
  icon: simple,
  button: simple,
});

export const footerSchema = complex({
  links: byKey(simple),
  downloads: byKey(downloadSchema),
  payment: byKey(simple),
  disclaimer: simple,
  copyright: simple,
});
