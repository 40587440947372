import type { FormSettings } from '@mwl/core-lib';
import { minLengthValidator, minPasswordLength, requiredStringValidator } from '@mwl/core-lib';

import type { FormRecoveryPasswordValues } from './FormRecoveryPassword.types';

export const formSettings: FormSettings<FormRecoveryPasswordValues> = {
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error.newPassword.missingParam' },
      minLength: { validate: minLengthValidator(minPasswordLength), message: 'error.newPassword.wrongFormat' },
    },
    value: '',
    relatedFields: ['confirm'],
  },
  confirm: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error.confirmNewPassword.missingParam' },
      compare: { validate: (value, state) => state?.password === value, message: 'error.passwordsNotEqual' },
    },
    value: '',
  },
};
