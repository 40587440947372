import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  loadProfileByGEORequest,
  selectBonusListAmountByApplicationType,
  selectInitialProfileByGEOIsLoaded,
  selectUserBalanceAmount,
  selectUserIsAuthenticated,
  selectUserLocale,
  selectUserProfile,
} from '@mwl/core-lib';

import { useTypedDispatch } from './useTypedDispatch';
import { useTypedSelector } from './useTypedSelector';

/**
 * Хук для инициализации приложения.
 * Он отвечает за загрузку профиля пользователя и установку параметров сайта.
 */
export const useInitializeApp = () => {
  const dispatch = useTypedDispatch();
  const router = useRouter();

  const isLoaded = useTypedSelector(selectInitialProfileByGEOIsLoaded);
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const { currency, id } = useTypedSelector(selectUserProfile);
  const locale = useTypedSelector(selectUserLocale);
  const balance = useTypedSelector(selectUserBalanceAmount);
  const casinoBonusAmount = useTypedSelector(selectBonusListAmountByApplicationType('CASINO'));

  const is404Page = router.route === '/404';

  const { inWebView } = router.query;

  useEffect(() => {
    const userParams = {
      user_id: id || null,
      balance_real: balance,
      balance_bonus: casinoBonusAmount,
    };

    // eslint-disable-next-line no-underscore-dangle
    window._site_params = {
      build_version: process.env.PACKAGE_VERSION ?? null,
      locale,
      currency,
      isAuth: isAuth ? 1 : 0,
      isWebView: inWebView ? 1 : 0,
      ...(isAuth ? userParams : {}),
    };
  }, [isAuth, currency, id, locale, balance, inWebView, casinoBonusAmount]);

  useEffect(() => {
    if (!isLoaded && !is404Page) {
      dispatch(loadProfileByGEORequest());
    }
  }, [dispatch, isLoaded, is404Page]);
};
