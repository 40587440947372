import type { FC } from 'react';
import { ButtonIconBase } from '@mwl/ui';

import { Button } from '../Button/Button';

import type { ButtonIconProps } from './ButtonIcon.types';

import styles from './ButtonIcon.module.scss';

const ButtonIcon: FC<ButtonIconProps> = ({ cover, rounded, size = 'auto', variant = 'text', className, ...props }) => {
  return (
    <ButtonIconBase
      className={cn(styles.button, styles[`${size}Size`], className, {
        [styles.rounded]: rounded,
        [styles.cover]: cover,
      })}
      component={Button}
      {...props}
      variant={variant}
    />
  );
};

export * from './ButtonIcon.types';
export { ButtonIcon };
