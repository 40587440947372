import type { FC } from 'react';
import { checkIsExternalLink, formatImageUrl } from '@mwl/core-lib';
import type { ImageProps as ImageBaseProps } from '@mwl/ui';
import { Image as ImageBase, NoSSR } from '@mwl/ui';

export interface ImageProps extends Omit<ImageBaseProps, 'alt'>, Required<Pick<ImageBaseProps, 'alt'>> {}

export const Image: FC<ImageProps> = ({ width, height, quality = 75, src, ...props }) => {
  const isSrcString = typeof src === 'string';

  const fullProps: ImageProps = {
    ...props,
    src: isSrcString ? formatImageUrl(src, width, quality, { enableDPIScaling: true }) : src,
    ...(!props.fill ? { width, height } : {}),
  };

  const content = <ImageBase {...fullProps} />;

  if (!src) {
    return null;
  }

  if (isSrcString && checkIsExternalLink(src)) {
    return <NoSSR>{content}</NoSSR>;
  }

  return content;
};
