import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useMemo } from 'react';
import { getDataCyProps, omitTestProps } from '@mwl/core-lib';

import ArrowIcon from '@public/assets/common/icons/arrow.svg';

import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import type { FormFieldAsInput, FormFieldAsNumberFormat } from '@/components/FormField/FormField';
import { FormField } from '@/components/FormField/FormField';

import type { SelectHeaderProps } from './SelectHeader.types';

import styles from './SelectHeader.module.scss';

const BaseSelectHeader: FC<SelectHeaderProps> = ({
  className,
  prefix,
  disabled,
  isOpen,
  search,
  numberFormat,
  placeholder,
  onChange,
  onToggle,
  label,
  sizeVariant,
  classes,
  ...props
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => onChange(event.target.value),
    [onChange],
  );

  const inputProps = useMemo(() => {
    if (numberFormat) {
      return {
        as: 'numberFormat',
        ...numberFormat,
        numberFormatPrefix: numberFormat?.prefix,
      } as FormFieldAsNumberFormat;
    }

    return { as: 'input' } as FormFieldAsInput;
  }, [numberFormat]);

  return (
    <div className={cn(styles.root, className, { [styles.opened]: isOpen })} {...getDataCyProps(props)}>
      <FormField
        {...inputProps}
        classes={{ component: cn(classes?.input, styles.input) }}
        prefix={prefix}
        value={search}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        label={label}
        sizeVariant={sizeVariant}
        {...omitTestProps(props)}
      />
      <ButtonIcon
        as="button"
        onClick={onToggle}
        className={cn(classes?.button, styles.button)}
        type="button"
        variant="text"
        aria-label="select toggle"
      >
        <ArrowIcon className={styles.icon} />
      </ButtonIcon>
    </div>
  );
};

const SelectHeader = memo(BaseSelectHeader);

export * from './SelectHeader.types';
export { SelectHeader };
