import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { getCookie, makeRequest, selectUserIsAuthenticated, useQueryPopup } from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks';

import { Popup } from '../Popup/Popup';

import type { GetPaymentWidgetUrlResponse } from './PaymentWidget.types';

import styles from './PaymentWidget.module.scss';

const pwQueryParam = 'payment-widget';
const pwQueryValue = '1';
const pwCookie = 'pw_enabled';
// const src = 'https://dev.paybetget.cash?payment_token=a3c1caf8-1c56-4f7d-964a-e1ec1a56fa74';

const PaymentWidget: FC = () => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);

  const { show, value, onClose } = useQueryPopup(pwQueryParam);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const getSessionToken = async () => {
      const { data } = await makeRequest<GetPaymentWidgetUrlResponse>({
        url: '/api/user/v3/payment/refill/widget/url',
        method: 'get',
      });

      return data;
    };

    if (isAuth) {
      getSessionToken().then((data) => {
        setUrl(data?.url);
      });
    }
  }, [isAuth]);

  const hasRouteToShow = show && value === pwQueryValue;
  const hasAccessCookie = Boolean(getCookie(pwCookie));

  if (!hasRouteToShow || !hasAccessCookie) {
    return null;
  }

  return (
    <Popup show onClose={() => onClose()} classes={{ wrapper: styles.root }}>
      <iframe title="Payment Widget" src={url} height="100%" width="100%" />
    </Popup>
  );
};

export { PaymentWidget };
