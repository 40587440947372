interface AuthenticatedOnlyProps {
  authenticated?: boolean;
}

interface AuthenticatedOnlyOptions {
  isAuth: boolean;
}

/**
 * Функция для ограничения пользователя в разделе только для авторизованных пользователей.
 */
export const createAuthenticatedOnly =
  ({ isAuth }: AuthenticatedOnlyOptions) =>
  ({ authenticated }: AuthenticatedOnlyProps) =>
    authenticated ? isAuth : true;
