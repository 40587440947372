import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';

import type { ButtonRegistrationProps } from './ButtonRegistration.types';

const BaseButtonRegistration: FC<ButtonRegistrationProps> = ({ className, onClick }) => {
  const { t } = useTranslation('common');

  return (
    <Button
      as="button"
      variant="fulfilled"
      color="yellow"
      fontSize="xl"
      fontWeight="bold"
      size="md"
      fullWidth
      className={className}
      type="button"
      onClick={onClick}
    >
      {t('button.registration', 'Registration')}
    </Button>
  );
};

const ButtonRegistration = memo(BaseButtonRegistration);

export * from './ButtonRegistration.types';
export { ButtonRegistration };
