import { AVIATOR_GAME_ID } from '../aviator';
import { routes } from '../routes';

import type { NavMenuItem } from './navMenu.types';

export const aviatorNavMenuItem: NavMenuItem = {
  key: 'header.navigation.aviator',
  text: 'aviator',
  href: {
    pathname: routes.casino.game,
    query: { gameId: AVIATOR_GAME_ID },
  },
  isAviator: true,
};

export const buyBonusNavMenuItem: NavMenuItem = {
  key: 'header.navigation.buy_bonus',
  text: 'Buy bonus',
  href: {
    pathname: routes.casino.category,
    query: { category: 'bonus_buy' },
  },
  isBuyBonus: true,
};

export const desktopNavMenu: Array<NavMenuItem> = [
  // TODO Временно скрываем в рамках MWL-6896
  // {
  //   key: 'header.navigation.sport',
  //   text: 'sport',
  //   href: routes.sport.home,
  // },
  {
    key: 'header.navigation.main',
    text: 'Main',
    href: routes.home,
    exactMatch: true,
  },
  {
    key: 'header.navigation.casino_home',
    text: 'casino',
    href: routes.casino.home,
  },
  {
    key: 'header.navigation.live_casino.home',
    text: 'live casino',
    href: routes.liveCasino.home,
  },
  // TODO Временно скрываем в рамках MWL-6896
  // {
  //   key: 'header.navigation.poker',
  //   text: 'poker',
  //   href: routes.poker.home,
  // },
  {
    key: 'header.navigation.tournaments',
    text: 'tournaments',
    href: routes.tournaments.home,
  },
];
