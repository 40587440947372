import DepositIcon from '@public/assets/mobileMenu/icons/deposit.svg';
import LiveCasinoIcon from '@public/assets/mobileMenu/icons/liveCasino.svg';
import MainIcon from '@public/assets/mobileMenu/icons/main.svg';
import PromoIcon from '@public/assets/mobileMenu/icons/promo.svg';
import CasinoIcon from '@public/assets/mobileMenu/icons/сasino.svg';

import { routes } from '../routes';

import type { NavMenuItem } from './navMenu.types';

export const mobileNavMenuAuthorizedItems: Array<NavMenuItem> = [
  { key: 'header.navigation.main', text: 'main', href: routes.home, Icon: MainIcon },
  {
    key: 'header.navigation.casino_home',
    text: 'casino',
    href: routes.casino.home,
    Icon: CasinoIcon,
  },
  {
    key: 'header.navigation.casino.live',
    text: 'live casino',
    href: routes.liveCasino.home,
    Icon: LiveCasinoIcon,
  },
  { key: 'header.navigation.promo.home', text: 'promo', href: routes.promo.home, Icon: PromoIcon },
  { key: 'header.navigation.deposit', text: 'deposit', href: { query: 'deposit=1' }, Icon: DepositIcon },
];
