import { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { PaymentMethodRestrictions, UseFormReturn, WithdrawalFormValues } from '@mwl/core-lib';
import { calcAvailableWithdrawal, getWithdrawalFormSettings, selectUserBalanceAmount } from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks';

import { useWithdrawal } from '../Withdrawal.context';

export const useUpdateWithdrawalForm = (resetForm: UseFormReturn<WithdrawalFormValues>['resetForm']) => {
  const { t } = useTranslation('payment');

  const userBalance = useTypedSelector(selectUserBalanceAmount);

  const { payment } = useWithdrawal();
  const { identity, fieldList } = payment?.method ?? {};

  const prevIdentifyRef = useRef(identity);
  const prevUserBalance = useRef<number>();

  useEffect(() => {
    const amountField = fieldList?.find((field) => field.name === 'amount');

    if (amountField && (prevIdentifyRef?.current !== identity || prevUserBalance.current !== userBalance)) {
      const restrictions: Array<PaymentMethodRestrictions> = [...(amountField.restrictions || [])];
      const availableWithdrawal = calcAvailableWithdrawal({ fields: fieldList, userBalance });

      restrictions.push({ name: 'maxBalanceSum', value: String(availableWithdrawal) });

      const filteredFields = fieldList?.filter((field) => field.name !== 'amount') ?? [];

      resetForm(getWithdrawalFormSettings([...filteredFields, { ...amountField, restrictions }], t));
    }

    prevUserBalance.current = userBalance;
    prevIdentifyRef.current = identity;
  }, [userBalance, identity, resetForm, t, fieldList]);
};
