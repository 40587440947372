/**
 * Преобразует значение в формате с заменой X на #
 */
export const getNumberFieldFormat = (value: string | undefined, replacement = '#'): string | undefined => {
  if (!value) {
    return undefined;
  }

  return value.replace(/X/gi, replacement);
};
