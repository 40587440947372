import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { calcTimerEndDate } from '@mwl/core-lib';

import type { RecoveryContextOnChangeState, RecoveryState, RecoveryStateContextProps } from './FormRecovery.types';

export const RecoveryStateContext = createContext<RecoveryStateContextProps>({} as RecoveryStateContextProps);

export const useRecoveryState = (): RecoveryStateContextProps => useContext(RecoveryStateContext);

const initialState: RecoveryState = {
  codeId: '',
  login: '',
  timer: null,
  isCodeError: false,
};

export const useProvideRecoveryState = (): RecoveryStateContextProps => {
  const [state, setState] = useState<RecoveryState>(initialState);

  const onChangeState: RecoveryContextOnChangeState = useCallback(({ timer, ...currentState }) => {
    setState((prev) => ({
      ...prev,
      ...currentState,
      timer: timer === undefined ? prev.timer : calcTimerEndDate(timer),
    }));
  }, []);

  const result: RecoveryStateContextProps = useMemo(() => ({ ...state, onChangeState }), [state, onChangeState]);

  return result;
};
