import type { FC } from 'react';
import Head from 'next/head';
import type { MetaData } from '@mwl/core-lib';
import { formatImageUrl, useLogos } from '@mwl/core-lib';

export interface MetaProps extends MetaData {}

const Favicon: FC = () => {
  const { app, favicon, icon16, icon32 } = useLogos();

  return (
    <Head>
      <link rel="apple-touch-icon" sizes="180x180" href={formatImageUrl(app, 180)} />
      <link rel="icon" type="image/x-icon" href={formatImageUrl(favicon, 16)} />
      <link rel="icon" type="image/png" sizes="32x32" href={formatImageUrl(icon32, 32)} />
      <link rel="icon" type="image/png" sizes="16x16" href={formatImageUrl(icon16, 16)} />
      <link rel="manifest" href="/static/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/static/favicon/safari-pinned-tab.svg" color="#000" />
      <meta name="msapplication-TileColor" content="#000" />
      <meta name="msapplication-config" content="/static/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#000" />
    </Head>
  );
};

export { Favicon };
