import type { RefObject } from 'react';
import { useMemo } from 'react';
import type { TippyProps } from '@tippyjs/react';

import { useMediaWidthDown } from '@/hooks';

import type { BonusPacketCardProps } from './BonusPacketCard.types';

export const useBonusPacketTippyProps = (
  containerRef: BonusPacketCardProps['containerRef'],
  root: RefObject<HTMLDivElement>,
) => {
  const isSmallScreen = useMediaWidthDown('screenXs');

  const getReferenceClientRect = useMemo(() => {
    if (!isSmallScreen || !containerRef?.current) {
      return () => (root.current as Element)?.getBoundingClientRect();
    }

    return () => (containerRef.current as Element)?.getBoundingClientRect();
  }, [isSmallScreen, containerRef, root]);

  return {
    getReferenceClientRect,
  };
};

export const useTippyPlacement = (index: number): TippyProps['placement'] => {
  const isXsScreen = useMediaWidthDown('screenXs');
  const isXlScreen = useMediaWidthDown('screenXl');
  const isScreenMd = useMediaWidthDown('screenMd');
  const isScreen1274 = useMediaWidthDown('screen1274');
  let lastRowIndex = 6;

  if (isXlScreen) {
    lastRowIndex = 5;
  }

  if (isScreenMd) {
    lastRowIndex = 4;
  }

  if (isScreen1274) {
    lastRowIndex = 3;
  }

  if (isXsScreen) {
    lastRowIndex = 2;
  }

  const lastRowPlacement = isXsScreen ? 'auto' : 'right-start';

  return (index + 1) % lastRowIndex === 0 ? lastRowPlacement : 'bottom-start';
};
