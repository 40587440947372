import type { FullRegistrationStepDictionary, RegistrationBonusesContent } from '@mwl/core-lib';

export const fullRegistrationSteps: FullRegistrationStepDictionary = {
  first: 1,
  second: 2,
  third: 3,
};

export const registrationBonuses: RegistrationBonusesContent = {
  CASINO: {
    title: ['registration.bonus.casino.title', 'Casino Bonus'],
    popupTitle: ['registration.bonuses.popup.casino.title', 'casino bonus'],
    description: [
      'auth_banner.bonus.casino.description',
      'While choosing this type of bonus you agree to get 100% deposit bonus, the bonus will be wagered on sportsbook only',
    ],
    image: '/assets/bonus/images/casinoBonus.png',
  },
  SPORT: {
    // Coming soon bonus
    // title: ['registration.bonus.sport.title', 'Sport Bonus'],
    // image: '/assets/bonus/images/freeSpinsBonus.png',
    comingSoonPrize: ['registration.bonus.sport.coming_soon_title', 'Coming soon'],
    title: ['registration.bonus.sport.coming_soon_title', 'Coming soon'],
    image: '/assets/bonus/images/freeSpinsBonusSoon.png',
    popupTitle: ['registration.bonuses.popup.sport.title', 'sport bonus'],
    description: [
      'auth_banner.bonus.sport.description',
      'In Love with Cashbacks? BANZAI can get you 10% Daily Cashback up to 100 dollars or the equivalent in other currency.',
    ],
  },
  REFUSAL: {
    title: ['registration.bonus.empty.title', 'No bonus'],
    image: '/assets/bonus/images/noBonus.png',
    popupTitle: ['registration.bonuses.popup.empty.title', 'Registration without bonuses'],
    description: ['auth_banner.bonus.empty.description', 'You can start playing and betting without bonuses'],
  },
};
