import type { FC } from 'react';
import { useCallback } from 'react';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';
import { useTranslation } from 'next-i18next';
import { getFieldDateFormat, getFieldRestrictionValue } from '@mwl/core-lib';

import { DatepickerDynamic } from '@/components/DatepickerDynamic/DatepickerDynamic';

import type { PaymentDateFieldProps } from './PaymentDateField.types';

import styles from './PaymentDateField.module.scss';

export const PaymentDateField: FC<PaymentDateFieldProps> = ({
  className,
  value,
  title,
  onChange,
  attr,
  restrictions,
}) => {
  const { t } = useTranslation('payment');

  const dateFormat = getFieldDateFormat(attr);
  const minDate = getFieldRestrictionValue('min', restrictions);
  const maxDate = getFieldRestrictionValue('max', restrictions);
  const mask = dateFormat === 'yyyy-MM-dd' ? 'yYYY-mM-dD' : undefined;

  const selected = value ? parse(value, dateFormat, new Date()).getTime() : 0;

  const handleChangeDate = useCallback(
    (time: number | string) => {
      onChange(format(new Date(time), dateFormat));
    },
    [dateFormat, onChange],
  );

  return (
    <DatepickerDynamic
      className={cn(styles.root, className)}
      selected={selected}
      onChange={handleChangeDate}
      placeholder={title ? t(title) : ''}
      dateFormat={dateFormat}
      classes={{ input: styles.input }}
      minDate={minDate ? parse(minDate, dateFormat, new Date()) : undefined}
      maxDate={maxDate ? parse(maxDate, dateFormat, new Date()) : undefined}
      mask={mask}
    />
  );
};
