import { useCallback, useEffect } from 'react';
import type { Banner, BannerPosition } from '@mwl/core-lib';
import {
  loadBannersRequest,
  selectBannersByPosition,
  selectUserCurrency,
  selectUserIsAuthenticated,
} from '@mwl/core-lib';

import { useTypedDispatch } from './useTypedDispatch';
import { useTypedSelector } from './useTypedSelector';

export interface UseSliderBannersParams {
  isCheckAuth?: boolean;
}

const defaultParams: UseSliderBannersParams = {
  isCheckAuth: true,
};

interface UseSliderBanners {
  banners: Array<Banner>;
  isLoaded: boolean;
}

/**
 * Хук для получения слайдерных баннеров.
 * @param position - Позиция баннеров.
 * @param params - Параметры хука.
 * @returns Объект с массивом баннеров и флагом загрузки.
 */
export const useSliderBanners = (
  position: BannerPosition,
  params: UseSliderBannersParams = defaultParams,
): UseSliderBanners => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const currency = useTypedSelector(selectUserCurrency);
  const { banners, isLoading } = useTypedSelector(selectBannersByPosition(position));

  const dispatch = useTypedDispatch();

  const dispatchRequest = useCallback(() => {
    dispatch(loadBannersRequest(position));
  }, [dispatch, position]);

  useEffect(() => {
    if (currency && (!params.isCheckAuth || (params.isCheckAuth && isAuth))) {
      dispatchRequest();
    }
  }, [isAuth, params.isCheckAuth, currency, dispatch, dispatchRequest]);

  return { banners: banners || [], isLoaded: isLoading === false };
};
