import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import {
  countriesToOptionsMapper,
  currencyToOptionsMapper,
  selectCountryList,
  selectCurrencyList,
  selectUserProfile,
} from '@mwl/core-lib';
import { FlagIcon } from '@mwl/ui';

import { formatCountriesToPhoneOptions } from '@/components/FormFieldPhone/FormFieldPhone.utils';

import { useTypedSelector } from '../useTypedSelector';

import type { UseFormRegisterOptionsResult } from './useFormRegisterOptions.types';

export const useFormRegisterOptions = (): UseFormRegisterOptionsResult => {
  const { t } = useTranslation('common');

  const countryList = useTypedSelector(selectCountryList);
  const currencyList = useTypedSelector(selectCurrencyList);
  const { country: userCountry } = useTypedSelector(selectUserProfile);

  const codes = useMemo(() => formatCountriesToPhoneOptions(countryList, 'currency'), [countryList]);

  const currencies = useMemo(() => currencyToOptionsMapper(currencyList), [currencyList]);

  const countries = useMemo(() => countriesToOptionsMapper(countryList, t, FlagIcon), [countryList, t]);

  const defaultCountry = useMemo(() => countries.find((country) => country.isDefault) ?? countries[0], [countries]);

  return { codes, currencies, countries, userCountry, defaultCountry };
};
