import type { FC } from 'react';
import { Trans, useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';

import { useWithdrawal } from '../../Withdrawal.context';

import styles from './PayoutBanForm.module.scss';

export const PayoutBanForm: FC = () => {
  const { t } = useTranslation('payment');

  const { changePayment } = useWithdrawal();

  const handleClick = () => {
    changePayment();
  };

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{t('payout_ban.title', 'Withdrawal of funds - prohibited')}</h3>
      <p className={styles.subtitle}>
        {t('payout_ban.subtitle', 'For security reasons, we have temporarily prohibited you from withdrawing funds.')}
      </p>
      <p className={styles.description}>
        {t(
          'payout_ban.description',
          'Please contact the support service in a convenient way for you - we will definitely help you!',
        )}
      </p>
      <p className={styles.email}>
        <Trans
          i18nKey="payout_ban.email"
          components={[<span className={styles.emailAddress} key="payout_ban.email" />]}
        >
          {`Email: <0>support@banzai.bet</0>`}
        </Trans>
      </p>

      <Button
        className={styles.button}
        as="button"
        type="button"
        variant="fulfilled"
        color="red"
        size="md"
        rounded
        fontWeight="bold"
        fontSize="lg"
        onClick={handleClick}
      >
        {t('payout_ban.button', 'Ok')}
      </Button>
    </div>
  );
};
