import type { RefObject } from 'react';
import { useMemo } from 'react';
import { useDropdownContext } from '@mwl/ui';

import { useMediaWidthDown } from '@/hooks/useMediaWidthDown';

interface UseContentMaxHeightParams {
  maxDesktopHeight: number;
  maxMobileHeight: number;
  parentRef?: RefObject<HTMLElement> | null;
}

const padding = 20;

export const useContentMaxHeight = ({
  maxDesktopHeight,
  maxMobileHeight,
  parentRef,
}: UseContentMaxHeightParams): number => {
  const isMobile = useMediaWidthDown('screenXs');

  const {
    elements,
    middlewareData: { offset },
  } = useDropdownContext();

  const calculatedMaxHeight = useMemo(() => {
    const maxHeight = isMobile ? maxMobileHeight : maxDesktopHeight;

    if (!parentRef?.current) {
      return maxHeight;
    }

    const triggerBottom = elements.reference?.getBoundingClientRect().bottom || 0;
    const parentBottom = parentRef.current.getBoundingClientRect().bottom || 0;

    const offsetY = offset?.y || 0;

    const result = Math.min(parentBottom - triggerBottom - offsetY, maxHeight) - padding;

    return result;
  }, [isMobile, maxMobileHeight, maxDesktopHeight, parentRef, elements.reference, offset?.y]);

  return calculatedMaxHeight;
};
