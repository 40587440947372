import type {
  GameCardAnalytics,
  PromotionsAnalytics,
  ProvidersAnalytics,
  SliderCardsAnalytics,
  SliderCardsLoadableAnalytics,
  TournamentsAnalytics,
} from '@/types';

export const listings = {
  topGames: 'topGames',
  newGames: 'newGames',
  favorites: 'favorites',
  providers: 'providers',
  promotions: 'promotions',
  tournaments: 'tournaments',
  gameShows: 'gameShows',
  vipGames: 'vipGames',
  recommendations: 'recommendations',
};

export const gameAnalytics: GameCardAnalytics = {
  playButton: {
    click: {
      eventName: 'gameCard_playBtn_click',
    },
  },
  demoButton: {
    click: {
      eventName: 'gameCard_demoBtn_click',
    },
  },
};

export const sliderAnalytics: SliderCardsAnalytics = {
  moreButton: {
    click: {
      eventName: 'listings_allBtn_click',
    },
  },
  flippingLeft: {
    click: {
      eventName: 'listings_flippingLeftBtn_click',
    },
    swipe: {
      eventName: 'listings_swipe',
    },
  },
  flippingRight: {
    click: {
      eventName: 'listings_flippingRightBtn_click',
    },
    swipe: {
      eventName: 'listings_swipe',
    },
  },
};

export const sliderCardsAnalytics: SliderCardsLoadableAnalytics = {
  slider: sliderAnalytics,
  game: gameAnalytics,
};

export const providersAnalytics: ProvidersAnalytics = {
  slider: sliderAnalytics,
  provider: {
    click: {
      eventName: 'providerCard_img_click',
    },
  },
};

export const promotionsAnalytics: PromotionsAnalytics = {
  slider: sliderAnalytics,
  promotion: {
    click: {
      eventName: 'salesCard_btn_click',
    },
  },
};

export const tournamentsAnalytics: TournamentsAnalytics = {
  slider: sliderAnalytics,
  tournament: {
    card: {
      click: {
        eventName: 'tournamentsCard_img_click',
      },
    },
    aboutButton: {
      click: {
        eventName: 'tournamentsCard_btn_click',
      },
    },
  },
};
