import type { RefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

interface UseSwipeParams {
  onSwipe: (direction: 'left' | 'right') => void;
}

interface UseSwipeResult {
  ref: RefObject<HTMLDivElement>;
}

/**
 * Хук для отслеживания свайпа.
 * @param onSwipe - Функция, выполняемая по окончанию свайпа, в которую передается
 * направление свайпа - 'left' или 'right'.
 * @returns ref - Ссылка на элемент, в котором отслеживаем свайп.
 */
export const useSwipe = ({ onSwipe }: UseSwipeParams): UseSwipeResult => {
  const containerRef = useRef<HTMLDivElement>(null);
  const menuTouchStartX = useRef<number>(0);
  const isMenuTouchEnded = useRef<boolean>(false);
  const isMenuTouchStarted = useRef<boolean>(true);

  const swipeStartMenu = useCallback((event: TouchEvent) => {
    menuTouchStartX.current = event.touches[0].clientX;
  }, []);

  const swipeMenu = useCallback(
    (event: TouchEvent) => {
      const menuTouchEndX = event.changedTouches[0].clientX;
      const scrollWidth = containerRef?.current?.scrollLeft || 0;
      const containerWidth = containerRef?.current?.offsetWidth || 0;
      const fullWidth = containerRef?.current?.scrollWidth || 0;

      if (menuTouchEndX < menuTouchStartX.current && !isMenuTouchEnded.current) {
        onSwipe('left');
        isMenuTouchEnded.current = fullWidth === containerWidth + scrollWidth;
        isMenuTouchStarted.current = true;
      }

      if (menuTouchEndX > menuTouchStartX.current && isMenuTouchStarted.current) {
        onSwipe('right');
        isMenuTouchStarted.current = scrollWidth !== 0;
        isMenuTouchEnded.current = false;
      }
    },
    [onSwipe],
  );

  useEffect(() => {
    const container = containerRef?.current;
    const abortController = new AbortController();

    container?.addEventListener('touchstart', swipeStartMenu, { passive: true, signal: abortController.signal });
    container?.addEventListener('touchend', swipeMenu, { passive: true, signal: abortController.signal });

    return () => {
      abortController.abort();
    };
  }, [swipeMenu, swipeStartMenu]);

  return { ref: containerRef };
};
