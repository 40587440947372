import { kebabToCamelCase } from '@mwl/core-lib';

import type { ButtonProps } from './Button.types';

import styles from './Button.module.scss';

type GetButtonParams = Required<Pick<ButtonProps, 'color' | 'size' | 'variant' | 'fontSize' | 'skew'>> &
  Pick<ButtonProps, 'className' | 'active' | 'rounded' | 'disabled' | 'uppercase' | 'cut'>;

export function getButtonClassNames({
  active,
  className,
  color,
  cut,
  disabled,
  fontSize,
  rounded,
  size,
  skew,
  uppercase,
  variant,
}: GetButtonParams) {
  return cn(
    styles.button,
    styles[variant],
    styles[`${fontSize}FontSize`],
    styles[`${size}Size`],
    styles[`${kebabToCamelCase(color)}Color`],
    styles[`${skew}Skew`],
    {
      [styles.cut]: cut,
      [styles.active]: active,
      [styles.rounded]: rounded,
      [styles.disabled]: disabled,
      [styles.uppercase]: uppercase,
    },
    className,
  );
}
