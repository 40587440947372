import { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { PaymentMethodRestrictions } from '@mwl/core-lib';
import { calcAmountDefaultValue, getDepositFormSettings, useDeposit } from '@mwl/core-lib';

import type { UseUpdateDepositFormAmountParams } from './useUpdateDepositFormAmount.types';

export const useUpdateDepositFormAmount = ({ resetForm, min, values }: UseUpdateDepositFormAmountParams) => {
  const { t } = useTranslation('payment');

  const { amount } = values;

  const { payment, selectedBonus, useBonus } = useDeposit();
  const { identity, fieldList } = payment ?? {};

  const prevIdentifyRef = useRef(identity);
  const prevMinAmount = useRef<string>();
  const activeBonusId = useRef<number>();
  const prevValue = useRef({ amount, min });

  useEffect(() => {
    prevValue.current = { amount, min };
  }, [amount, min]);

  useEffect(() => {
    const minAmount =
      useBonus && selectedBonus?.minimalDepositAmount ? String(selectedBonus?.minimalDepositAmount) : undefined;

    const amountField = fieldList?.find((field) => field.name === 'amount');

    if (prevIdentifyRef?.current && prevIdentifyRef?.current !== identity) {
      resetForm(getDepositFormSettings({ t, fields: fieldList }));
    } else if (amountField && (activeBonusId.current !== selectedBonus?.id || minAmount !== prevMinAmount.current)) {
      const restrictions: Array<PaymentMethodRestrictions> = [...(amountField.restrictions || [])];

      if (minAmount) {
        restrictions.push({ name: 'minBonusAmount', value: minAmount });
      }

      const defaultValue = calcAmountDefaultValue({
        minBonusValue: useBonus ? selectedBonus?.minimalDepositAmount : undefined,
        minFieldValue: prevValue.current.min,
        value: prevValue.current.amount as string,
        useBonus,
        isIdentifyChange: prevIdentifyRef?.current !== identity,
      });

      const filteredFields = fieldList?.filter((field) => field.name !== 'amount') ?? [];

      resetForm(
        getDepositFormSettings({
          t,
          fields: [...filteredFields, { ...amountField, restrictions }],
          defaultValues: { ...values, amount: defaultValue },
        }),
      );
    }

    prevMinAmount.current = minAmount;
    activeBonusId.current = selectedBonus?.id;
    prevIdentifyRef.current = identity;
  }, [identity, fieldList, resetForm, t, selectedBonus, useBonus, values]);
};
