import { complex, simple } from '@mwl/core-lib';

export const loyaltyPageSchema = complex({
  onbording: simple,
  banner: simple,
  currentLevel: simple,
  cashback: simple,
  giftStore: simple,
  casinoBonus: simple,
  sportBonus: simple,
});
