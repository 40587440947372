import type { ComponentType, FC } from 'react';
import { lazy, Suspense } from 'react';
import { checkIsServer, useWheelData } from '@mwl/core-lib';

import { useMediaWidthDown } from '@/hooks';
import { federation, wheelModuleName } from '@/utils/federation';

import { Loader } from '../Loader/Loader';

import type { WheelProps } from './Wheel.types';

import styles from './Wheel.module.scss';

const scope = 'wheelV2';

const WheelComponent = lazy<ComponentType<WheelProps>>(async () => {
  return (await federation.loadRemote(`${scope}/${wheelModuleName}`)) as {
    default: ComponentType<WheelProps>;
  };
});

const Wheel: FC = () => {
  const isMobile = useMediaWidthDown('screenXs');

  const wheelData = useWheelData();
  const isServer = checkIsServer();

  if (isServer) {
    return null;
  }

  return (
    <Suspense
      fallback={
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      }
    >
      <WheelComponent {...wheelData} theme="banzai" isMobile={isMobile} sportEnabled={false} balanceUpdateRemotely />
    </Suspense>
  );
};

export { Wheel };
