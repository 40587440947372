export const routes = {
  home: '/',
  faq: '/faq',
  rules: '/rules',
  notFound: '/404',
  bonuses: '/bonuses',
  contacts: '/contacts',
  privacyPolicy: '/privacy-policy',
  affiliates: '/affiliates',
  download: '/download/[platform]',
  loyalty: '/loyalty',
  promoRules: '/promotion-rules',
  promotionRules: '/promotion-rules',
  store: '/store',

  sport: {
    home: '/sport',
    line: '/sport/line/[lineId]',
    favorites: '/sport/favorites',
    live: '/sport/live',
    pregame: '/sport/pregame',
    history: '/sport/history',
  },

  providers: {
    home: '/providers',
    provider: `/providers/[providerName]`,
  },

  casino: {
    home: '/casino',
    category: '/casino/[category]',
    favorites: '/casino/favorites',
    game: '/casino/game/[gameId]/[mode]',
    buyBonus: '/casino/bonus_buy',
  },

  tournaments: {
    home: '/tournaments',
    tournament: '/tournaments/[tournamentLink]',
  },

  liveCasino: {
    home: '/live-casino',
    category: '/live-casino/[category]',
    game: '/live-casino/game/[gameId]/[mode]',
  },

  promo: {
    home: '/promo',
    about: '/promo/about',
    cashBack: '/promo/cash-back',
    newYearLuckyTicket: '/promo/new_year_lucky_ticket2024',
    januaryLuckyTicket: '/promo/january_lucky_ticket2024',
  },

  poker: {
    home: '/poker',
  },

  wheel: {
    home: '/wheel',
  },

  profile: {
    home: '/profile',
    requestHistory: '/profile/request-history',
  },
};
