import { byKey, complex, simple } from '@mwl/core-lib';

const purchaseSchema = complex({
  button: simple,
  popup: simple,
});

const infoSchema = complex({
  button: simple,
  popup: simple,
});

const priceSchema = complex({
  cash: simple,
  gems: simple,
  gemsIcon: simple,
  gemsTooltip: simple,
});

const cardSchema = complex({
  card: simple,
  purchase: purchaseSchema,
  info: infoSchema,
  title: simple,
  price: priceSchema,
});

export const storePageSchema = complex({
  page: simple,
  giftBlock: simple,
  title: simple,
  gemsBalance: simple,
  filters: byKey(simple),
  cards: cardSchema,
});
