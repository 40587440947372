import type { FC, PropsWithChildren } from 'react';
import { memo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { extendAnalyticsData } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { routes } from '@/constants';

import type { CheckboxSignUpProps } from './CheckboxSignUp.types';

import styles from './CheckboxSignUp.module.scss';

const BaseCheckboxSignUp: FC<PropsWithChildren<CheckboxSignUpProps>> = ({ className, children, classes, ...props }) => {
  const { t } = useTranslation();

  const link = (
    <Button
      as="link"
      href={routes.rules}
      variant="text"
      color="white"
      className={styles.link}
      uppercase={false}
      analytics={extendAnalyticsData(props.analytics, { href: routes.rules, text: t('register.confirm') })}
    />
  );

  return (
    <Checkbox
      classes={{ text: cn(styles.text, classes?.text), box: cn(styles.box, classes?.box) }}
      className={cn(styles.root, className)}
      {...props}
    >
      {children || (
        <Trans i18nKey="common:register.confirm" components={[link]}>
          {`I confirm that I'm of legal age and agree with the <0>site rules</0>`}
        </Trans>
      )}
    </Checkbox>
  );
};

const CheckboxSignUp = memo(BaseCheckboxSignUp);

export * from './CheckboxSignUp.types';
export { CheckboxSignUp };
