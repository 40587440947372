import type { TippyProps } from '@tippyjs/react';

export const defaultTippyProps: TippyProps = {
  arrow: false,
  zIndex: 15,
  maxWidth: 'none',
  animation: 'scale-extreme',
  placement: 'bottom-start',
  interactive: true,
  allowHTML: true,
  appendTo: () => document.body as Element,
};
