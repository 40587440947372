import { useMemo } from 'react';
import type { PaymentItem, PaymentMethodField } from '@mwl/core-lib';
import { isPaymentFieldCopiable } from '@mwl/core-lib';

export interface UsePaymentEditableFieldsResult {
  editableFields: Array<PaymentMethodField>;
  staticFields: Array<PaymentMethodField>;
}

export const usePaymentEditableFields = (method?: PaymentItem) => {
  const result = useMemo(() => {
    const defaultValue: UsePaymentEditableFieldsResult = {
      editableFields: [],
      staticFields: [],
    };

    return (
      method?.fieldList?.reduce<UsePaymentEditableFieldsResult>((acc, field) => {
        const isNonEditable = field.type === 'qrCode' || isPaymentFieldCopiable(field.attr);

        if (isNonEditable) {
          acc.staticFields.push(field);
        } else {
          acc.editableFields.push(field);
        }

        return acc;
      }, defaultValue) ?? defaultValue
    );
  }, [method]);

  return result;
};
