import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import type { BaseQueryPopupParams } from '@mwl/core-lib';
import { payoutCancelSuccessKey, usePayloadHistory, useQueryPopupOpen } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';
import { Loader } from '@/components/Loader/Loader';
import type { MessageClasses } from '@/components/Message/Message';
import { Message } from '@/components/Message/Message';
import { ModalMessage } from '@/components/ModalMessage/ModalMessage';
import type { PopupClasses } from '@/components/Popup/Popup.types';

import { MessageConfirmCancelling } from '../../components/MessageConfirmCancelling/MessageConfirmCancelling';

import { HistoryTable } from './components/HistoryTable/HistoryTable';
import { usePaymentsHistoryState } from './PaymentsHistory.hook';
import type { PaymentsHistoryProps } from './PaymentsHistory.types';

import styles from './PaymentsHistory.module.scss';

const messageClasses: MessageClasses = {
  button: styles.emptyButton,
};

const popupClasses: PopupClasses = {
  wrapper: styles.popupWrapper,
};

export const PaymentsHistory: FC<PaymentsHistoryProps> = ({ className }) => {
  const { t } = useTranslation('payment');

  const { createLinkProps } = useQueryPopupOpen();

  const { history, isLoading } = usePayloadHistory();

  const { form, selectedItem, handleClose, handleOpenConfirmCancelling } = usePaymentsHistoryState();

  if (isLoading) {
    return <Loader />;
  }

  const isHistoryEmpty = !history.length;

  const linkProps = createLinkProps({ queryName: 'deposit' as BaseQueryPopupParams });

  return (
    <div
      className={cn(styles.root, className, {
        [styles.emptyRoot]: isHistoryEmpty,
      })}
    >
      {isHistoryEmpty && (
        <div className={styles.empty}>
          <Message
            status="error"
            classes={messageClasses}
            textColor="grey-light"
            textSize="small"
            customAction={
              <Button
                as="link"
                {...linkProps}
                color="green"
                fontSize="lg"
                fontWeight="bold"
                size="md"
                align="center"
                rounded
              >
                {t('history_make_deposit', 'Make a deposit')}
              </Button>
            }
          >
            {t(
              'history_empty',
              'To see the withdrawal methods available in your region, please make your first deposit',
            )}
          </Message>
        </div>
      )}

      {!isHistoryEmpty && (
        <HistoryTable className={styles.table} items={history} onCancelPayment={handleOpenConfirmCancelling} />
      )}

      <ModalMessage
        popupClasses={popupClasses}
        status="success"
        show={form === 'CANCEL_CONFIRM'}
        content={t(payoutCancelSuccessKey)}
        onClose={handleClose}
      >
        <MessageConfirmCancelling payoutId={selectedItem?.id} onClose={handleClose} onCancel={handleClose} />
      </ModalMessage>
    </div>
  );
};
