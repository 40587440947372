import { useCallback, useEffect, useRef, useState } from 'react';

interface UseShowFormFieldTooltipOptions<T> {
  isTouched: boolean;
  errors: { [K in keyof T]: string };
  errorFieldAfterTouched: keyof T;
}

type ErrorsState<T> = {
  [K in keyof T]: 'show' | 'hide' | 'close';
};

const timeout = 3000;

const convertErrors = <T extends AnyObject>(errors: T) => {
  return (Object.keys(errors) as Keys<T>).reduce((acc, key) => {
    // eslint-disable-next-line no-param-reassign
    acc[key] = 'hide';
    return acc;
  }, {} as ErrorsState<T>);
};

export const useShowFormFieldTooltip = <T extends AnyObject>({
  isTouched,
  errors,
  errorFieldAfterTouched,
}: UseShowFormFieldTooltipOptions<T>) => {
  const timeoutRef = useRef<Nullable<NodeJS.Timeout>>(null);

  const [showedErrors, setShowedErrors] = useState<ErrorsState<T>>({} as ErrorsState<T>);

  const showError = useCallback((field: keyof T, newErrors?: ErrorsState<T>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setShowedErrors((prev) => {
      if (prev[field] === 'close') {
        return prev;
      }

      return {
        ...prev,
        ...newErrors,
        [field]: 'show',
      };
    });
  }, []);

  const hideError = useCallback((field: keyof T) => {
    setShowedErrors((prev) => {
      if (prev[field] === 'close') {
        return prev;
      }

      return {
        ...prev,
        [field]: 'hide',
      };
    });
  }, []);

  const closeError = (field: keyof T) => () => {
    setShowedErrors((prev) => {
      return {
        ...prev,
        [field]: 'close',
      };
    });
  };

  const setShowedErrorsWithTimer = useCallback(
    (field: keyof T, newErrors?: ErrorsState<T>) => {
      showError(field, newErrors);

      timeoutRef.current = setTimeout(() => {
        hideError(field);
      }, timeout);
    },
    [hideError, showError],
  );

  useEffect(() => {
    if (isTouched) {
      const converted = convertErrors(errors);
      setShowedErrorsWithTimer(errorFieldAfterTouched, converted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouched]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    showedErrors,
    hideError,
    showError,
    setShowedErrorsWithTimer,
    closeError,
  };
};
