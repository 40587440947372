import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import type { Analytics } from '@mwl/core-lib';
import { extendAnalyticsData, minLengthValidator, minPasswordLength, sendAnalyticsData } from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';

import MailIcon from '@public/assets/common/icons/mail.svg';

import { FormField } from '@/components/FormField/FormField';
import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormFieldTooltip } from '@/components/FormFieldTooltip';
import { useShowFormFieldTooltip } from '@/components/FormFieldTooltip/FormFieldTooltip.hooks';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { Select } from '@/components/Select/Select';
import { useFormRegisterOptions, useTogglePopupRegistrationBonus } from '@/hooks';

import type { RegistrationCountryOption } from '@/types';

import { ButtonSignIn } from '../ButtonSignIn/ButtonSignIn';
import { ButtonSignUp } from '../ButtonSignUp/ButtonSignUp';
import { CheckboxSignUp } from '../CheckboxSignUp/CheckboxSignUp';
import { PromoCodeField } from '../PromoCodeField/PromoCodeField';

import { useRegistrationEmailForm, useRegistrationEmailHandlers } from './FormRegistrationEmail.hooks';
import type { FormRegistrationEmailProps } from './FormRegistrationEmail.types';

import styles from './FormRegistrationEmail.module.scss';

const BaseFormRegistrationEmail: FC<FormRegistrationEmailProps> = ({
  className,
  variant = 'default',
  parentRef,
  testSchema,
  analytics,
}) => {
  const { t } = useTranslation('common');

  const { currencies, countries, userCountry, defaultCountry } = useFormRegisterOptions();

  const { handleSubmit, isLoading, error, formState, changeField } = useRegistrationEmailForm({
    analytics: analytics?.submit,
  });

  const { handleInputChange, handleSelectCurrency, handleSelectCountry } = useRegistrationEmailHandlers({
    changeField,
  });

  useTogglePopupRegistrationBonus({
    fieldsValues: [formState.values.email, formState.values.password, formState.values.promoCode],
  });

  useEffect(() => {
    if (countries.length) {
      const countryValue = countries.find((country) => country.value === userCountry) ?? defaultCountry;
      handleSelectCountry(countryValue);
    }
  }, [countries, defaultCountry, handleSelectCountry, userCountry]);

  useEffect(() => {
    if (formState.values.country && currencies?.length) {
      const currencyOption = currencies.find((value) => value.value === formState.values.country.currency);
      handleSelectCurrency(currencyOption ?? currencies[0]);
    }
  }, [formState.values.country, currencies, handleSelectCurrency]);

  const hasErrorEmail = Boolean(formState.isTouched && formState.errors?.email);
  const hasErrorPassword = Boolean(formState.isTouched && formState.errors?.password);

  const { showedErrors, closeError, showError, hideError } = useShowFormFieldTooltip({
    isTouched: formState.isTouched,
    errors: formState.errors,
    errorFieldAfterTouched: 'email',
  });

  const signUpAnalytics = extendAnalyticsData(analytics?.signUp, {
    with_promocode: !!formState.values.promoCode,
    promocode: formState.values.promoCode || undefined,
  });

  const handleSelectCountryWithAnalytics = useCallback(
    (option: RegistrationCountryOption) => {
      const analyticsWithText = extendAnalyticsData(analytics?.country, { text: option.label });
      sendAnalyticsData(analyticsWithText?.change?.eventName, analyticsWithText?.change?.data);

      handleSelectCountry(option);
    },
    [analytics?.country, handleSelectCountry],
  );

  const handleSelectCurrencyWithAnalytics = useCallback(
    (option: SelectOption) => {
      const analyticsWithText = extendAnalyticsData(analytics?.currency, { text: option.label });
      sendAnalyticsData(analyticsWithText?.change?.eventName, analyticsWithText?.change?.data);

      handleSelectCurrency(option);
    },
    [analytics?.currency, handleSelectCurrency],
  );

  const handleBlur = (fieldAnalytics?: Analytics) => () => {
    sendAnalyticsData(fieldAnalytics?.change?.eventName, fieldAnalytics?.change?.data);
  };

  return (
    <form
      className={cn(styles.root, className, styles[`${variant}Variant`])}
      onSubmit={handleSubmit}
      {...testSchema?.nodeProps}
    >
      {error && error.type !== '/email-already-exists' && (
        <FormMessage className={styles.message}>
          {Array.isArray(error.message) ? t(error.message) : error.message}
        </FormMessage>
      )}
      {isLoading && <Loader />}
      <div className={styles.formContainer}>
        <FormFieldTooltip
          errorText={t(formState.errors?.email)}
          isError={hasErrorEmail}
          isShow={showedErrors?.email === 'show'}
          onClose={closeError('email')}
          className={styles.emailField}
          onClickOutside={() => hideError('email')}
        >
          <FormField
            inputMode="email"
            autoComplete="email"
            autoCapitalize="off"
            as="input"
            placeholder={t('form.field.email', 'E-mail')}
            label={t('form.field.email', 'E-mail')}
            errorText={t('form.field.email', 'E-mail')}
            value={formState.values.email}
            name="email"
            prefix={<MailIcon className={cn(styles.mailIcon, hasErrorEmail && styles.hasError)} />}
            onChange={handleInputChange}
            onBlur={handleBlur(analytics?.email)}
            isError={hasErrorEmail}
            sizeVariant="32"
            onFocus={() => showError('email')}
            {...testSchema?.emailInput.nodeProps}
          />
        </FormFieldTooltip>
        <FormFieldTooltip
          errorText={t(formState.errors?.password)}
          isError={hasErrorPassword}
          isShow={showedErrors?.password === 'show'}
          onClose={closeError('password')}
          className={styles.passwordField}
          defaultText={
            !minLengthValidator(minPasswordLength)(formState.values.password)
              ? t('error.password.wrongFormat', 'The password must contain at least 8 characters')
              : undefined
          }
          onClickOutside={() => hideError('password')}
        >
          <FormFieldHidden
            autoComplete="off"
            placeholder={t('form.field.password', 'Password')}
            label={t('form.field.password', 'Password')}
            errorText={t('form.field.password', 'Password')}
            value={formState.values.password}
            onChange={handleInputChange}
            onBlur={handleBlur(analytics?.password)}
            name="password"
            isError={hasErrorPassword}
            sizeVariant="32"
            onFocus={() => showError('password')}
            analytics={analytics?.password}
            {...testSchema?.passwordInput.nodeProps}
          />
        </FormFieldTooltip>
        <Select
          className={styles.currencyField}
          selected={formState.values.currency}
          options={currencies}
          onSelect={handleSelectCurrencyWithAnalytics}
          parentRef={parentRef}
          sizeVariant="32"
          {...testSchema?.currencySelect.nodeProps}
        />
        <Select
          selected={formState.values.country}
          options={countries}
          onSelect={handleSelectCountryWithAnalytics}
          parentRef={parentRef}
          sizeVariant="32"
          className={styles.countryField}
          {...testSchema?.countrySelect.nodeProps}
        />
        <PromoCodeField
          name="promoCode"
          value={formState.values.promoCode}
          onChange={handleInputChange}
          sizeVariant="32"
          className={styles.promoCodeField}
          analytics={analytics?.promoCode}
        />
        <CheckboxSignUp
          name="agree"
          checked={formState.values.agree}
          onChange={handleInputChange}
          className={styles.agreeField}
          variant={formState.isTouched && formState.errors?.agree ? 'red' : 'default'}
          analytics={analytics?.rules}
          {...testSchema?.agreementCheckbox.nodeProps}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonSignUp {...testSchema?.signUpButton.nodeProps} analytics={signUpAnalytics} />
        <ButtonSignIn {...testSchema?.signInButton.nodeProps} analytics={analytics?.signIn} />
      </div>
    </form>
  );
};

const FormRegistrationEmail = memo(BaseFormRegistrationEmail);

export * from './FormRegistrationEmail.types';
export { FormRegistrationEmail };
