import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { QRCodeSVG } from 'qrcode.react';

import { Image } from '@/components/Image/Image';

import { PaymentReadonlyField } from '../PaymentReadonlyField/PaymentReadonlyField';
import { PaymentTextButton } from '../PaymentTextButton/PaymentTextButton';

import type { PaymentTextElementProps } from './PaymentTextElement.types';

import styles from './PaymentTextElement.module.scss';

export const PaymentTextElement: FC<PaymentTextElementProps> = ({
  className,
  type,
  title,
  value,
  clipboardCopy,
  linkFromKey,
  link,
  resource,
  action,
  buttonType,
}) => {
  const { t } = useTranslation('payment');

  const getField = () => {
    switch (type) {
      case 'text':
        return (
          <PaymentReadonlyField
            className={styles.textWrapper}
            title={title ? t(title) : ''}
            value={value ? t(value) : ''}
            attr={[{ key: 'copiable', value: String(!!clipboardCopy) }]}
          />
        );

      case 'qrCode':
        return (
          <div className={styles.qrCodeWrapper}>
            <QRCodeSVG
              className={styles.qrCode}
              value={value || ''}
              bgColor="transparent"
              fgColor="#000"
              level="L"
              includeMargin={false}
            />
          </div>
        );

      case 'image':
        return (
          <div className={styles.imageWrapper}>
            <Image src={value || ''} fill style={{ objectFit: 'contain' }} alt="Image Preview" />
          </div>
        );

      case 'button':
        return (
          <PaymentTextButton
            className={className}
            title={title}
            buttonType={buttonType}
            resource={resource}
            action={action}
            link={link}
            linkFromKey={linkFromKey}
          />
        );

      default:
        return null;
    }
  };

  return <div className={cn(styles.root, className)}>{getField()}</div>;
};
