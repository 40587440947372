import type { FormSettings } from '@mwl/core-lib';
import { requiredStringValidator } from '@mwl/core-lib';

import { checkIsEmail } from '../../FormRecovery.utils';

import type { FormRecoveryLoginValues } from './FormRecoveryLogin.types';

export const getFormSettings = (value: string): FormSettings<FormRecoveryLoginValues> => ({
  login: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error.requiredField_common' },
    },
    value,
  },
});

export const formatPhone = (value: string) => {
  return /^\+/.test(value) ? value : `+${value}`;
};

export const formatLogin = (value: string) => {
  const isEmail = checkIsEmail(value);

  return isEmail ? value : formatPhone(value);
};
