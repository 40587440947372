import type { ChangeEventHandler } from 'react';
import { useCallback } from 'react';
import { format } from 'date-fns/format';
import {
  formatPhoneNumber,
  formatResponseErrors,
  fullRegistration,
  loginSuccess,
  registerSuccess,
  removeAllSpaces,
  selectUserLocale,
  setRecoveryLogin,
  useForm,
  useQueryPopupOpen,
  useSubmitFormAuth,
} from '@mwl/core-lib';

import { useRegister } from '@/context/register/register';
import { useTypedDispatch, useTypedSelector } from '@/hooks';

import type {
  FormRegistrationExtendedValues,
  UseRegistrationExtendedHandlersParams,
} from './FormRegistrationExtended.types';
import { formSettings } from './FormRegistrationExtended.utils';

export function useRegistrationExtendedForm() {
  const { formState, changeField, handleSubmit, setErrors } = useForm<FormRegistrationExtendedValues>(formSettings);

  const dispatch = useTypedDispatch();

  const locale = useTypedSelector(selectUserLocale);

  const { selectedBonus, onSuccessRegister } = useRegister();

  const { onOpenPopup } = useQueryPopupOpen();

  const {
    state: { error, isLoading },
    onSubmitConfirm,
  } = useSubmitFormAuth({
    requestHandler: fullRegistration,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.token) {
        dispatch(registerSuccess());
        dispatch(loginSuccess({ token: data.token, skipAuthReload: true }));
        onSuccessRegister();
      }
    },
    onError: (formError) => {
      const isEmailAlreadyExist = !!formError.details?.find((detail) => detail.key === 'error.emailAlreadyExists');
      const isPhoneAlreadyExist = !!formError.details?.find((detail) => detail.key === 'error.phoneAlreadyExists');

      if (isEmailAlreadyExist) {
        dispatch(setRecoveryLogin(formState.values.email));
        onOpenPopup({ queryName: 'reset_password' });
      } else if (isPhoneAlreadyExist) {
        dispatch(setRecoveryLogin(formatPhoneNumber(formState.values.code.value, formState.values.phone)));
        onOpenPopup({ queryName: 'reset_password' });
      } else {
        setErrors(formatResponseErrors<typeof formState.errors>(formError));
      }
    },
  });

  const submitConfirm = async (values: FormRegistrationExtendedValues) => {
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';

    onSubmitConfirm({
      phonePrefix: values.code.value,
      phone: formatPhoneNumber(values.code.value, values.phone),
      firstName: values.name,
      address: values.city,
      dateOfBirth: values.dateOfBirth,
      countryAlpha2IsoCode: values.country.value,
      currencyIsoCode: values.currency.label,
      email: values.email,
      password: values.password,
      bonusType: selectedBonus.type !== 'REFUSAL' ? selectedBonus.type : undefined,
      timezone,
      locale,
    });
  };

  return {
    changeField,
    handleSubmit: handleSubmit(submitConfirm),
    isLoading,
    error,
    onSubmitConfirm,
    formState,
  };
}

export function useRegistrationExtendedHandlers({ changeField }: UseRegistrationExtendedHandlersParams) {
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value, checked, type, dataset } = event.target;

      const typedName = name as keyof FormRegistrationExtendedValues;

      const removeAllSpacesFn = dataset?.preserveSpace ? (val: string) => val : removeAllSpaces;

      const typedValue = (
        type === 'checkbox' ? checked : removeAllSpacesFn(value)
      ) as ValueOf<FormRegistrationExtendedValues>;

      changeField(typedName, typedValue);
    },
    [changeField],
  );

  const handleChangeDate = useCallback(
    (time: number) => {
      changeField('dateOfBirth', format(new Date(time), 'yyyy-MM-dd'));
    },
    [changeField],
  );

  return {
    handleInputChange,
    handleChangeDate,
  };
}
