import type { FC } from 'react';

import { Button } from '@/components/Button/Button';

import { useWithdrawal } from '../../views/Withdrawal/Withdrawal.context';

import type { PaymentSubmitProps } from './PaymentSubmit.types';

export const PaymentSubmit: FC<PaymentSubmitProps> = ({ className, children, disabled }) => {
  const { payment, changePayment } = useWithdrawal();

  const props =
    payment?.method.type === 'pop-up'
      ? { type: 'button' as const, onClick: () => changePayment() }
      : { type: 'submit' as const };

  return (
    <Button
      className={className}
      as="button"
      variant="fulfilled"
      rounded
      fullWidth
      fontSize="lg"
      fontWeight="bold"
      size="md"
      color="green"
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};
