import type { FormSettings } from '@mwl/core-lib';
import { isEmail, requiredStringValidator } from '@mwl/core-lib';

import type { FormLoginEmailValues } from './FormLoginEmail.types';

export const formSettings: FormSettings<FormLoginEmailValues> = {
  email: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      email: { validate: isEmail, message: 'error' },
    },
    value: '',
  },
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
    },
    value: '',
  },
};
