/* eslint-disable global-require */
import React from 'react';
import ReactDOM from 'react-dom';
import { init } from '@module-federation/enhanced/runtime';

const isDevWheel = process.env.DEPLOY_ENV === 'development';

const wheelHost = `https://microfrontendwof-${isDevWheel ? 'dev' : 'prod'}.com/wheelV2.js`;

export const wheelModuleName = 'wheelV2';

const federation = init({
  name: 'host',
  remotes: [
    {
      entry: wheelHost,
      name: wheelModuleName,
      alias: wheelModuleName,
      shareScope: 'default',
      type: 'global',
    },
  ],
  shared: {
    react: {
      scope: 'default',
      lib: () => React,
      get: () => () => require('react'),
      shareConfig: {
        singleton: true,
        requiredVersion: '^18',
      },
    },
    'react-dom': {
      scope: 'default',
      lib: () => ReactDOM,
      get: () => () => require('react-dom'),
      shareConfig: {
        singleton: true,
        requiredVersion: '^18',
      },
    },
  },
});

export { federation };
