import { isDev } from '@mwl/core-lib';

const allowedHosts: Array<string> = [];

const getHost = () => (typeof window !== 'undefined' ? window.location.hostname : '');

/**
 * Возвращает результат метода согласно настройкам проекта.
 * @todo Временное решение, пока нужные настройки не добавлены в админке
 */
export const fullRegistrationIsAllowed = (): boolean => {
  const host = getHost();

  return isDev ? false : allowedHosts.includes(host);
};
