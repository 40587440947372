import type { ParsedUrlQueryInput } from 'querystring';
import { isNotEmptyArray, isNotNullOrUndefined, keys } from '@mwl/core-lib';

import { sportUrlNormalParamsMap, sportUrlParamsMap } from '@/constants';

import type { SportUrlNormalParams, SportUrlParams } from '@/types';

import type { GetLinesCountByEntityParams } from './useSportUrlParams.types';

/**
 * Очищает урл от пустой строки, пустого массива, null и undefined
 * @param obj "грязный" объект URL
 *
 * @returns "очищенный" объект URL
 */
export const removeEmptyValues = (obj: ParsedUrlQueryInput): ParsedUrlQueryInput =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      if (Array.isArray(value)) {
        return isNotEmptyArray(value);
      }

      return isNotNullOrUndefined(value) && value !== '';
    }),
  );

/**
 * Трансформирует query параметры спорта в нормальный вид
 * @param query объект с сокращенными параметрами спорта
 *
 * @returns объект с нормальными параметрами спорта
 */
export const convertParamsToNormal = (query: SportUrlParams): SportUrlNormalParams => {
  return keys(query).reduce((result, key) => {
    return {
      ...result,
      [sportUrlParamsMap[key]]: query[key],
    };
  }, {});
};

/**
 * Трансформирует query параметры спорта в сокращенный вид
 * @param query объект с нормальными параметрами спорта
 *
 * @returns объект с сокращенными параметрами спорта
 */
export const convertParamsToShort = (query: SportUrlNormalParams): SportUrlParams => {
  return keys(query).reduce((result, key) => {
    return {
      ...result,
      [sportUrlNormalParamsMap[key]]: query[key],
    };
  }, {});
};

/**
 * Превращает строку в массив с единственной строкой
 * @param value
 *
 * @returns массив со значением-строкой, либо изначальный массив, либо undefined
 */
export const convertToArray = (value: undefined | string | Array<string>): Array<string> | undefined => {
  if (typeof value === 'string') {
    return [value];
  }

  return value;
};

/**
 * Получение количества линий определенного стейджа в нужных сущностях (виды спорта / категории / чемпионаты)
 * @param list - массив сущностей (виды спорта / категории / чемпионаты)
 * @param targets - массив id целевых сущностей для фильтрации общего списка
 * @param newStage - стейдж (LIVE / PREGAME) для которого нужно получить количество линий
 *
 * @returns объект, в котором ключами являются ID сущностей, а значениями - количество линий нужного стейджа в данной сущности
 */
export const getLinesCountByEntity = ({ list, targets, newStage }: GetLinesCountByEntityParams) => {
  return list.reduce<Dictionary<number>>((result, item) => {
    if (!targets.length) {
      return result;
    }

    if (targets.includes(item.id) && newStage) {
      const currentStage = item.linesCount.find((countItem) => countItem.stage === newStage);

      if (!currentStage) {
        return result;
      }

      return {
        ...result,
        [item.id]: currentStage?.count,
      };
    }

    return result;
  }, {});
};
