import type { FormSettings } from '@mwl/core-lib';
import {
  ageValidator,
  isEmail,
  isNotEmpty,
  minLengthValidator,
  minPasswordLength,
  onlyNumbers,
  removeAllSpaces,
  requiredStringValidator,
  requiredValidator,
} from '@mwl/core-lib';

import type { FormRegistrationExtendedState, FormRegistrationExtendedValues } from './FormRegistrationExtended.types';

export const initialState: FormRegistrationExtendedState = {
  error: null,
  isLoading: false,
  isSuccess: false,
};

export const formSettings: FormSettings<FormRegistrationExtendedValues> = {
  name: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: '',
  },
  city: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: '',
  },
  dateOfBirth: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
      date: { validate: ageValidator, message: 'error' },
    },
    value: '',
  },
  code: {
    rules: {
      required: { validate: isNotEmpty, message: 'error' },
    },
    value: { format: '', label: '', value: '', prefix: '' },
  },
  country: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '', prefix: '' },
  },
  currency: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '' },
  },
  email: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      extended: { validate: isEmail, message: 'error' },
    },
    value: '',
  },
  phone: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      minLength: {
        validate: (value, state) =>
          minLengthValidator(removeAllSpaces(state?.code.format || '').length)(onlyNumbers(value)),
        message: 'error',
      },
    },
    value: '',
  },
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      minLength: { validate: minLengthValidator(minPasswordLength), message: 'error' },
    },
    value: '',
  },
  promoCode: {
    value: '',
  },
  agree1: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  agree2: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  agree3: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  agree4: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  agree5: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  agree6: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
};
