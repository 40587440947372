import type { SportUrlNormalParamsKeys, SportUrlParamsKeys } from '@/types';

export const sportUrlParamsMap: Record<SportUrlParamsKeys, SportUrlNormalParamsKeys> = {
  sch: 'search',
  schsp: 'searchSportId',
  sp: 'sportId',
  sps: 'sportIds',
  ct: 'categoryId',
  cts: 'categoryIds',
  ch: 'championshipId',
  chs: 'championshipIds',
  st: 'stage',
  upc: 'upcoming',
};

export const sportUrlNormalParamsMap: Record<SportUrlNormalParamsKeys, SportUrlParamsKeys> = {
  search: 'sch',
  searchSportId: 'schsp',
  sportId: 'sp',
  sportIds: 'sps',
  categoryId: 'ct',
  categoryIds: 'cts',
  championshipId: 'ch',
  championshipIds: 'chs',
  stage: 'st',
  upcoming: 'upc',
};
