import type { FC } from 'react';
import { useCallback } from 'react';
import { useDeposit, useQueryPopup } from '@mwl/core-lib';

import { ModalMessage } from '@/components/ModalMessage/ModalMessage';

import { PopupDeclineStatus } from '../PopupDeclineStatus/PopupDeclineStatus';
import { PopupPaymentStatus } from '../PopupPaymentStatus/PopupPaymentStatus';

import styles from './DepositModals.module.scss';

const popupClasses = {
  wrapper: styles.popupWrapper,
};

export const DepositModals: FC = () => {
  const { content, changeContent } = useDeposit();
  const { onClose } = useQueryPopup();

  const resetContent = useCallback(() => {
    changeContent({ form: 'create' });
  }, [changeContent]);

  const closeOnSuccess = () => onClose();

  return (
    <>
      <ModalMessage
        popupClasses={popupClasses}
        show={content.form === 'error'}
        status="error"
        onClose={resetContent}
        title={content.title}
        content={content.message}
        buttonText={content.buttonText}
      />
      <ModalMessage
        popupClasses={popupClasses}
        show={content.form === 'success'}
        status="success"
        onClose={closeOnSuccess}
        title={content.title}
        content={content.message}
        buttonText={content.buttonText}
      />
      <PopupPaymentStatus />
      <PopupDeclineStatus />
    </>
  );
};
