import type { FormSettings } from '@mwl/core-lib';
import { minLengthValidator, minPasswordLength, requiredStringValidator } from '@mwl/core-lib';

import type { FormRecoveryLinkValues } from './FormRecoveryLink.types';

export const formSettings: FormSettings<FormRecoveryLinkValues> = {
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      minLength: { validate: minLengthValidator(minPasswordLength), message: 'error' },
    },
    value: '',
    relatedFields: ['confirm'],
  },
  confirm: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      minLength: { validate: minLengthValidator(minPasswordLength), message: 'error' },
      compare: { validate: (value, state) => state?.password === value, message: 'error' },
    },
    value: '',
  },
};
