import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
import type { PaymentHistoryItem, PaymentItem } from '@mwl/core-lib';

import type { SelectedPaymentItem } from '@/types';

export type WithdrawalPopupType = 'error' | 'warning' | 'success' | 'confirmation' | 'userIsFrozen';

export interface WithdrawalPopup {
  type: WithdrawalPopupType;
  title?: string;
  message?: string;
  buttonText?: string;
  confirmation?: PaymentHistoryItem;
}

export interface WithdrawalContextProps {
  popup?: WithdrawalPopup;
  payment?: SelectedPaymentItem;
  changePayment(paymentMethod?: PaymentItem, index?: number): void;
  openPopup(value: WithdrawalPopup): void;
  closePopup(): void;
}

export const WithdrawalContext = createContext<WithdrawalContextProps>({} as WithdrawalContextProps);

export const useWithdrawal = (): WithdrawalContextProps => useContext(WithdrawalContext);

export const useProvideWithdrawal = (): WithdrawalContextProps => {
  const [selectedPayment, setSelectedPayment] = useState<SelectedPaymentItem>();
  const [popup, setPopup] = useState<WithdrawalPopup>();

  const changePayment = useCallback((method?: PaymentItem, paymentIndex?: number) => {
    setSelectedPayment((prev) => {
      if (!method || paymentIndex === undefined || method.identity === prev?.method.identity) {
        return undefined;
      }

      return { id: paymentIndex, method };
    });
  }, []);

  const openPopup = useCallback((value: WithdrawalPopup) => {
    setPopup(value);
  }, []);

  const closePopup = useCallback(() => {
    setPopup(undefined);
  }, []);

  return { payment: selectedPayment, changePayment, popup, openPopup, closePopup };
};

export const WithdrawalProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useProvideWithdrawal();

  return <WithdrawalContext.Provider value={value}>{children}</WithdrawalContext.Provider>;
};
