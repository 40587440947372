import type { FormSettings } from '@mwl/core-lib';
import { requiredValidator } from '@mwl/core-lib';

import type { ThirdStepRegistrationState, ThirdStepRegistrationValues } from './ThirdStepRegistration.types';

export const initialState: ThirdStepRegistrationState = {
  error: null,
  isLoading: false,
  isSuccess: false,
};

export const formSettings: FormSettings<ThirdStepRegistrationValues> = {
  age: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  playingForYourOwnMoney: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  firstAndOnlyRegistration: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  termsAndConditions: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  declareInformation: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  awareLimitations: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
  gamblingWebsite: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: false,
  },
};
