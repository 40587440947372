import type { FC, PropsWithChildren } from 'react';
import { TabsBase } from '@mwl/ui';

import type { TabsProps } from './Tabs.types';

import styles from './Tabs.module.scss';

const Tabs: FC<PropsWithChildren<TabsProps>> = ({ children, className }) => {
  return <TabsBase className={cn(styles.tabs, className)}>{children}</TabsBase>;
};

export { Tabs };
