export const translates = {
  login: [
    'recovery.description.login',
    'To recover your password, enter your e-mail or phone number used during registration',
  ],
  email: ['recovery.description.email', 'An email with a confirmation code has been sent to {{login}}'],
  phone: ['recovery.description.phone', 'A phone with a confirmation code has been sent to {{login}}'],
  timerError: [
    'recovery.description.error',
    'Password recovery attempts have been exhausted. Try again after the timer expires.',
  ],
};
