import { calcTimerEndDate } from '@mwl/core-lib';

import type { ChangeFormPayload, ConfirmationPopupState } from './ConfirmationPopup.types';

interface ChangeForm {
  type: 'CHANGE_FORM';
  payload: ChangeFormPayload;
}

interface SuccessCancelling {
  type: 'SUCCESS_CANCELLING';
}

interface ChangeTimer {
  type: 'CHANGE_TIMER';
  payload?: number | null;
}

interface ResetState {
  type: 'RESET_STATE';
}

type Actions = ChangeForm | ChangeTimer | ResetState | SuccessCancelling;

export const initialState: ConfirmationPopupState = {
  form: null,
  isSuccess: false,
  timer: null,
};

const changeForm = (
  state: ConfirmationPopupState,
  { form, timer, ...params }: ChangeFormPayload,
): ConfirmationPopupState => {
  return {
    ...state,
    ...params,
    form,
    timer: timer === undefined ? state.timer : calcTimerEndDate(timer),
  };
};

export const reducer = (state: ConfirmationPopupState, action: Actions): ConfirmationPopupState => {
  switch (action.type) {
    case 'CHANGE_FORM':
      return changeForm(state, action.payload);

    case 'SUCCESS_CANCELLING':
      return { ...state, isSuccess: true };

    case 'CHANGE_TIMER':
      return { ...state, timer: calcTimerEndDate(action.payload ?? null) };

    case 'RESET_STATE':
      return { ...initialState };

    default:
      return state;
  }
};
