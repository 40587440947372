import type { FC } from 'react';
import { memo } from 'react';
import { FormMessageBase } from '@mwl/ui';

import type { FromMessageProps } from './FormMessage.types';

import styles from './FormMessage.module.scss';

const BaseFormMessage: FC<FromMessageProps> = ({ className, children, variant = 'error', ...rest }) => {
  return (
    <FormMessageBase className={cn(styles.root, styles[`${variant}Variant`], className)} align="center" {...rest}>
      {children}
    </FormMessageBase>
  );
};

export * from './FormMessage.types';
const FormMessage = memo(BaseFormMessage);
export { FormMessage };
