import { useCallback, useState } from 'react';
import { loadHistoryRequest } from '@mwl/core-lib';

import { useTypedDispatch } from '@/hooks';

import type { MessageConfirmCancellingState } from './MessageConfirmCancelling.types';

const initialState: MessageConfirmCancellingState = {
  form: 'CANCEL_CONFIRM',
  error: null,
};

export const useConfirmationCancellingState = () => {
  const dispatch = useTypedDispatch();

  const [state, setState] = useState<MessageConfirmCancellingState>(initialState);

  const handleCancellingSuccess = useCallback(() => {
    dispatch(loadHistoryRequest());
    setState((prev) => ({ ...prev, form: 'CANCEL_SUCCESS' }));
  }, [dispatch]);

  const handleCancellingError = useCallback(
    (error: string | Array<string> | null) => setState((prev) => ({ ...prev, form: 'CANCEL_ERROR', error })),
    [],
  );

  return { ...state, handleCancellingSuccess, handleCancellingError };
};
