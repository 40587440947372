import { complex } from '@mwl/core-lib';

import { aboutPageSchema } from '@/utils/markerTree/aboutPage';
import { privacyPolicyPageSchema } from '@/utils/markerTree/privacyPolicy';

import { appPageSchema } from './appPage';
import { casinoGamePageSchema } from './casinoGamePage';
import { casinoPageSchema } from './casinoPage';
import { commonSchema } from './common';
import { contactsPageSchema } from './contactsPage';
import { errorPageSchema } from './errorPage';
import { faqPageSchema } from './faqPage';
import { favoritesPageSchema } from './favorites';
import { globalPopupsSchema } from './globalPopups';
import { liveCasinoPageSchema } from './liveCasino';
import { loyaltyPageSchema } from './loyaltyPage';
import { mainPageSchema } from './mainPage';
import { incidentsPageSchema, profilePageSchema } from './profile';
import { promoSchema } from './promo';
import { promotionRulesPageSchema } from './promotionRulesPage';
import { providersPageSchema } from './providersPage';
import { rulesPageSchema } from './rulesPages';
import { storePageSchema } from './store';
import { tournamentsPageSchema } from './tournamentsPage';

const rootObjectSchema = complex({
  appPage: appPageSchema,
  loyaltyPage: loyaltyPageSchema,
  profilePage: profilePageSchema,
  favoritesPage: favoritesPageSchema,
  mainPage: mainPageSchema,
  common: commonSchema,
  incidentsPage: incidentsPageSchema,
  faqPage: faqPageSchema,
  storePage: storePageSchema,
  promo: promoSchema,
  about: aboutPageSchema,
  casinoPage: casinoPageSchema,
  liveCasinoPage: liveCasinoPageSchema,
  promotionRulesPage: promotionRulesPageSchema,
  privacyPolicyPage: privacyPolicyPageSchema,
  popups: globalPopupsSchema,
  casinoGamePage: casinoGamePageSchema,
  providersPage: providersPageSchema,
  tournamentsPage: tournamentsPageSchema,
  rulesPage: rulesPageSchema,
  contactsPage: contactsPageSchema,
  errorPage: errorPageSchema,
});

export const rootObject = rootObjectSchema('root');

export const appPageObject = rootObject.appPage;
export const headerObject = appPageObject.header;
export const faqPageObject = rootObject.faqPage;
export const formLoginObject = appPageObject.formLogin;
export const incidentsPageObject = rootObject.incidentsPage;
export const profilePageObject = rootObject.profilePage;
export const storePageObject = rootObject.storePage;
export const promoObject = rootObject.promo;
export const casinoPageObject = rootObject.casinoPage;
export const liveCasinoPageObject = rootObject.liveCasinoPage;
export const promotionRulesPageObject = rootObject.promotionRulesPage;
export const privacyPolicyPageObject = rootObject.privacyPolicyPage;
export const popupsObject = rootObject.popups;
export const registerObject = appPageObject.register;
export const formRecoveryLoginObject = appPageObject.formRecoveryLogin;
export const formRecoveryCodeObject = appPageObject.formRecoveryCode;
export const formRecoveryPasswordObject = appPageObject.formRecoveryPassword;
export const formRecoverySuccessObject = appPageObject.formRecoverySuccess;
export const casinoLayoutObject = appPageObject.casinoLayout;
export const casinoGamePageObject = rootObject.casinoGamePage;
export const providersPageObject = rootObject.providersPage;
export const commonObject = rootObject.common;
export const mainPageObject = rootObject.mainPage;
export const tournamentsPageObject = rootObject.tournamentsPage;
export const aboutPageObject = rootObject.about;
export const favoritesPageObject = rootObject.favoritesPage;
export const userMenuObject = appPageObject.userMenu;
export const loyaltyPageObject = rootObject.loyaltyPage;
export const rulesPageObject = rootObject.rulesPage;
export const contactsPageObject = rootObject.contactsPage;
export const errorPageObject = rootObject.errorPage;
