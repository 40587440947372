import type { ChangeEventHandler } from 'react';
import { useCallback } from 'react';
import {
  fullRegistration,
  loginSuccess,
  registerSuccess,
  setRecoveryLogin,
  useForm,
  useQueryPopupOpen,
  useSubmitFormAuth,
} from '@mwl/core-lib';

import { useTypedDispatch } from '@/hooks';

import type { FormRegistrationFullData } from '../../FormRegistrationFull.types';

import type { ThirdStepRegistrationValues, UseHandleChangeProps } from './ThirdStepRegistration.types';
import { formSettings } from './ThirdStepRegistration.utils';

interface UseThirdStepRegistrationFormParams {
  formData?: FormRegistrationFullData;
}

export function useThirdStepRegistrationForm({ formData }: UseThirdStepRegistrationFormParams) {
  const dispatch = useTypedDispatch();

  const { onOpenPopup } = useQueryPopupOpen();

  const { changeField, formState, handleSubmit } = useForm<ThirdStepRegistrationValues>(formSettings);

  const {
    state: { error, isLoading },
    onSubmitConfirm,
  } = useSubmitFormAuth({
    requestHandler: fullRegistration,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.token) {
        dispatch(registerSuccess());
        dispatch(loginSuccess({ token: data.token }));
        onOpenPopup({ queryName: 'deposit' });
      }
    },
    onError: (formError) => {
      if (!formData) {
        return;
      }

      if (formError.type === '/phone-already-exists') {
        dispatch(setRecoveryLogin(formData.phone));
        onOpenPopup({ queryName: 'reset_password' });
      }

      if (formError.type === '/email-already-exists') {
        dispatch(setRecoveryLogin(formData.email));
        onOpenPopup({ queryName: 'reset_password' });
      }
    },
  });

  const submitConfirm = () => {
    if (formData) {
      onSubmitConfirm(formData);
    }
  };

  return {
    changeField,
    formState,
    isLoading,
    error,
    handleSubmit: handleSubmit(submitConfirm),
  };
}

export const useHandleChange = ({ changeField, formState }: UseHandleChangeProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, checked } = event.target;

      const typedName = name as keyof ThirdStepRegistrationValues;

      changeField(typedName, checked);
    },
    [changeField],
  );

  const handleChangeAll: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { checked } = event.target;

      Object.keys(formState.values).forEach((typedName) => {
        changeField(typedName as keyof ThirdStepRegistrationValues, checked);
      });
    },
    [changeField, formState.values],
  );

  return { handleChange, handleChangeAll };
};
