import type { ChangeEventHandler } from 'react';
import { useCallback } from 'react';
import type { UpdateFieldOptions } from '@mwl/core-lib';
import { removeAllSpaces } from '@mwl/core-lib';
import type { SelectOption } from '@mwl/ui';

import type { FormFieldPhoneOnChange, FormFieldPhoneOnSelect } from '@/components/FormFieldPhone/FormFieldPhone.types';
import { useRegister } from '@/context';

import type { RegistrationCountryOption, RegistrationPhoneBaseFormValues, RegistrationPhoneOption } from '@/types';

import type { UseRegistrationPhoneHandlersParams } from './useRegistrationPhoneHandlers.types';

export function useRegistrationPhoneHandlers<T extends RegistrationPhoneBaseFormValues>({
  changeField,
  countries,
  codes,
}: UseRegistrationPhoneHandlersParams<T>) {
  const { updateCurrency } = useRegister();

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value, checked, type } = event.target;

      const typedName = name as keyof T;
      const typedValue = (type === 'checkbox' ? checked : removeAllSpaces(value)) as T[keyof T];

      changeField(typedName, typedValue);
    },
    [changeField],
  );

  const handlePhoneChange: FormFieldPhoneOnChange = useCallback((value) => changeField('phone', value), [changeField]);

  const handleSelectCode: FormFieldPhoneOnSelect<RegistrationPhoneOption> = useCallback(
    (option) => {
      changeField('code', option);

      const country = countries.find((item) => item.value === option.country);

      if (country) {
        changeField('country', country);
      }
    },
    [changeField, countries],
  );

  const handleSelectCurrency = useCallback(
    (option: SelectOption, options?: UpdateFieldOptions) => {
      updateCurrency(option.value);
      changeField('currency', option, options);
    },
    [changeField, updateCurrency],
  );

  const handleSelectCountry = useCallback(
    (option: RegistrationCountryOption) => {
      changeField('country', option);
      const code = codes.find((item) => item.country === option.value);

      if (code) {
        changeField('code', code);
      }
    },
    [changeField, codes],
  );

  return { handleSelectCurrency, handleSelectCountry, handleSelectCode, handlePhoneChange, handleChange };
}
