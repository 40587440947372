import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import type { Modifier, Obj } from '@popperjs/core';

import IncorrectIcon from '@public/assets/common/icons/incorrect.svg';

import { Tooltip } from '@/components/Tooltip/Tooltip';

import type { FormFieldTooltipProps } from './FormFieldTooltip.types';

import styles from './FormFieldTooltip.module.scss';

const customArrowModifier: Modifier<'customArrow', Obj> = {
  name: 'customArrow',
  enabled: true,
  phase: 'write',
  fn({ state }) {
    const arrowElement = state.elements.arrow;

    if (arrowElement) {
      arrowElement.style.transform = 'translateX(15px)';
    }
  },
};

const FormFieldTooltip: FC<PropsWithChildren<FormFieldTooltipProps>> = ({
  isError,
  errorText,
  defaultText,
  isShow,
  children,
  className,
  onClickOutside,
  onClose,
  tooltipRef,
}) => {
  const isMountTooltip = !!defaultText || (!!errorText && isError);

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.inner}>{children}</div>

      {isMountTooltip && (
        <Tooltip
          reference={tooltipRef}
          visible={isShow}
          onClickOutside={onClickOutside}
          contentVariant="custom"
          content={
            <div className={cn(styles.content, isError && styles.hasError)}>
              {!!isError && (
                <div className={styles.errorIcon}>
                  <IncorrectIcon width={12} height={12} onClick={onClose} />
                </div>
              )}
              {isError ? errorText : defaultText}
            </div>
          }
          variant={isError ? 'error' : 'arrow'}
          placement="bottom-start"
          popperOptions={{
            modifiers: [
              customArrowModifier,
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export * from './FormFieldTooltip.types';
export * from './FormFieldTooltip.hooks';
export { FormFieldTooltip };
