import { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import {
  loadRefillRequest,
  selectRefillMethods,
  selectRefillMethodsIsLoading,
  selectUserCurrency,
  selectUserIsAuthenticated,
} from '@mwl/core-lib';

import { useTypedDispatch, useTypedSelector } from '@/hooks';

import { formatRefillMethods } from './usePayloadDeposit.utils';

export const usePayloadDeposit = () => {
  const { t } = useTranslation('payment');

  const dispatch = useTypedDispatch();

  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const methods = useTypedSelector(selectRefillMethods);
  const isLoading = useTypedSelector(selectRefillMethodsIsLoading);
  const currency = useTypedSelector(selectUserCurrency);

  const refillList = useMemo(() => formatRefillMethods(methods, t), [methods, t]);

  useEffect(() => {
    if (currency && isAuth) {
      dispatch(loadRefillRequest());
    }
  }, [currency, dispatch, isAuth]);

  return { methods: refillList, isLoading };
};
