import type { FC } from 'react';
import type { CountdownProps } from 'react-countdown';
import ReactCountdown from 'react-countdown';
import { NoSSR } from '@mwl/ui';

const Countdown: FC<CountdownProps> = (props) => {
  return (
    <NoSSR>
      <ReactCountdown {...props} />
    </NoSSR>
  );
};

export type { CountdownProps, ReactCountdown };
export { Countdown };
