import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { payoutCancelSuccessKey } from '@mwl/core-lib';

import { Message } from '@/components/Message/Message';

import type { MessageSuccessCancellingProps } from './MessageSuccessCancelling.types';

export const MessageSuccessCancelling: FC<MessageSuccessCancellingProps> = ({ className, onClick }) => {
  const { t } = useTranslation('payment');

  return (
    <Message className={className} textColor="yellow" status="success" onClick={onClick}>
      {t(payoutCancelSuccessKey)}
    </Message>
  );
};
