export const widgetIds: Dictionary<string> = {
  uz: 'iW9tDrXooV',
  cz: 'Q1qlMB4ZFQ',
  cs_cz: 'Q1qlMB4ZFQ',
  fi: 'ghjQsev892',
  kz: 'wpFyksSwDs',
  ru_kz: 'wpFyksSwDs',
  kk_kz: 'wpFyksSwDs',
  pk: 'ByrO09lbAh',
  ur_pk: 'ByrO09lbAh',
  ur: 'ByrO09lbAh',
  np: 'YtAW8e1ppD',
  ne_np: 'YtAW8e1ppD',
  ne: 'YtAW8e1ppD',
  pl: 'aKgo61lKQR',
  bd: '1kmibzA5W1',
  bn_bd: '1kmibzA5W1',
  en: 'IJz5wBK8K7',
  ru: 'VGcM86cgo1',
  hi: 'KiTKy7CYjg',
};
