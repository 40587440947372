import { useEffect } from 'react';
import {
  loadCasinoProvidersRequest,
  selectCasinoProvidersError,
  selectCasinoProvidersIsLoaded,
  selectCasinoProvidersIsLoading,
  selectCasinoProvidersList,
  selectUserCurrency,
} from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks/useTypedSelector';

import { useTypedDispatch } from './useTypedDispatch';

const useCasinoProviders = () => {
  const dispatch = useTypedDispatch();

  const currency = useTypedSelector(selectUserCurrency);
  const providers = useTypedSelector(selectCasinoProvidersList);
  const isLoaded = useTypedSelector(selectCasinoProvidersIsLoaded);
  const isLoading = useTypedSelector(selectCasinoProvidersIsLoading);
  const error = useTypedSelector(selectCasinoProvidersError);

  useEffect(() => {
    if (currency) {
      dispatch(loadCasinoProvidersRequest());
    }
  }, [currency, dispatch]);

  return {
    providers,
    isLoaded,
    isLoading,
    error,
  };
};

export { useCasinoProviders };
