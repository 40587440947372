import type { FC } from 'react';
import { memo } from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import type { MetaData } from '@mwl/core-lib';

export interface MetaProps extends MetaData {}

const data: Required<MetaProps> = {
  title: ['title.default', 'Banzai'],
  description: ['description.default', 'Online games for you'],
  metaVariables: {},
};

const BaseMeta: FC<MetaProps> = ({
  title = data.title,
  description = data.description,
  metaVariables = data.metaVariables,
}) => {
  const { t } = useTranslation('seo');

  const metaTitle = t(title, metaVariables);
  const metaDescription = t(description, metaVariables);

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta property="og:title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
    </Head>
  );
};

export const Meta = memo(BaseMeta);
