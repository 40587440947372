import type { FC } from 'react';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { TranslationKey } from '@mwl/core-lib';
import { FileUploader } from '@mwl/ui';

import CloseIcon from '@public/assets/common/icons/close.svg';

import { Button } from '@/components/Button/Button';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';

import { uploadError } from './PaymentUploadField.data';
import type { PaymentUploadFieldProps } from './PaymentUploadField.types';
import { generateFormData, getDisplayMaxSize, upload, validateFile } from './PaymentUploadField.utils';

import styles from './PaymentUploadField.module.scss';

const BasePaymentUploadField: FC<PaymentUploadFieldProps> = ({
  value,
  onChange,
  allowedExtensions,
  attr,
  className,
  title,
  fileName,
  uploadingPath,
  customRequiredFormDataFields,
  storageEncodedFormData,
  isError,
  hint,
}) => {
  const { t } = useTranslation('payment');

  const [uploadedFileName, setUploadedFileName] = useState('');
  const [localError, setLocalError] = useState<TranslationKey>('');
  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = async (file: File) => {
    if (uploadingPath && fileName) {
      setIsLoading(true);

      const formData = generateFormData({ file, customRequiredFormDataFields, storageEncodedFormData, fileName });

      const { status } = await upload(uploadingPath, formData);

      if (status && status >= 200 && status < 300) {
        setUploadedFileName(file.name);
        onChange(`${uploadingPath}/${fileName}`);
      } else {
        setLocalError(uploadError);
      }

      setIsLoading(false);
    }
  };

  const handleChange = (file: File | null) => {
    if (!file && !value) {
      return;
    }

    setLocalError('');
    onChange('');

    if (!file) {
      return;
    }

    const validationError = validateFile({ file, attr, allowedExtensions });

    if (validationError) {
      setLocalError(validationError);
      return;
    }

    uploadFile(file);
  };

  const displayMaxSize = useMemo(() => getDisplayMaxSize(attr), [attr]);

  return (
    <FileUploader onChange={handleChange} accept={allowedExtensions?.toString()}>
      {({ onClick, reset }) => (
        <div className={cn(styles.root, className, { [styles.invalidField]: isError || localError })}>
          <div className={styles.row}>
            <Button
              className={styles.button}
              as="button"
              variant="fulfilled"
              color="grey"
              onClick={onClick}
              rounded
              type="button"
              disabled={isLoading}
              align="left"
              uppercase={false}
              cut={false}
            >
              {title ? t(title) : t('upload_field.base_title', 'Upload file')}
            </Button>
            <p className={styles.text}>
              {!value ? t('upload_field.no_selected', 'File not selected') : uploadedFileName}
            </p>
            {!!value && !isLoading && (
              <ButtonIcon
                className={styles.closeButton}
                as="button"
                type="button"
                size="xs"
                onClick={reset}
                aria-label="payment uploader close"
              >
                <CloseIcon />
              </ButtonIcon>
            )}
          </div>

          {(!!allowedExtensions?.length || displayMaxSize) && !hint && (
            <div className={styles.description}>
              {allowedExtensions?.length && (
                <span>
                  {t('upload_field.allowed_extensions', 'Available formats:')} {allowedExtensions.join(', ')}
                  {'. '}
                </span>
              )}
              {displayMaxSize ? `${t('upload_field.max_size', 'Max size:')} ${displayMaxSize}` : ''}
            </div>
          )}

          {!!hint && <div className={styles.description}>{t(hint)}</div>}

          {localError && <div className={styles.error}>{t(localError)}</div>}
        </div>
      )}
    </FileUploader>
  );
};

export const PaymentUploadField = memo(BasePaymentUploadField);
