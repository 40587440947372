import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { Analytics } from '@mwl/core-lib';
import { extendAnalyticsData, sendAnalyticsData, useSwitchValue } from '@mwl/core-lib';

import PromoCodeCheckIcon from '@public/assets/common/icons/promoCodeCheck.svg';

import { Button } from '@/components/Button/Button';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import type { FormFieldAsInput } from '@/components/FormField/FormField';
import { FormField } from '@/components/FormField/FormField';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import type { TippyRef } from '@/components/Tooltip/Tooltip.types';

import styles from './PromoCodeField.module.scss';

export interface PromoCodeFieldProps extends Omit<FormFieldAsInput, 'as' | 'analytics'> {
  analytics?: Analytics;
}

export const PromoCodeField: FC<PromoCodeFieldProps> = ({ value, className, analytics, ...props }) => {
  const { t } = useTranslation('common');

  const inputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const tippyRef = useRef<TippyRef>(null);

  const [isActive, setActive] = useState(false);
  const [isApplied, setApplied] = useState(false);
  const { value: isTooltipVisible, on: showTooltip, off: hideTooltip } = useSwitchValue(false);

  const handleBlur = () => {
    if (!value) {
      setActive(false);
    }

    sendAnalyticsData(analytics?.change?.eventName, { ...(analytics?.change?.data || {}), promocode: value });
  };

  const applyPromo = useCallback(() => {
    setApplied(true);
    showTooltip();
  }, [showTooltip]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isTooltipVisible) {
      timeoutId = setTimeout(() => {
        hideTooltip();
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [hideTooltip, isTooltipVisible]);

  useEffect(() => {
    const inputEl = inputRef.current;
    const submitButtonEl = submitButtonRef.current;

    if (submitButtonEl && inputEl) {
      const buttonPadding = 19;
      const submitButtonWidth = submitButtonEl.offsetWidth + buttonPadding;

      inputEl.style.paddingRight = `${submitButtonWidth}px`;
    }

    return () => {
      if (inputEl) {
        inputEl.style.paddingRight = '';
      }
    };
  }, [isActive]);

  const renderPostfix = () => {
    if (!isActive) {
      return null;
    }

    if (isApplied) {
      return (
        <ButtonIcon
          as="button"
          type="button"
          className={styles.appliedIconBtn}
          onClick={showTooltip}
          aria-label="promo code check"
        >
          <PromoCodeCheckIcon width={20} height={20} />
        </ButtonIcon>
      );
    }

    return (
      <Button
        as="button"
        ref={submitButtonRef}
        type="button"
        variant="text"
        onClick={applyPromo}
        disabled={!value}
        className={styles.applyBtn}
        fontWeight="bold"
        analytics={extendAnalyticsData(analytics, { promocode: value })}
      >
        {t('auth.promoCode.ok', 'OK')}
      </Button>
    );
  };

  useEffect(() => {
    setApplied(false);
  }, [value]);

  return (
    <Tooltip
      variant="arrow"
      content={t('auth.promoCode.applied', 'Promo code applied')}
      appendTo="parent"
      zIndex={2}
      visible={isTooltipVisible}
      onClickOutside={hideTooltip}
      placement="bottom"
      className={cn(styles.root, className)}
      tippyRef={tippyRef}
    >
      <FormField
        as="input"
        ref={inputRef}
        placeholder={t('auth.promoCode.havePromoCode', 'I have a promo code')}
        classes={{ component: styles.input }}
        className={cn(styles.field, isApplied && styles.appliedField)}
        onFocus={() => setActive(true)}
        onBlur={handleBlur}
        postfix={renderPostfix()}
        value={value}
        autoComplete="off"
        analytics={analytics}
        {...props}
      />
    </Tooltip>
  );
};
