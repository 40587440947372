import dynamic from 'next/dynamic';

import { Loader } from '@/components/Loader/Loader';

const DatepickerDynamic = dynamic(
  async () => {
    const result = await import('../Datepicker/Datepicker');
    return result.Datepicker;
  },
  {
    loading: () => <Loader />,
    ssr: false,
  },
);

export { DatepickerDynamic };
