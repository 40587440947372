import { useEffect, useMemo } from 'react';

import { useTypedDispatch } from '@/hooks/useTypedDispatch';
import { togglePopupRegistrationBonus } from '@/store/features/ui/actions';

export interface UseTogglePopupRegistrationBonusParams<TValue> {
  fieldsValues: Array<TValue>;
}

/**
 * Хук, который необходим для проверки изменились ли поля в формах авторизации/регистрации,
 * чтобы показывать popup: Зарегистрируйтесь, чтоб получить бонус...
 * @param fieldsValues {Array} - Массив из значений формы
 */
export const useTogglePopupRegistrationBonus = <TValue>({
  fieldsValues,
}: UseTogglePopupRegistrationBonusParams<TValue>) => {
  const dispatch = useTypedDispatch();

  const isSomeDirty = useMemo(() => fieldsValues.some((item) => !!item), [fieldsValues]);

  useEffect(() => {
    if (isSomeDirty) {
      dispatch(togglePopupRegistrationBonus(false));
    } else {
      dispatch(togglePopupRegistrationBonus(true));
    }
  }, [dispatch, isSomeDirty]);
};
