import type { FormSettings } from '@mwl/core-lib';
import { requiredValidator } from '@mwl/core-lib';

import type { FormRegistrationSnValues } from './FormRegistrationSn.types';

export const formSettings: FormSettings<FormRegistrationSnValues> = {
  currency: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '' },
  },
  promoCode: {
    value: '',
  },
  agree: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: true,
  },
};
