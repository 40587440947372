import { useMemo } from 'react';
import type { LinesCountByStageSB } from '@mwl/core-lib';

import { useSportUrlParams } from './useSportUrlParams/useSportUrlParams';

interface UseSportItemsCountParams {
  linesCount: Array<LinesCountByStageSB>;
  linesCountTotal: number;
}

/**
 * Хук для правильного указания количества линий в виде спорта / категории / чемпионате
 * @param linesCount - Количество линий с группировкой по stage
 * @param linesCountTotal - Общее количество линий
 * @returns Количество линий для текущего стейджа, либо общее количество при его отсутствии
 */
export const useSportItemsCount = ({ linesCount, linesCountTotal }: UseSportItemsCountParams) => {
  const { stage } = useSportUrlParams();

  const count = useMemo(() => {
    if (!stage) {
      return linesCountTotal;
    }

    const countByStage = linesCount.find((countItem) => countItem.stage === stage);

    if (!countByStage) {
      return linesCountTotal;
    }

    return countByStage.count;
  }, [stage, linesCountTotal, linesCount]);

  return count;
};
