import { useRouter } from 'next/router';

import { routes } from '@/constants';

/**
 * Хук возвращает true если текущая страница - главная страница
 */
export const useIsMainPage = (): boolean => {
  const { pathname } = useRouter();
  return pathname === routes.home;
};
