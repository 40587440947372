import type { TFunction } from 'next-i18next';
import type { PaymentItem, PaymentMethod } from '@mwl/core-lib';
import { findPaymentData } from '@mwl/core-lib';

export const formatRefillMethods = (methods: Array<PaymentMethod>, t: TFunction): Array<PaymentItem> => {
  if (!methods.length) {
    return [];
  }

  return methods.map<PaymentItem>(({ title, identity, form, type, commission }) => {
    const { max, min } = findPaymentData(form.fieldList);

    return {
      title,
      identity,
      type,
      min: min ? parseFloat(min) : undefined,
      max: max ? parseFloat(max) : undefined,
      commission,
      fieldList: form.fieldList,
      method: form.method,
      action: form.action,
      groupList: form.groupList,
      card: {
        image: form.logo ?? '',
        footer: t<string>(title),
      },
    };
  });
};
