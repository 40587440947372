import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { onlyNumbers, useForm, useSubmitForm, validateConfirmationCodeV2 } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';
import { FormField } from '@/components/FormField/FormField';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { CODE_LENGTH } from '@/constants';
import { formRecoveryCodeObject } from '@/utils';

import { useRecoveryState } from '../../FormRecovery.context';
import { ButtonSendCodeAgain } from '../ButtonSendCodeAgain/ButtonSendCodeAgain';

import { countAttempts, translationKeys } from './FormRecoveryCode.data';
import { useSendCode } from './FormRecoveryCode.hooks';
import type { FormRecoveryCodeProps, FormRecoveryCodeValues } from './FormRecoveryCode.types';
import { formSettings } from './FormRecoveryCode.utils';

import styles from './FormRecoveryCode.module.scss';

const BaseFormRecoveryCode: FC<FormRecoveryCodeProps> = ({ className, onChangeTab }) => {
  const { t } = useTranslation('common');

  const [count, setCount] = useState(countAttempts);

  const { timer, login, error: stateError, onChangeState } = useRecoveryState();

  const { formState, changeField } = useForm<FormRecoveryCodeValues>(formSettings);

  const { state, onSubmitConfirm } = useSubmitForm({
    requestHandler: validateConfirmationCodeV2,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.codeId && data?.result === 'SUCCESS') {
        onChangeState({ codeId: data.codeId, error: null });
        onChangeTab('password');
      }

      if (data?.result === 'ERROR') {
        onChangeState({ codeId: data.codeId, timer: data.timer, error: data?.error });
      }

      if (data?.code === 'REQUEST_CODE_FROZEN_PERIOD') {
        setCount((prev) => prev - 1);
      }
    },
    onError: (error) => {
      if (error.type === '/verification-code-failed') {
        setCount((prev) => prev - 1);
      }
    },
  });

  const { error, isLoading, isSuccess, requestCode } = useSendCode(login, formState.values);

  const description = useMemo(() => {
    if (count && count < countAttempts) {
      return t(translationKeys.attempts, { count });
    }

    if (count === 0) {
      return t(translationKeys.attemptsFinish);
    }

    return '';
  }, [count, t]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;

      const code = onlyNumbers(value).slice(0, CODE_LENGTH);

      changeField(name as keyof FormRecoveryCodeValues, code);

      if (code !== formState.values.code && code.length === CODE_LENGTH && count > 0) {
        onSubmitConfirm({ code });
      }
    },
    [formState.values.code, count, changeField, onSubmitConfirm],
  );

  const handleResetTimer = useCallback(() => onChangeState({ timer: null }), [onChangeState]);

  const handleClose = useCallback(() => onChangeTab('login'), [onChangeTab]);

  useEffect(() => {
    if (isSuccess) {
      setCount(countAttempts);
    }
  }, [isSuccess]);

  return (
    <form
      {...formRecoveryCodeObject.nodeProps}
      className={cn(styles.root, className)}
      onSubmit={(event) => event.preventDefault()}
    >
      {(state.error || error || stateError) && (
        <FormMessage className={styles.message}>
          {error && (Array.isArray(error.message) ? t(error.message) : error.message)}
          {state.error && (Array.isArray(state.error.message) ? t(state.error.message) : state.error.message)}
          {stateError && t(stateError)}
        </FormMessage>
      )}
      {(state.isLoading || isLoading) && <Loader />}
      <FormField
        {...formRecoveryCodeObject.codeField.nodeProps}
        autoCapitalize="off"
        autoComplete="one-time-code"
        inputMode="numeric"
        as="input"
        placeholder={t('form.field.verification_code', 'Verification code')}
        value={formState.values.code}
        name="code"
        onChange={handleChange}
        className={styles.field}
        isError={Boolean((formState.isTouched && formState.errors.code) || stateError)}
      />
      {description && <div className={cn(styles.description)} dangerouslySetInnerHTML={{ __html: description }} />}
      <ButtonSendCodeAgain
        {...formRecoveryCodeObject.sendAgainButton.nodeProps}
        timer={timer}
        sendRequest={requestCode}
        onComplete={handleResetTimer}
        className={styles.button}
      />
      <Button
        as="button"
        color="grey"
        size="md"
        fontSize="xl"
        fontWeight="bold"
        fullWidth
        className={styles.button}
        type="button"
        onClick={handleClose}
        rounded
      >
        {t('button.cancel', `Cancel`)}
      </Button>
    </form>
  );
};

const FormRecoveryCode = memo(BaseFormRecoveryCode);

export * from './FormRecoveryCode.types';
export { FormRecoveryCode };
