const namespaces = {
  common: true,
  casino: true,
  liveCasino: true,
  promo: true,
  sport: true,
  notification: true,
  loyalty: true,
  poker: true,
  wheel: true,
  download: true,
  deposit: true,
  payment: true,
  rules: true,
  promotionrules: true,
  privacypolicy: true,
  contacts: true,
  profile: true,
  providers: true,
  promotionRules: true,
  error: true,
  bonuses: true,
};

module.exports = {
  ns: Object.keys(namespaces),
  namespaces,
};
