import { useCallback, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import type { UseAdditionalNavProps } from './useAdditionalNav.types';

/**
 * Возвращает два списка ссылок в зависимости от размера экрана.
 * @param links - Список ссылок.
 * @param containerRef - Ссылка на родительский элемент.
 * @param shadowRef - Ссылка на родительский элемент.
 * @param numberRemovedLinks - Номер удаленных ссылок.
 * @param offsettingWidth - Ширина ссылки при отсутствии родительского элемента.
 * @returns Максимальная высота элемента.
 */

export const useAdditionalNav = <T>({
  links,
  containerRef,
  shadowRef,
  numberRemovedLinks = 0,
  offsettingWidth = 40,
}: UseAdditionalNavProps<T>) => {
  const [visibleLinks, setVisibleLinks] = useState(links);
  const [otherLinks, setOtherLinks] = useState<typeof links>([]);

  const calcVisibleLinks = useCallback(() => {
    if (!containerRef.current || !shadowRef.current) {
      return;
    }

    const { clientWidth } = containerRef.current;
    const { children } = shadowRef.current;
    let visibleLinksWidth = 0;
    let countVisibleLinks = links.length;

    for (let i = 0; i < children.length; i += 1) {
      const linkElWidth = (children[i] as HTMLElement).offsetWidth;

      if (linkElWidth + visibleLinksWidth < clientWidth - offsettingWidth) {
        visibleLinksWidth += linkElWidth;
      } else {
        countVisibleLinks = i - numberRemovedLinks;
        break;
      }
    }

    setVisibleLinks(links.slice(0, countVisibleLinks));
    setOtherLinks(links.slice(countVisibleLinks));
  }, [containerRef, links, numberRemovedLinks, shadowRef, offsettingWidth]);

  useEffect(() => {
    const onResize = () => {
      calcVisibleLinks();
    };

    const resizeObserver = new ResizeObserver(onResize);

    const linksContainerEl = containerRef.current;

    if (linksContainerEl) {
      resizeObserver.observe(linksContainerEl);
    }

    onResize();

    return () => {
      if (linksContainerEl) {
        resizeObserver.unobserve(linksContainerEl);
      }
    };
  }, [links, calcVisibleLinks, containerRef]);

  return {
    visibleLinks,
    otherLinks,
  };
};
