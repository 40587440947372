import { isMobile } from 'react-device-detect';
import type { SwiperProps } from 'swiper/react';

export const swiperProps: SwiperProps = {
  slidesPerView: 'auto',
  updateOnWindowResize: true,
  observer: true,
  observeParents: true,
  navigation: true,
  allowTouchMove: isMobile,
  spaceBetween: 8,
};
