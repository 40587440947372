import type { TFunction } from 'next-i18next';
import { numberFormatter } from '@mwl/core-lib';

interface FormatPriceRangeProps {
  min?: string | number;
  max?: string | number;
  t: TFunction;
}

export const formatPriceRange = ({ max, min, t }: FormatPriceRangeProps): string => {
  const format = (value: number | string) => numberFormatter(+value);

  if (min && max && Number.isFinite(+min) && Number.isFinite(+max)) {
    return `${format(min)} - ${format(max)}`;
  }

  if (min && Number.isFinite(+min)) {
    return `${t('common:prise.min', 'min')}: ${format(min)}`;
  }

  if (max && Number.isFinite(+max)) {
    return `${t('common:prise.max', 'max')}: ${format(max)}`;
  }

  return '';
};
