import type { FormSettings } from '@mwl/core-lib';
import { CODE_LENGTH, minLengthValidator, requiredStringValidator } from '@mwl/core-lib';

import type { FormConfirmationValues } from './FormConfirmation.types';

export const formSettings: FormSettings<FormConfirmationValues> = {
  code: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error' },
      minLength: { validate: minLengthValidator(CODE_LENGTH), message: 'error' },
    },
    value: '',
  },
};
