import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, useQueryPopupOpen } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';

import type { ButtonSignInProps } from './ButtonSignIn.types';

import styles from './ButtonSignIn.module.scss';

const BaseButtonSignIn: FC<ButtonSignInProps> = ({ className, analytics, ...props }) => {
  const { t } = useTranslation('common');

  const { createLinkProps } = useQueryPopupOpen();

  const linkProps = useMemo(() => createLinkProps({ queryName: 'login' }), [createLinkProps]);

  const buttonText = t('button.signin', `Sign In`);

  return (
    <Button
      as="link"
      variant="fulfilled"
      size="lg"
      fontSize="lg"
      fontWeight="bold"
      fullWidth
      rounded
      color="grey"
      className={cn(className, styles.root)}
      analytics={extendAnalyticsData(analytics, { text: buttonText })}
      {...linkProps}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

const ButtonSignIn = memo(BaseButtonSignIn);

export * from './ButtonSignIn.types';
export { ButtonSignIn };
