import type { FC } from 'react';
import { memo } from 'react';
import { getTestProps, handleEventWithAnalytics } from '@mwl/core-lib';
import { SwitchBase } from '@mwl/ui';

import type { SwitchProps } from './Switch.types';

import styles from './Switch.module.scss';

const BaseSwitch: FC<SwitchProps> = ({ active, disabled, classes, onChange, analytics, ...props }) => {
  const analyticsEvent = active ? analytics?.off : analytics?.on;

  const handleChange = (isChecked: boolean) => {
    if (disabled) {
      return;
    }

    const eventHandler = handleEventWithAnalytics(onChange, analyticsEvent?.eventName, analyticsEvent?.data);

    eventHandler(isChecked);
  };

  const analyticsClassName: string | undefined = analyticsEvent?.data?.cls;

  return (
    <SwitchBase
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      wrapperProps={getTestProps(props) as any} // TODO(d.arutyunyan): fix later in @mwl/core-lib
      className={cn(styles.root, analyticsClassName, classes?.root, { [styles.disabled]: disabled })}
      classes={{ track: styles.track, checked: styles.checked, thumb: cn(styles.thumb, classes?.control) }}
      onChange={handleChange}
      checked={active}
      radius="lg"
      size="md"
    />
  );
};

const Switch = memo(BaseSwitch);

export * from './Switch.types';
export { Switch };
