import { useMemo } from 'react';
import type { LinesCountByStageSB } from '@mwl/core-lib';

import { useSportUrlParams } from './useSportUrlParams/useSportUrlParams';

interface SportItem {
  linesCount: Array<LinesCountByStageSB>;
}

/**
 * Хук для фильтрации видов спорта / категорий / чемпионатов по стейджу
 * @param items - Массив элементов, содержащих поле linesCountExtended
 * @returns Отфильтрованный массив
 */
export const useItemsFilteredByStage = <T extends SportItem>(items: Array<T>) => {
  const { stage } = useSportUrlParams();

  const filteredItems = useMemo(() => {
    if (!stage) {
      return items;
    }

    return items.filter((item) => {
      const currentStage = item.linesCount.find((countItem) => countItem.stage === stage);

      if (!currentStage) {
        return true;
      }

      return currentStage.count > 0;
    });
  }, [items, stage]);

  return filteredItems;
};
