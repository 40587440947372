import type { FormFieldProps } from './FormField.types';

import styles from './FormField.module.scss';

type GetInputClassNameParams = Required<Pick<FormFieldProps, 'as'>> & Omit<FormFieldProps, 'className'>;

export const getInputClassName = ({
  isError,
  isSuccess,
  as,
  prefix,
  postfix,
  variant,
  sizeVariant,
}: GetInputClassNameParams) => {
  const isInput = as !== 'textarea';

  return cn({
    [styles.error]: isInput && isError,
    [styles.success]: isInput && isSuccess,
    [styles.textarea]: !isInput,
    [styles.input]: isInput,
    [styles.withPrefix]: isInput && prefix,
    [styles.withPostfix]: isInput && postfix,
    [styles.light]: variant === 'light',
    [styles[`size${sizeVariant}`]]: !!sizeVariant,
  });
};
