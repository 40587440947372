import type { FC } from 'react';
import { useRef } from 'react';
import { useRouter } from 'next/router';
import { selectIsMonitoringPage, selectUserInitialAuthCompleted, useDisableBodyScroll } from '@mwl/core-lib';

import { Loader } from '@/components/Loader/Loader';
import { useTypedSelector } from '@/hooks';
import { appPageObject } from '@/utils/markerTree';

import styles from './Preloader.module.scss';

const Preloader: FC = () => {
  const router = useRouter();

  const isAuthCompleted = useTypedSelector(selectUserInitialAuthCompleted);
  const isMonitoringPage = useTypedSelector(selectIsMonitoringPage);

  const is404Page = router.route === '/404';

  const containerRef = useRef<HTMLDivElement>(null);

  const isLoading = !isAuthCompleted && !isMonitoringPage && !is404Page;

  useDisableBodyScroll({
    isDisable: isLoading,
    popupRef: containerRef,
  });

  return (
    <div
      {...appPageObject.preloader.nodeProps}
      className={cn(styles.root, {
        [styles.opened]: isLoading,
      })}
      ref={containerRef}
    >
      <div className={styles.container}>
        <Loader className={styles.loader} />
      </div>
    </div>
  );
};

export { Preloader };
