import type { HistoryPaymentStatus } from '@mwl/core-lib';

import styles from './HistoryTable.module.scss';

export const initialCount = 20;

export const columns = [
  { key: 'payment:date', text: 'Date' },
  { key: 'payment:status', text: 'Status', className: styles.colStatus },
  { key: 'payment:credit_amount', text: 'Credit amount' },
  { key: 'payment:paid_amount', text: 'Paid amount' },
  { key: 'payment:type', text: 'Type' },
  { key: 'payment:merged_method', text: 'Method' },
  { key: 'payment:account_number', text: 'Account number' },
];

export const cancelStatuses: Partial<Record<HistoryPaymentStatus, boolean>> = {
  WAITING_FOR_CONFIRMATION: true,
};
