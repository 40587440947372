import type { FC } from 'react';

import { Button } from '@/components/Button/Button';

import type { PaymentStatusLinkProps } from './PaymentStatusLink.types';

import styles from './PaymentStatusLink.module.scss';

export const PaymentStatusLink: FC<PaymentStatusLinkProps> = ({ item, children, onOpenConfirmPopup }) => {
  if (!onOpenConfirmPopup) {
    return <>{children}</>;
  }

  return (
    <Button as="button" variant="text" color="white" onClick={() => onOpenConfirmPopup(item)} className={styles.link}>
      {children}
    </Button>
  );
};
