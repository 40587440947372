const { ns } = require('./namespaces');
const { getLocales } = require('./getLocales');

const locales = getLocales();

/** @type import("next").I18NConfig */
const i18n = {
  defaultLocale: 'en',
  locales,
  localeDetection: false,
};

/** @type import("next-i18next").UserConfig */
module.exports = {
  i18n,
  ns,
  serializeConfig: false,
  fallbackLng: 'en',
  defaultNS: 'common',
  reloadOnPrerender: process.env.NODE_ENV !== 'production',
  debug: false,
};
