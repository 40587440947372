import { createReducer } from '@reduxjs/toolkit';

import { togglePopupRegistrationBonus, toggleSidebar } from './actions';

export interface UIState {
  showSidebar: boolean;
  isErrorPage: boolean;
  registrationBonusIsShowed: boolean;
}

const initialState: UIState = {
  showSidebar: false,
  isErrorPage: false,
  registrationBonusIsShowed: false,
};

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleSidebar, (state, action) => ({
      ...state,
      showSidebar: action.payload,
    }))
    .addCase(togglePopupRegistrationBonus, (state, action) => ({
      ...state,
      registrationBonusIsShowed: action.payload,
    }));
});
