import { useEffect, useMemo } from 'react';
import {
  extendAnalyticsData,
  formatPhoneNumber,
  formatResponseErrors,
  loginSuccess,
  registerSuccess,
  registrationByPhone,
  selectUserLocale,
  sendAnalyticsData,
  setRecoveryLogin,
  useForm,
  useQueryPopupOpen,
  useSubmitFormAuth,
} from '@mwl/core-lib';

import { useRegister } from '@/context/register/register';
import { useTypedDispatch, useTypedSelector } from '@/hooks';

import type { FormRegistrationPhoneValues, UseRegistrationPhoneFormParams } from './FormRegistrationPhone.types';
import { formSettings } from './FormRegistrationPhone.utils';

export function useRegistrationPhoneForm({ analytics }: UseRegistrationPhoneFormParams) {
  const dispatch = useTypedDispatch();
  const locale = useTypedSelector(selectUserLocale);
  const { onOpenPopup } = useQueryPopupOpen();
  const { selectedBonus, onSuccessRegister } = useRegister();

  const { formState, changeField, handleSubmit, setErrors } = useForm<FormRegistrationPhoneValues>(formSettings);

  const fullAnalytics = useMemo(
    () =>
      extendAnalyticsData(analytics, {
        with_promocode: !!formState.values.promoCode,
        promocode: formState.values.promoCode || undefined,
      }),
    [analytics, formState.values.promoCode],
  );

  const {
    state: { error, isLoading },
    onSubmitConfirm,
  } = useSubmitFormAuth({
    requestHandler: registrationByPhone,
    formState: formState.values,
    onSuccess: (data) => {
      if (data?.token) {
        sendAnalyticsData(fullAnalytics?.success?.eventName, fullAnalytics?.success?.data);
        dispatch(registerSuccess());
        dispatch(loginSuccess({ token: data.token, skipAuthReload: true }));
        onSuccessRegister();
      }
    },
    onError: (formError) => {
      const isPhoneAlreadyExist = !!formError.details?.find((detail) => detail.key === 'error.phoneAlreadyExists');

      setErrors(formatResponseErrors<typeof formState.errors>(formError));

      if (isPhoneAlreadyExist) {
        dispatch(setRecoveryLogin(formatPhoneNumber(formState.values.code.value, formState.values.phone)));
        onOpenPopup({ queryName: 'reset_password' });
      }
    },
  });

  const submitConfirm = (values: FormRegistrationPhoneValues) => {
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';

    onSubmitConfirm({
      countryIso2Code: values.country.value,
      currencyIsoCode: values.currency.label,
      phonePrefix: values.code.label,
      phone: formatPhoneNumber(values.code.value, values.phone),
      bonusType: selectedBonus.type !== 'REFUSAL' ? selectedBonus.type : undefined,
      locale,
      password: values.password,
      promoCode: values.promoCode || undefined,
      timezone,
    });
  };

  useEffect(() => {
    const { type = '', errors } = formState;
    const eventName = fullAnalytics?.error?.eventName;

    if (!['VALIDATE_ALL', 'SET_ERRORS'].includes(type) || (!errors?.phone && !errors?.password)) {
      return;
    }

    const errorFields: Array<string> = [];

    if (errors?.phone) {
      errorFields.push('phone');
    }

    if (errors?.password) {
      errorFields.push('password');
    }

    const eventSuffix = formState.type === 'VALIDATE_ALL' ? '_front_error' : '_back_error';

    sendAnalyticsData(`${eventName}${eventSuffix}`, {
      ...(fullAnalytics?.error?.data || {}),
      form_errors: errorFields,
    });
  }, [formState, fullAnalytics?.error?.data, fullAnalytics?.error?.eventName]);

  return {
    formState,
    changeField,
    handleSubmit: handleSubmit(submitConfirm),
    isLoading,
    error,
  };
}
