import type { FC } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { FormRecoveryCode } from './components/FormRecoveryCode/FormRecoveryCode';
import { FormRecoveryLink } from './components/FormRecoveryLink/FormRecoveryLink';
import { FormRecoveryLogin } from './components/FormRecoveryLogin/FormRecoveryLogin';
import { FormRecoveryPassword } from './components/FormRecoveryPassword/FormRecoveryPassword';
import { RecoveryStateContext, useProvideRecoveryState } from './FormRecovery.context';
import { translates } from './FormRecovery.data';
import type { FormRecoveryProps, FormRecoveryTab } from './FormRecovery.types';
import { checkIsEmail } from './FormRecovery.utils';

import styles from './FormRecovery.module.scss';

const BaseFormRecovery: FC<FormRecoveryProps> = ({ className, defaultTab = 'login' }) => {
  const { t } = useTranslation('common');

  const [tab, setTab] = useState<FormRecoveryTab>(defaultTab);

  const value = useProvideRecoveryState();

  const changeTab = useCallback((recoveryTab: FormRecoveryTab) => setTab(recoveryTab), []);

  const message = useMemo(() => {
    if (value.isCodeError) {
      return translates.timerError;
    }

    if (tab === 'login') {
      return translates.login;
    }

    if (tab === 'code') {
      const isEmail = checkIsEmail(value.login);

      return isEmail ? translates.email : translates.phone;
    }

    return '';
  }, [tab, value.login, value.isCodeError]);

  return (
    <RecoveryStateContext.Provider value={value}>
      <div className={cn(styles.root, className, { [styles.error]: value.isCodeError })}>
        <h2 className={styles.title}>{t('auth.recovery.title', 'Password recovery')}</h2>
        {message && <p className={styles.description}>{t(message, { login: value.login })}</p>}
        <FormRecoveryLogin className={cn(styles.form, { [styles.active]: tab === 'login' })} onChangeTab={changeTab} />
        {tab === 'code' && <FormRecoveryCode className={cn(styles.form, styles.active)} onChangeTab={changeTab} />}
        <FormRecoveryPassword className={cn(styles.form, { [styles.active]: tab === 'password' })} />
        <FormRecoveryLink className={cn(styles.form, { [styles.active]: tab === 'link' })} />
      </div>
    </RecoveryStateContext.Provider>
  );
};

const FormRecovery = memo(BaseFormRecovery);

export * from './FormRecovery.types';
export { FormRecovery };
