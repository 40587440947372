import type { PropsWithChildren, Ref } from 'react';
import { forwardRef, memo } from 'react';
import { isMobile } from 'react-device-detect';
import type Scrollbar from 'react-perfect-scrollbar';
import { PerfectScrollbarBase } from '@mwl/ui';

import type { PerfectScrollbarProps } from './PerfectScrollbar.types';

import styles from './PerfectScrollbar.module.scss';

const defaultOptions: PerfectScrollbarProps['options'] = {
  suppressScrollX: true,
};

const BasePerfectScrollbar = forwardRef(
  (
    {
      children,
      options = {},
      className,
      isSwitchOffOnMobile = true,
      ...props
    }: PropsWithChildren<PerfectScrollbarProps>,
    ref: Ref<Scrollbar & HTMLDivElement>,
  ): JSX.Element => {
    return (
      <PerfectScrollbarBase
        {...props}
        options={{ ...defaultOptions, ...options }}
        className={cn(styles.root, { [styles.mobile]: isMobile && isSwitchOffOnMobile }, className)}
        isSwitchOffOnMobile={isSwitchOffOnMobile}
        ref={ref}
      >
        {children}
      </PerfectScrollbarBase>
    );
  },
);

const PerfectScrollbar = memo(BasePerfectScrollbar);

export * from './PerfectScrollbar.types';
export { PerfectScrollbar };
