import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';

import { FormRecoveryTimer } from '../FormRecoveryTimer/FormRecoveryTimer';

import type { ButtonSendCodeAgainProps } from './ButtonSendCodeAgain.types';

const BaseButtonSendCodeAgain: FC<ButtonSendCodeAgainProps> = ({
  className,
  timer,
  onComplete,
  sendRequest,
  ...props
}) => {
  const { t } = useTranslation('common');

  return (
    <Button
      {...props}
      as="button"
      color="red"
      size="md"
      fontSize="xl"
      fontWeight="bold"
      fullWidth
      className={className}
      type="button"
      onClick={sendRequest}
      disabled={!!timer}
      rounded
    >
      {t('send_code_again', 'Send code again')}
      {timer && <FormRecoveryTimer date={timer} onComplete={onComplete} />}
    </Button>
  );
};

const ButtonSendCodeAgain = memo(BaseButtonSendCodeAgain);

export * from './ButtonSendCodeAgain.types';
export { ButtonSendCodeAgain };
