import { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { PaymentItem, PayoutProhibitionReason } from '@mwl/core-lib';
import {
  loadPayoutRequest,
  selectPayoutMethods,
  selectPayoutMethodsIsLoading,
  selectPayoutProhibitionReasons,
  selectUserBalanceAmount,
  selectUserCurrency,
  selectUserCurrencySymbol,
  selectUserIsAuthenticated,
} from '@mwl/core-lib';

import { useTypedDispatch, useTypedSelector } from '@/hooks';

import { formatWithdrawalMethods } from './usePayloadWithdrawal.utils';

interface UsePayloadPayoutResult {
  methods: Array<PaymentItem>;
  prohibitionReasons: Array<PayoutProhibitionReason>;
  isLoading: boolean;
}

export const usePayloadWithdrawal = (): UsePayloadPayoutResult => {
  const dispatch = useTypedDispatch();

  const { t } = useTranslation('payout');

  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const methods = useTypedSelector(selectPayoutMethods);
  const prohibitionReasons = useTypedSelector(selectPayoutProhibitionReasons);
  const isLoading = useTypedSelector(selectPayoutMethodsIsLoading);
  const currencySymbol = useTypedSelector(selectUserCurrencySymbol);
  const currency = useTypedSelector(selectUserCurrency);
  const userBalance = useTypedSelector(selectUserBalanceAmount);

  const payoutList = useMemo(
    () => formatWithdrawalMethods(methods, currencySymbol, userBalance, t),
    [methods, currencySymbol, t, userBalance],
  );

  useEffect(() => {
    if (currency && isAuth) {
      dispatch(loadPayoutRequest());
    }
  }, [currency, dispatch, isAuth]);

  return { methods: payoutList, isLoading, prohibitionReasons: prohibitionReasons ?? [] };
};
