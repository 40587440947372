import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { downloadFile } from '@mwl/core-lib';

import type { ButtonAsButton, ButtonAsLink } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';

import type { PaymentTextButtonProps } from './PaymentTextButton.types';

export const PaymentTextButton: FC<PaymentTextButtonProps> = ({
  className,
  buttonType,
  title,
  resource,
  action,
  link,
  linkFromKey,
}) => {
  const { t } = useTranslation('payment');

  const downloadImage = useCallback(() => {
    downloadFile(resource || '', 'image');
  }, [resource]);

  const buttonProps = useMemo(() => {
    if (action === 'download' && resource) {
      return {
        as: 'button',
        onClick: downloadImage,
      } as Omit<ButtonAsButton, 'children'>;
    }

    return { as: 'link', href: (!!linkFromKey && t(linkFromKey)) || link || resource || '' } as Omit<
      ButtonAsLink,
      'children'
    >;
  }, [action, downloadImage, link, linkFromKey, resource, t]);

  return (
    <Button
      className={className}
      {...buttonProps}
      variant="fulfilled"
      rounded
      fullWidth
      fontSize="lg"
      fontWeight="bold"
      size="md"
      color="green"
      type={buttonType}
    >
      {title ? t(title) : ''}
    </Button>
  );
};
