import type { BonusPacketCardProps } from './BonusPacketCard.types';

import styles from './BonusPacketCard.module.scss';

type GetBonusClassNameParams = Required<Pick<BonusPacketCardProps, 'size' | 'theme'>> &
  Pick<BonusPacketCardProps, 'className' | 'isActive' | 'onSelect' | 'available' | 'locked'> & { isAuth: boolean };

export const getBonusClassNames = ({
  className,
  size,
  isActive,
  onSelect,
  available,
  theme,
  locked,
  isAuth,
}: GetBonusClassNameParams) => {
  return cn(
    styles.root,
    styles[`${size}Size`],
    styles[`${theme?.toLowerCase()}Theme`],
    {
      [styles.active]: isActive,
      [styles.supportActive]: onSelect,
      [styles.disabled]: !available && isAuth,
      [styles.locked]: locked,
    },
    className,
  );
};
