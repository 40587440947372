import type { PaymentMethodField } from '@mwl/core-lib';
import { makeRequest } from '@mwl/core-lib';

import { validationKeys } from './PaymentUploadField.data';
import type { GenerateFormDataParams, ValidateFileParams } from './PaymentUploadField.types';

// Данные, которые могут прийти с гринбэка в storageEncodedFormData, но которых не должно быть в formData
const excludedKeys = ['url'];

const formatBytes = (bytes: number, decimalPlaces = 2) => {
  if (!+bytes) {
    return '0 B';
  }

  const byte = 1024;
  const decimalCount = decimalPlaces < 0 ? 0 : decimalPlaces;
  const sizes = ['B', 'KB', 'MB', 'GB'];

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(byte));

  return `${parseFloat((bytes / byte ** sizeIndex).toFixed(decimalCount))} ${sizes[sizeIndex]}`;
};

const base64ToJson = (base64Str: string) => {
  try {
    const jsonStr = Buffer.from(base64Str, 'base64').toString();
    return JSON.parse(jsonStr) as Dictionary<string | number>;
  } catch (error) {
    console.error('Parsing error: ', error);
    return {};
  }
};

export const getFileExtension = (fileName: string) => {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex >= 0 ? fileName.slice(dotIndex) : '';
};

export const getAttrSizes = (attr: PaymentMethodField['attr']) => {
  const min = attr?.find((attribute) => attribute.key === 'minSizeBytes');
  const max = attr?.find((attribute) => attribute.key === 'maxSizeBytes');

  return { min: min ? +min.value : null, max: max ? +max.value : null };
};

export const getDisplayMaxSize = (attr: PaymentMethodField['attr']) => {
  const { max } = getAttrSizes(attr);

  return max ? formatBytes(max) : '';
};

export const validateFile = ({ file, allowedExtensions, attr }: ValidateFileParams) => {
  const extension = getFileExtension(file.name);

  if (!extension || (allowedExtensions && !allowedExtensions.includes(extension))) {
    return validationKeys.extension;
  }

  const { min, max } = getAttrSizes(attr);

  if (min && file.size < min) {
    return validationKeys.minSize;
  }

  if (max && file.size > max) {
    return validationKeys.maxSize;
  }

  return null;
};

export const generateFormData = ({
  file,
  fileName,
  customRequiredFormDataFields,
  storageEncodedFormData,
}: GenerateFormDataParams) => {
  const formData = new FormData();

  if (storageEncodedFormData) {
    const parsedData = base64ToJson(storageEncodedFormData);

    Object.entries(parsedData).forEach(([key, value]) => {
      if (!excludedKeys.includes(key)) {
        formData.append(key, String(value));
      }
    });
  }

  customRequiredFormDataFields?.forEach((field) => {
    if (field === 'x-amz-meta-file-extension') {
      formData.append(field, getFileExtension(file.name));
    }
  });

  formData.append('key', fileName);
  formData.append('file', file, 'file');

  return formData;
};

export const upload = async (url: string, formData: FormData) => {
  return makeRequest({
    url,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
