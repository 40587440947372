import { byKey, complex, simple } from '@mwl/core-lib';

const formLoginEmailTabSchema = complex({
  emailInput: simple,
  passwordInput: simple,
  resetLink: simple,
  submitButton: simple,
});

const formLoginPhoneTabSchema = complex({
  phoneInput: simple,
  passwordInput: simple,
  resetLink: simple,
  submitButton: simple,
});

export const formLoginSchema = complex({
  tabs: byKey(simple),
  emailTab: formLoginEmailTabSchema,
  phoneTab: formLoginPhoneTabSchema,
  signUpButton: simple,
});
