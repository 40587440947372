import type { LineCategorySB, LineChampionshipSB, LineListSB, LineSportSB } from '@mwl/core-lib';

import type { LineWithEntities } from '@/types';

interface Entity {
  id: number | string;
}

const arrayToMap = <T extends Entity>(data?: Array<T>): Dictionary<T> => {
  if (!data) {
    return {};
  }

  return data.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: item,
    };
  }, {});
};

/**
 * Функция для получения списка линий с дополнительными данными о категориях, чемпионатах и спортах.
 */
export const sportLinesEnrichment = (data: LineListSB): Array<LineWithEntities> => {
  const categoriesMap = arrayToMap<LineCategorySB>(data.categories);
  const championshipsMap = arrayToMap<LineChampionshipSB>(data.championships);
  const sportsMap = arrayToMap<LineSportSB>(data.sports);

  return (data.lines || []).map((line) => ({
    ...line,
    category: categoriesMap[line.categoryId],
    championship: championshipsMap[line.championshipId],
    sport: sportsMap[line.sportId],
  }));
};
