import type { FC, PropsWithChildren } from 'react';
import { useCallback } from 'react';
import Link from 'next/link';

import type { LineWithEntities } from '@/types';

import { useSportUrlParams } from './useSportUrlParams/useSportUrlParams';

interface UseSportLinksParams {
  data: LineWithEntities;
  styles: Readonly<Dictionary<string>>;
}

/** Хук для генерации ссылок на разделы спорта
 * @param data - Данные линии
 * @param styles - Объект стилей для компонентов ссылок
 *
 * @returns SportLink - Компонент ссылки на вид спорта
 * @returns championshipLink - Компонент ссылки на чемпионат и / или категорию
 * @returns sportCode - Код спорта
 * @returns sportIcon - Иконка спорта
 */
export const useSportLinks = ({ data, styles }: UseSportLinksParams) => {
  const { createSportItemLink, createChampionshipLink, createSportCategoryLink, championshipId, categoryId, sportId } =
    useSportUrlParams();

  const { championship } = data;
  const championshipUrl = createChampionshipLink(championship);

  let championshipLink = championship ? (
    <Link className={styles.headerLink} title={championship?.title} href={championshipUrl}>
      {championship.title}
    </Link>
  ) : (
    ''
  );

  if (String(championship?.id) === championshipId) {
    championshipLink = <span title={championship?.title}>{championship.title}</span>;
  }

  const { category } = data;
  const categoryTitle = category?.title ?? '';
  const hasCategoryTitle = categoryTitle && championship?.title?.includes(categoryTitle);

  if (!hasCategoryTitle) {
    const categoryUrl = createSportCategoryLink(category);

    const categoryNameComponent =
      String(category?.id) === categoryId ? (
        <span title={categoryTitle}>{categoryTitle}.</span>
      ) : (
        <Link className={styles.headerLink} title={categoryTitle} href={categoryUrl}>
          {categoryTitle}.
        </Link>
      );

    const championshipNameComponent =
      String(championship?.id) === championshipId ? (
        <span title={championship?.title}>{championship?.title ?? ''}</span>
      ) : (
        <Link className={styles.headerLink} title={championship?.title} href={championshipUrl}>
          {championship?.title ?? ''}
        </Link>
      );

    championshipLink = (
      <>
        {categoryNameComponent} {championshipNameComponent}
      </>
    );
  }

  const SportLink: FC<PropsWithChildren> = useCallback(
    ({ children }) => {
      if (sportId === String(data.sport.id)) {
        return <span className={styles.sportLink}>{children}</span>;
      }

      return (
        <Link href={createSportItemLink(String(data.sport.id))} className={styles.sportLink}>
          {children}
        </Link>
      );
    },
    [sportId, data.sport.id, createSportItemLink, styles.sportLink],
  );

  return {
    SportLink,
    championshipLink,
    sportCode: data.sport.code,
    sportIcon: data.sport.icon,
  };
};
