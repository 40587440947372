import { useEffect, useState } from 'react';
import type { BonusListItem } from '@mwl/core-lib';
import { loadBonusListRequest, selectBonusList } from '@mwl/core-lib';

import { useTypedDispatch } from './useTypedDispatch';
import { useTypedSelector } from './useTypedSelector';

export const useBonusInfo = (bonusId?: string) => {
  const [activeBonus, setActiveBonus] = useState<BonusListItem | null>(null);
  const dispatch = useTypedDispatch();
  const currentBonusList = useTypedSelector(selectBonusList);

  useEffect(() => {
    if (!currentBonusList) {
      dispatch(loadBonusListRequest());
    }

    const targetBonus = currentBonusList?.find((bonus) => bonus.id === bonusId);

    if (targetBonus) {
      setActiveBonus(targetBonus);
    }
  }, [bonusId, currentBonusList, dispatch]);

  return activeBonus;
};
