import type { FC } from 'react';
import { memo } from 'react';
import { getTestProps } from '@mwl/core-lib';
import { PaymentCardBase } from '@mwl/ui';

import type { PaymentCardProps } from './PaymentCard.types';

import styles from './PaymentCard.module.scss';

const BasePaymentCard: FC<PaymentCardProps> = ({
  className,
  classes,
  identity,
  image,
  footer,
  discount,
  active = false,
  onClick,
  ...props
}) => {
  const handleClick = () => onClick?.(identity);

  return (
    <PaymentCardBase
      {...getTestProps(props)}
      className={cn(styles.root, className, { [styles.active]: active }, active && classes?.active)}
      classes={{
        image: cn(styles.image, classes?.image),
        footer: cn(styles.text, classes?.text, styles.footer),
        header: styles.discount,
      }}
      onClick={handleClick}
      header={discount ? <span>{discount}%</span> : null}
      image={image}
      footer={footer}
      radius="lg"
    />
  );
};

export * from './PaymentCard.types';
const PaymentCard = memo(BasePaymentCard);
export { PaymentCard };
