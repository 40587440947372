import { byKey, complex, simple } from '@mwl/core-lib';

const promotionsSchema = complex({
  promotion: simple,
  promotionButton: simple,
  popup: simple,
});

const promotionBlockSchema = complex({
  block: simple,
  popup: simple,
});

const promotionNavigationSchema = complex({
  root: simple,
  item: byKey(simple),
});

export const promoSchema = complex({
  header: simple,
  promotions: promotionsSchema,
  navigation: promotionNavigationSchema,
  promoBlock: promotionBlockSchema,
});
