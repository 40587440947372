import NewIcon from '@public/assets/casino/icons/new.svg';
import PopularIcon from '@public/assets/casino/icons/popular.svg';
import FavoritesIcon from '@public/assets/common/icons/star.svg';

import { routes } from '@/constants/routes';

import type { NavMenuItem } from './navMenu.types';

export const sidebarTopSectionNavMenuCasino: Array<NavMenuItem> = [
  {
    key: 'header.navigation.casino.popular',
    text: 'Popular',
    slug: 'popular',
    href: { pathname: routes.casino.category, query: { category: 'popular' } },
    Icon: PopularIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_popularBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.new',
    text: 'New',
    slug: 'new',
    href: { pathname: routes.casino.category, query: { category: 'new' } },
    Icon: NewIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_newBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.favorite',
    slug: 'favorite',
    text: 'Favorite',
    href: routes.casino.favorites,
    authenticated: true,
    Icon: FavoritesIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_favoritesBtn_click',
      },
    },
  },
];

export const sidebarTopSectionNavMenuLiveCasino: Array<NavMenuItem> = [
  {
    key: 'header.navigation.casino.popular',
    text: 'Popular',
    slug: 'popular',
    href: { pathname: routes.liveCasino.category, query: { category: 'popular' } },
    Icon: PopularIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_popularBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.new',
    text: 'New',
    slug: 'new',
    href: { pathname: routes.liveCasino.category, query: { category: 'new' } },
    Icon: NewIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_newBtn_click',
      },
    },
  },
  {
    key: 'header.navigation.casino.favorite',
    slug: 'favorite',
    text: 'Favorite',
    href: routes.casino.favorites,
    authenticated: true,
    Icon: FavoritesIcon,
    analytics: {
      click: {
        eventName: 'casinoMenu_favoritesBtn_click',
      },
    },
  },
];
