import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { CountdownRendererFn } from 'react-countdown';
import { zeroPad } from 'react-countdown';
import { calcTimerIsEnded } from '@mwl/core-lib';

import { Countdown } from '@/components/Countdown/Countdown';

import type { TimerProps } from './Timer.types';

export const Timer: FC<TimerProps> = ({ className, date, onComplete }) => {
  const rendererCountdown: CountdownRendererFn = useCallback(
    ({ days, hours, minutes, seconds }) => {
      const resultHours = days * 24 + hours;

      return (
        <span className={cn(className)}>
          {!!resultHours && `${zeroPad(resultHours)}:`}
          {zeroPad(minutes)}
          {!resultHours && `:${zeroPad(seconds)}`}
        </span>
      );
    },
    [className],
  );

  useEffect(() => {
    const isTimerEnded = calcTimerIsEnded(date);

    if (isTimerEnded) {
      onComplete();
    }
  }, [date, onComplete]);

  return <Countdown date={date} renderer={rendererCountdown} onComplete={onComplete} />;
};
