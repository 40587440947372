import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { type Analytics, type BonusPacketRegistrationType, extendAnalyticsData } from '@mwl/core-lib';

import { Switch } from '@/components/Switch/Switch';
import { useRegister } from '@/context';

import { BonusItem } from '../BonusItem/BonusItem';

import styles from './RegisterContainerFooter.module.scss';

interface BaseRegisterContainerFooterProps {
  onChange?: (type: BonusPacketRegistrationType) => void;
  analytics?: Analytics;
}

const BaseRegisterContainerFooter: FC<BaseRegisterContainerFooterProps> = ({ onChange, analytics }) => {
  const { t } = useTranslation();
  const { bonuses, selectedBonus, changeBonus } = useRegister();
  const notRefusalBonuses = bonuses.filter((b) => b.type !== 'REFUSAL');

  const handleChangeBonus = (active: boolean) => {
    const firstBonusType = notRefusalBonuses[0]?.type || 'CASINO';
    const bonus = active ? firstBonusType : 'REFUSAL';
    changeBonus(bonus);
    onChange?.(bonus);
  };

  const switchAnalytics = useMemo(() => {
    const firstBonusType = notRefusalBonuses[0]?.type || 'CASINO';
    const bonusType = selectedBonus?.type !== 'REFUSAL' ? 'REFUSAL' : firstBonusType;
    return extendAnalyticsData(analytics, { reg_bonus: bonusType.toLocaleLowerCase() });
  }, [analytics, notRefusalBonuses, selectedBonus?.type]);

  return (
    <div className={styles.root}>
      <div className={styles.refuseContainer}>
        <span className={styles.label}>{t('reg_popup_choose_bonus', 'Choose Bonus')}</span>
        <Switch
          disabled={bonuses.length < 2}
          active={selectedBonus?.type !== 'REFUSAL'}
          onChange={handleChangeBonus}
          analytics={switchAnalytics}
        />
      </div>
      {selectedBonus?.type !== 'REFUSAL' && (
        <div className={styles.bonusesWrapper}>
          <div className={styles.bonusesContainer}>
            {notRefusalBonuses.map(({ type, title, image, prize, comingSoonPrize }) => (
              <BonusItem
                key={type}
                title={t(title)}
                text={comingSoonPrize ? t(comingSoonPrize) : prize}
                onClick={() => changeBonus(type)}
                active={selectedBonus?.type === type}
                image={image}
                comingSoon={type === 'SPORT'}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const RegisterContainerFooter = memo(BaseRegisterContainerFooter);

export { RegisterContainerFooter };
