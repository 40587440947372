import type { Redirect } from 'next';
import qs from 'qs';

import { defaultLocale, routes } from '@/constants';

/**
 * Перенаправляет на страницу спорта по параметрам из query.
 */
export const serverRedirectToSport = (locale: string, params?: Dictionary<number>): Redirect => {
  const destination = `${(locale === defaultLocale ? '' : `/${locale}/`) + routes.sport.home}${
    params ? `?${qs.stringify(params)}` : ''
  }`;

  return { destination, permanent: false };
};
