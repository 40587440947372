import type { ChangeEvent, ChangeEventHandler, FC, Ref } from 'react';
import { forwardRef, memo, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { addFieldNameToAnalytics, handleEventWithAnalytics } from '@mwl/core-lib';
import { FormFieldBase } from '@mwl/ui';

import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';

import type { FormFieldProps, NumberFormatProps } from './FormField.types';
import { getInputClassName } from './FormField.utils';

import styles from './FormField.module.scss';

const NumberFormatAdapter: FC<NumberFormatProps> = forwardRef(({ numberFormatPrefix, ...props }, ref) => {
  return <NumberFormat {...props} getInputRef={ref} prefix={numberFormatPrefix} />;
});

const BaseFormField = forwardRef<HTMLInputElement | HTMLTextAreaElement, FormFieldProps>(
  (
    {
      className,
      prefix,
      postfix,
      isError,
      isSuccess,
      isConfirmed,
      variant = 'default',
      label,
      errorText,
      sizeVariant = '48',
      analytics,
      onChange,
      hasApprove,
      approveText,
      name,
      disabled,
      classes,
      labelOverflow = true,
      ...props
    },
    ref,
  ) => {
    const inputClassName = useMemo(
      () => getInputClassName({ as: props.as, isError, isSuccess, postfix, prefix, variant, sizeVariant }),
      [props.as, isError, isSuccess, postfix, prefix, variant, sizeVariant],
    );

    const labelText = useMemo(() => {
      if (hasApprove) {
        return approveText;
      }

      if (isError && errorText) {
        return errorText;
      }

      return label;
    }, [approveText, errorText, hasApprove, isError, label]);

    const labelElement = useMemo(() => {
      if (!label && !isError && !errorText) {
        return null;
      }

      return (
        <FormFieldLabel
          htmlFor={props.id}
          hasError={Boolean(isError && errorText)}
          hasValue={!!props.value}
          hasApprove={hasApprove}
          hasOverflow={labelOverflow}
          className={cn(styles.label, { [styles.confirmed]: isConfirmed }, styles[`labelSize${sizeVariant}`])}
        >
          {labelText}
        </FormFieldLabel>
      );
    }, [
      errorText,
      hasApprove,
      isConfirmed,
      isError,
      label,
      labelOverflow,
      labelText,
      props.id,
      props.value,
      sizeVariant,
    ]);

    const handleOnChange = handleEventWithAnalytics<ChangeEvent<HTMLTextAreaElement | HTMLInputElement>>(
      onChange as ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
      analytics?.input?.eventName,
      addFieldNameToAnalytics(analytics?.input?.data, name),
    );

    const commonProps = {
      name,
      onChange: handleOnChange,
      labelElement,
      disabled,
      className: cn(styles.root, className, {
        [styles.error]: isError,
        [styles.success]: isSuccess,
      }),
      classes: {
        component: cn(inputClassName, classes?.component, analytics?.input?.data?.cls),
        prefix: cn(styles.prefix, classes?.prefix),
        postfix: cn(styles.postfix, classes?.postfix),
      },
    };

    const inputProps = {
      ...commonProps,
      prefix,
      postfix,
    };

    if (props.as === 'textarea') {
      return (
        <FormFieldBase
          component="textarea"
          ref={ref as Ref<HTMLTextAreaElement>}
          {...props}
          {...commonProps}
          className={cn(commonProps.className, styles.textareaField)}
        />
      );
    }

    if (props.as === 'numberFormat') {
      const { numberFormatPrefix } = props;

      return (
        <FormFieldBase
          ref={ref}
          component={NumberFormatAdapter}
          {...props}
          {...inputProps}
          numberFormatPrefix={numberFormatPrefix}
        />
      );
    }

    return <FormFieldBase component="input" ref={ref as Ref<HTMLInputElement>} {...props} {...inputProps} />;
  },
);

const FormField = memo(BaseFormField);

export * from './FormField.types';
export { FormField };
