import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import type { Swiper as SwiperType } from 'swiper';
import { SliderBase } from '@mwl/ui';

import ArrowLeftIcon from '@public/assets/common/icons/arrowLeftRound.svg';
import ArrowRightIcon from '@public/assets/common/icons/arrowRightRound.svg';

import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';

import type { SliderProps } from './Slider.types';

import styles from './Slider.module.scss';

const Slider: FC<SliderProps> = ({ classes, isEndShadow, isStartShadow, navigation, children, onSwiper, ...props }) => {
  // Нужно для ререндера после инициализации swiper
  const [, setSwiper] = useState<SwiperType>();

  const prevRef = useRef<HTMLDivElement | null>(null);
  const nextRef = useRef<HTMLDivElement | null>(null);

  const isNavigation = navigation === true;

  const navigationResult = isNavigation
    ? { prevEl: prevRef?.current, nextEl: nextRef?.current, disabledClass: cn(styles.disabled, classes?.disabled) }
    : navigation;

  const onSwiperHandler = useCallback(
    (instance: SwiperType) => {
      onSwiper?.(instance);
      setSwiper(instance);
    },
    [onSwiper],
  );

  return (
    <SliderBase
      className={cn({
        [styles.endShadow]: isEndShadow,
        [styles.startShadow]: isStartShadow,
      })}
      classes={{ wrapper: cn(styles.root, classes?.wrapper), slide: classes?.slide }}
      navigation={navigationResult}
      onSwiper={onSwiperHandler}
      beforeContent={
        isNavigation && (
          <div className={cn(styles.button, styles.prev, classes?.buttonLeft)} ref={prevRef}>
            <ButtonIcon
              as="button"
              size="xs"
              rounded
              className={styles.arrowButton}
              color="red"
              aria-label="slider navigate prev"
            >
              <ArrowLeftIcon className={cn(styles.icon, classes?.arrowIcon)} />
            </ButtonIcon>
          </div>
        )
      }
      afterContent={
        isNavigation && (
          <div className={cn(styles.button, styles.next, classes?.buttonRight)} ref={nextRef}>
            <ButtonIcon
              as="button"
              size="xs"
              rounded
              className={styles.arrowButton}
              color="red"
              aria-label="slider navigate next"
            >
              <ArrowRightIcon className={cn(styles.icon, classes?.arrowIcon)} />
            </ButtonIcon>
          </div>
        )
      }
      {...props}
    >
      {children}
    </SliderBase>
  );
};

export * from './Slider.types';
export { Slider };
