import { useRouter } from 'next/router';

import { routes } from '@/constants';

/**
 * Хук возвращает true если текущая страница - страница игры
 */
export const useIsGamePage = (): boolean => {
  const { pathname } = useRouter();
  return pathname.startsWith(routes.casino.game) || pathname.startsWith(routes.liveCasino.game);
};
