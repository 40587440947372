const webViewTag = 'wv';

/**
 * Возвращает true, если приложение запущено в веб-вью.
 */
export const isWebViewUserAgent = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.includes(webViewTag);
};
