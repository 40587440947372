import { useMemo } from 'react';
import { calcDepositBonusAmount, numberFormatter, selectUserCurrencySymbol, useDeposit } from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks';

import type { DepositFormAmountDataParams, DepositFormAmountDataResult } from './useDepositFormAmountData.types';

const minorOptions = { minimumFractionDigits: 0, maximumFractionDigits: 2 };

export const useDepositFormAmountData = ({ value }: DepositFormAmountDataParams): DepositFormAmountDataResult => {
  const currencySymbol = useTypedSelector(selectUserCurrencySymbol);

  const { selectedBonus, useBonus, payment } = useDeposit();

  const suffix = currencySymbol && ` ${currencySymbol.toUpperCase()} `;

  const amountWithCommission = useMemo(() => {
    if (!payment) {
      return 0;
    }

    const { commission } = payment;

    if (commission?.type === 'INCLUDED') {
      return +value - +value * (commission.percent / 100);
    }

    return +value;
  }, [value, payment]);

  const bonusAmount = useMemo(() => {
    if (!useBonus || !selectedBonus?.rewardDetails || selectedBonus.minimalDepositAmount > +value) {
      return null;
    }

    return calcDepositBonusAmount(selectedBonus, amountWithCommission);
  }, [useBonus, selectedBonus, amountWithCommission, value]);

  const total = useMemo(() => amountWithCommission + (bonusAmount || 0), [amountWithCommission, bonusAmount]);

  return {
    total: numberFormatter(total, { suffix, ...minorOptions }),
    bonusAmount: bonusAmount ? numberFormatter(bonusAmount, { suffix, ...minorOptions }) : null,
  };
};
