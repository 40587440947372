import type { PaymentMethodField } from '@mwl/core-lib';
import {
  calcAvailableWithdrawal,
  numberFormatter,
  selectUserBalanceAmount,
  selectUserCurrencySymbol,
} from '@mwl/core-lib';

import { useTypedSelector } from '@/hooks';

export const useWithdrawalFormAmount = (fields?: Array<PaymentMethodField> | null): string => {
  const userBalance = useTypedSelector(selectUserBalanceAmount);
  const currencySymbol = useTypedSelector(selectUserCurrencySymbol);

  const amountField = fields?.find((field) => field.name === 'amount');
  const suffix = currencySymbol && ` ${currencySymbol}`;

  if (!amountField) {
    return '';
  }

  const available = calcAvailableWithdrawal({ fields, userBalance });

  return numberFormatter(available || 0, { suffix });
};
