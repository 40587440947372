import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';

import { PaymentCard } from '@/components/PaymentCard/PaymentCard';
import { useMediaWidthDown } from '@/hooks';
import { popupsObject } from '@/utils';

import type { PaymentGridProps } from './PaymentGrid.types';

import styles from './PaymentGrid.module.scss';

const BasePaymentGrid: FC<PaymentGridProps> = ({ list, payment, classes, className, onChangePayment }) => {
  const isMobileScreen = useMediaWidthDown('screenXs');

  const formContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (payment?.identity && isMobileScreen) {
      formContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [payment?.identity, isMobileScreen]);

  return (
    <div className={cn(styles.root, className)}>
      {list.map((item, index) => (
        <PaymentCard
          {...item.card}
          {...popupsObject.deposit.payment.cards(item.identity).nodeProps}
          classes={classes?.item}
          identity={item.identity}
          onClick={() => onChangePayment(item, index)}
          active={item.identity === payment?.identity}
          key={item.identity}
        />
      ))}
    </div>
  );
};

export * from './PaymentGrid.types';
export const PaymentGrid = memo(BasePaymentGrid);
