import { type FC, useCallback } from 'react';
import { Trans, useTranslation } from 'next-i18next';

import ArrowIcon from '@public/assets/common/icons/arrowRightOutlined.svg';

import { Button } from '@/components/Button/Button';
import { routes } from '@/constants';
import { popupsObject } from '@/utils';

import { useWithdrawal } from '../../Withdrawal.context';

import styles from './EmptyProfileForm.module.scss';

const components = [
  <Button
    as="link"
    href={routes.profile.home}
    variant="text"
    color="default"
    uppercase={false}
    className={styles.link}
    key="routes.profile.home"
  />,
];

export const EmptyProfileForm: FC = () => {
  const { t } = useTranslation('payment');
  const { payment, changePayment } = useWithdrawal();

  const handleClickBack = useCallback(() => {
    changePayment();
  }, [changePayment]);

  return (
    <div
      {...popupsObject.deposit.payment.formWrapper(payment?.method.identity ?? '').nodeProps}
      className={styles.root}
    >
      <Button
        className={styles.backButton}
        as="button"
        variant="text"
        onClick={handleClickBack}
        align="left"
        fontWeight="bold"
        color="white"
      >
        <ArrowIcon className={styles.backIcon} />
        {t('back.button_refill', 'Back')}
      </Button>
      <div className={styles.head}>
        {t('back.button_withdrawal', 'Withdrawal')}&nbsp;{payment && t(payment.method.title)}
      </div>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('payout_empty.title', 'Fill out your profile!')}</h2>
        <p className={styles.description}>
          <Trans i18nKey="payout_empty.description" components={components} ns="payment">
            {`Before withdrawing funds, you need to <0>complete your profile</0>`}
          </Trans>
        </p>

        <Button
          className={styles.button}
          as="link"
          href={routes.profile.home}
          variant="fulfilled"
          color="red"
          size="md"
          rounded
          fontWeight="bold"
          fontSize="lg"
        >
          {t('payout_empty.button', 'Go to profile')}
        </Button>
      </div>
    </div>
  );
};
