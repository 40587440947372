import type { Analytics, BonusPacketRegistrationType } from '@mwl/core-lib';

import type { FormLoginTab, LoginAnalytics } from '../FormLogin/FormLogin.types';
import type { FormRegistrationTab } from '../FormRegistration/FormRegistration.types';

import type { RegisterContainerAnalytics } from './components/RegisterContainer/BaseRegisterContainer.types';

export type PopupAuthAnalytics = Partial<Record<'popup', Analytics>> & (RegisterContainerAnalytics | LoginAnalytics);

export const getRegisterAnalytics = (
  tab: FormRegistrationTab,
  bonus?: BonusPacketRegistrationType,
): PopupAuthAnalytics => {
  const data = {
    reg_type: tab,
    reg_bonus: bonus?.toLocaleLowerCase(),
  };

  return {
    popup: {
      open: {
        eventName: tab !== 'extended' ? 'regPopup_open' : undefined,
        data,
      },
      outsideClick: {
        eventName: tab !== 'extended' ? 'regPopup_outsideClick_close' : undefined,
        data,
      },
      closeIconClick: {
        eventName: tab !== 'extended' ? 'regPopup_xIcon_close' : undefined,
        data,
      },
    },
    tabs: {
      click: {
        eventName: 'regPopup_regType_switch',
        data,
      },
    },
    form: {
      phone: {
        change: {
          eventName: 'regPopupForm_phone_input',
          data,
        },
      },
      email: {
        change: {
          eventName: 'regPopupForm_email_input',
          data,
        },
      },
      social: {
        click: {
          eventName: 'regPopupForm_socialButton_click',
          data,
        },
      },
      password: {
        change: {
          eventName: 'regPopupForm_password_input',
          data,
        },
        on: {
          eventName: 'regPopupForm_showPassIcon_click',
          data,
        },
        off: {
          eventName: 'regPopupForm_hidePassIcon_click',
          data,
        },
      },
      country: {
        change: {
          eventName: 'regPopupForm_country_select',
          data,
        },
      },
      countryCode: {
        change: {
          eventName: 'regPopupForm_countryCode_select',
          data,
        },
      },
      currency: {
        change: {
          eventName: 'regPopupForm_currency_select',
          data,
        },
      },
      promoCode: {
        change: {
          eventName: 'regPopupForm_addPromoCode_input',
          data,
        },
        click: {
          eventName: 'regPopupForm_addPromoCode_checkSuccess',
          data,
        },
      },
      rules: {
        check: {
          eventName: 'regPopupForm_rules_checkbox_check',
          data,
        },
        uncheck: {
          eventName: 'regPopupForm_rules_checkbox_uncheck',
          data,
        },
        click: {
          eventName: 'regPopupForm_agreementLink_click',
          data,
        },
      },
      signIn: {
        click: {
          eventName: 'regPopup_logInBtn_click',
          data,
        },
      },
      signUp: {
        click: {
          eventName: 'regPopupForm_submit',
          data,
        },
      },
      submit: {
        error: {
          eventName: 'regPopupForm',
          data,
        },
        success: {
          eventName: 'regPopupForm_success',
          data,
        },
      },
    },
    bonus: {
      on: {
        eventName: tab !== 'extended' ? 'regPopup_bonus_tab_on' : undefined,
        data,
      },
      off: {
        eventName: tab !== 'extended' ? 'regPopup_tab_off' : undefined,
        data,
      },
    },
  };
};

export const getLoginAnalytics = (tab: FormLoginTab): PopupAuthAnalytics => {
  const data = {
    auth_type: tab,
  };

  return {
    popup: {
      open: {
        eventName: 'popup_entrance_open',
        data,
      },
      outsideClick: {
        eventName: 'popup_entrance_outsideClick_close',
        data,
      },
      closeIconClick: {
        eventName: 'popup_entrance_xIcon_close',
        data,
      },
    },
    tabs: {
      click: {
        eventName: 'popup_entrance_typeSwitch_click',
        data,
      },
    },
    form: {
      email: {
        change: {
          eventName: 'popup_entranceForm_email_input',
          data,
        },
      },
      phone: {
        change: {
          eventName: 'popup_entranceForm_phone_input',
          data,
        },
      },
      forgotLink: {
        click: {
          eventName: 'popup_entranceForm_forgotLink_click',
          data,
        },
      },
      password: {
        change: {
          eventName: 'popup_entranceForm_password_input',
          data,
        },
        on: {
          eventName: 'popup_entranceForm_showIcon_click',
          data,
        },
        off: {
          eventName: 'popup_entranceForm_hideIcon_click',
          data,
        },
      },
      countryCode: {
        select: {
          eventName: 'popup_entranceForm_countryCode_select',
          data,
        },
      },
      social: {
        click: {
          eventName: 'popup_entranceForm_socialIcon_click',
          data,
        },
      },
      signIn: {
        click: {
          eventName: 'popup_entranceForm_submit',
          data,
        },
      },
      submit: {
        error: {
          eventName: 'popup_entranceForm_front_error',
          data,
        },
        success: {
          eventName: 'popup_entranceForm_success',
          data,
        },
      },
    },
  };
};
