import { useCallback } from 'react';
import { payoutRequestConfirmationCodeV2, useSubmitForm } from '@mwl/core-lib';

import type { FormConfirmationOnChangeTimer, FormConfirmationValues } from './FormConfirmation.types';

interface UsePaymentSendCodeParams {
  values: FormConfirmationValues;
  payoutId?: string;
  onChangeTimer: FormConfirmationOnChangeTimer;
  onLimitError: (timer?: number) => void;
}

export const usePaymentSendCode = ({ values, payoutId, onChangeTimer, onLimitError }: UsePaymentSendCodeParams) => {
  const { state, onSubmitConfirm } = useSubmitForm({
    requestHandler: payoutRequestConfirmationCodeV2,
    formState: values,
    onSuccess: (data) => {
      if (data) {
        onChangeTimer(data.timer);
      }

      if (data?.code === 'VERIFICATION_ATTEMPTS_LIMIT') {
        onLimitError(data?.timer);
      }
    },
  });

  const requestCode = useCallback(() => {
    if (payoutId) {
      onSubmitConfirm({ payoutId });
    }
  }, [payoutId, onSubmitConfirm]);

  return { ...state, requestCode };
};
