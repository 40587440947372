import type { MouseEvent, MouseEventHandler, Ref } from 'react';
import { forwardRef } from 'react';
import Link from 'next/link';
import { handleEventWithAnalytics } from '@mwl/core-lib';
import type { MwlSize } from '@mwl/ui';
import { ButtonBase } from '@mwl/ui';

import type { ButtonProps } from './Button.types';
import { getButtonClassNames } from './Button.utils';

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      active,
      align = 'center',
      children,
      className,
      color = 'white',
      cut = true,
      disabled,
      fontSize = 'none',
      rounded,
      size = 'none',
      skew = 'none',
      uppercase = true,
      variant = 'fulfilled',
      onClick,
      analytics,
      ...props
    },
    ref,
  ) => {
    const buttonClassNames = getButtonClassNames({
      active,
      className,
      color,
      cut,
      disabled,
      fontSize,
      rounded,
      size,
      skew,
      uppercase,
      variant,
    });

    const analyticsClassName: string | undefined = analytics?.click?.data?.cls;

    const analyticsData = {
      ...(analytics?.click?.data || {}),
      cls: analytics?.click?.data?.cls || buttonClassNames,
    };

    const handleOnClick = handleEventWithAnalytics<MouseEvent<HTMLButtonElement | HTMLAnchorElement>>(
      onClick as MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined,
      analytics?.click?.eventName,
      analyticsData,
    );

    const tagProps = props['data-cy'] ? { 'data-cy': props['data-cy'] } : {};

    const defaultRadius: MwlSize = 'sm';

    const commonProps = {
      className: cn(analyticsClassName, buttonClassNames),
      onClick: handleOnClick,
      justify: align,
      radius: rounded ? defaultRadius : undefined,
      disabled,
    };

    if (props.as === 'link') {
      const { as: _, ...otherProps } = props;

      return (
        <ButtonBase {...commonProps} {...otherProps} component={Link} ref={ref as Ref<HTMLAnchorElement>} {...tagProps}>
          {children}
        </ButtonBase>
      );
    }

    if (props.as === 'external') {
      const { as: _, target = '_blank', ...otherProps } = props;

      return (
        <ButtonBase
          {...commonProps}
          component="a"
          ref={ref as Ref<HTMLAnchorElement>}
          role="presentation"
          target={target}
          rel="noopener noreferrer"
          {...otherProps}
          {...tagProps}
        >
          {children}
        </ButtonBase>
      );
    }

    const { as: _, ...otherProps } = props;

    return (
      <ButtonBase {...commonProps} {...otherProps} {...tagProps} ref={ref as Ref<HTMLButtonElement>}>
        {children}
      </ButtonBase>
    );
  },
);

export * from './Button.types';
export { Button };
