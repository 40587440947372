import { useCallback, useMemo } from 'react';
import type { QueryKey, UseFetchHandlerOptions } from '@mwl/core-lib';
import { getBonusInformation, selectUserCurrency, selectUserIsAuthenticated, useFetch } from '@mwl/core-lib';

import { useTypedSelector } from './useTypedSelector';

export const useBonusDescription = (bonusId: string) => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const currency = useTypedSelector(selectUserCurrency);

  const bonusInfoQueryKey = useMemo<QueryKey>(() => ['getBonusInformation', isAuth, bonusId], [bonusId, isAuth]);

  const bonusInfoFetchQuery = useCallback(
    ({ signal }: UseFetchHandlerOptions) => getBonusInformation({ bonusId }, { signal }),
    [bonusId],
  );

  const { data } = useFetch(bonusInfoQueryKey, bonusInfoFetchQuery, { disabled: !bonusId || !currency });

  return {
    bonusInfo: data,
  };
};
