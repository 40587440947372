import { type FormatGame, extendAnalyticsData } from '@mwl/core-lib';

import type { GameCardAnalytics } from '@/types';

interface GetGameAnalyticsProps {
  count: number;
  listings?: string;
  game: Partial<FormatGame>;
  index: number;
  analytics?: GameCardAnalytics;
}

/**
 * Дополняет аналитику игры данными:
 * count - количество игр в блоке,
 * index - позиция игры в блоке,
 * listings - название блока,
 * game - данные игры,
 * analytics - аналитика, которую необходимо дополнить.
 */
export const getGameAnalytics = ({
  count,
  listings,
  game,
  index,
  analytics,
}: GetGameAnalyticsProps): GameCardAnalytics => {
  const data = {
    count,
    position: index + 1,
    game_id: game.id,
    badges_icons: game.badges,
    listings,
  };

  return {
    playButton: extendAnalyticsData(analytics?.playButton, data),
    demoButton: extendAnalyticsData(analytics?.demoButton, data),
  };
};
