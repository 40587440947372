import type { TransitionOptionsValue } from '@mwl/core-lib';

import type { PopupStatusPaymentData, PopupStatusPaymentItem } from './PopupPaymentStatus.types';

const statuses: PopupStatusPaymentData = {
  success: {
    status: 'success',
    message: 'refill.response.success.message',
  },
  error: {
    status: 'error',
    message: 'refill.response.error.message',
    buttonText: 'refill.create.error.button',
  },
  cancel: {
    status: 'error',
    message: 'refill.response.cancel.message',
    buttonText: 'refill.create.error.button',
  },
};

export const getPopupData = (value: TransitionOptionsValue): PopupStatusPaymentItem | null => {
  switch (value) {
    case 'error':
      return statuses.error;

    case 'success':
      return statuses.success;

    case 'cancel':
      return statuses.cancel;

    default:
      return null;
  }
};
