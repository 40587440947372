import type { FormSettings } from '@mwl/core-lib';
import {
  isEmail,
  minLengthValidator,
  minPasswordLength,
  requiredStringValidator,
  requiredValidator,
} from '@mwl/core-lib';

import type { FormRegistrationEmailState, FormRegistrationEmailValues } from './FormRegistrationEmail.types';

export const initialState: FormRegistrationEmailState = {
  error: null,
  isLoading: false,
  isSuccess: false,
};

export const formSettings: FormSettings<FormRegistrationEmailValues> = {
  country: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '', prefix: '' },
  },
  currency: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: { label: '', value: '' },
  },
  email: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error.requiredField_common' },
      email: { validate: isEmail, message: 'error.email.wrongFormat' },
    },
    value: '',
  },
  password: {
    rules: {
      required: { validate: requiredStringValidator, message: 'error.requiredField_common' },
      minLength: { validate: minLengthValidator(minPasswordLength), message: 'error.password.wrongFormat' },
    },
    value: '',
  },
  promoCode: {
    value: '',
  },
  agree: {
    rules: {
      required: { validate: requiredValidator, message: 'error' },
    },
    value: true,
  },
};
